import { Fragment, ReactNode } from 'react'
import { StatusTag } from '../layout/StatusTag'
import { StatusTagColors } from '../../lib/interfaces'

interface BiomarkerSummaryIndicatorProps {
  value: number
  text: string
  color: StatusTagColors
  roundedStatusMode?: boolean
}

export function BiomarkerSummaryIndicator({
  value,
  text,
  color,
  roundedStatusMode = false,
}: BiomarkerSummaryIndicatorProps): ReactNode | string {
  const v: string = value > 0 ? value.toString() : '-'
  return (
    <Fragment>
      <StatusTag
        color={color}
        label={text}
        value={v}
        filled={true}
        roundedStatusMode={roundedStatusMode}
      ></StatusTag>
    </Fragment>
  )
}
