import { Fragment, useEffect, useState } from 'react'

import toast from 'react-hot-toast'
import {
  capitalizeFirstLetter,
  formatMoney,
  // formatMoney,
  // getUserPrettyDate,
  // getPrettyDateAndTime,
  setDocumentTitle,
} from '../../../../../lib/utils'
import { SettingsTabProps } from '../Settings'
import {
  OrganisationWithBranding,
  useOrganisationContext,
} from '../../../../context/OrganisationContext'
import {
  BillingCurrent,
  // BillingCurrentSubscription,
  StripePayment,
} from '../../../../../lib/validators'
import PageLoader from '../../../../layout/PageLoader'
import { actions, useCurrentBilling } from '../../../../../lib/store'
import {
  // cancelCurrentSubscription,
  createStripeCustomer,
  getCurrentBilling,
  retryStripeCharge,
  updateCard,
  updateCardRequest,
} from '../../../../../lib/billing'
import VisaIcon from '../../../../../../public/img/payment/visa.png?url'
import MastercardIcon from '../../../../../../public/img/payment/mastercard.png'
import AmexIcon from '../../../../../../public/img/payment/amex.png'
import GenericIcon from '../../../../../../public/img/payment/generic.png'
import { Button } from '../../../../form/button/Button'
import { SettingsTabBillingInvoices } from './SettingsTabBillingInvoices'
import {
  CardCaptureDialog,
  DialogError,
  LoadingFunction,
  OnIntentData,
  OnIntentRequestCallback,
  OnIntentRequestError,
} from '../../../../payment/CardCaptureDialog'
import {
  GroupSection,
  GroupSectionItem,
  GroupSections,
} from '../../../../layout/GroupSection'

export const SettingsTabBilling: React.FC<SettingsTabProps> = () => {
  setDocumentTitle(`Billing Settings`, 'BRANDED')
  const { org, theme } = useOrganisationContext()
  const billing = useCurrentBilling()
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [paymentMethodBeingAdded, setPaymentMethodBeingAdded] =
    useState('primary')
  const [currentBilling, setCurrentBilling] = useState<
    BillingCurrent | null | undefined
  >()
  // const [currentSubscription, setCurrentSubscription] = useState<
  //   BillingCurrentSubscription | null | undefined
  // >()

  useEffect(() => {
    async function getBilling(organisation: OrganisationWithBranding) {
      const billingInfo = await getCurrentBilling(organisation)
      // if (billingInfo === null) {
      //   toast.error('No billing info.')

      //   // setLoading(false)
      // }
      setCurrentBilling(billingInfo)
      actions.setCurrentBilling(billingInfo)
      setLoading(false)
    }
    if (!billing && org) {
      getBilling(org)
    } else {
      setLoading(false)
      setCurrentBilling(billing)
    }
  }, [billing, org])

  // const cancelSubscription = async (shouldCancel: boolean) => {
  //   const sub = await cancelCurrentSubscription(org!, shouldCancel)
  //   console.log('the response is', sub)
  //   setCurrentSubscription(sub)
  //   // TODO: FIX THIS - we need to update subscription status on the currentBilling object
  //   // actions.setCurrentSubscription(null)
  // }
  // test

  // const handleConfirmationDialog = async (): Promise<void> => {
  //   console.log("we hit the okay button... lets submit now");
  //   if (!org) {
  //     throw new Error("Missing Org");
  //   }
  //   if (!stripe || !elements) {
  //     throw new Error("Stripe missing");
  //   }

  //   if (elements == null) {
  //     return;
  //   }

  //   console.log("lets now handle the new billing info.");

  //   // Trigger form validation and wallet collection
  //   const { error: submitError } = await elements.submit();
  //   if (submitError) {
  //     // Show error to your customer
  //     console.log("an error happened", submitError);
  //     return;
  //   }

  //   setIsSaving(true);
  //   try {
  //     const response = await updateCardRequest(org);

  //     // Confirm the Intent using the details collected by the Payment Element
  //     const intentResponse = await stripe.confirmSetup({
  //       elements,
  //       clientSecret: response.clientSecret,
  //       redirect: "if_required",
  //       confirmParams: {
  //         return_url: `${import.meta.env.VITE_APP_URL}/org/${org.id}`,
  //       },
  //     });
  //     if (intentResponse.error) {
  //       throw new Error(
  //         intentResponse.error.message ||
  //           "An error occured, please contact support."
  //       );
  //     }
  //     if (intentResponse.setupIntent.status !== "succeeded") {
  //       throw new Error("Something went wrong. please contact support");
  //     }
  //     if (!intentResponse.setupIntent.payment_method) {
  //       throw new Error("Missing payment method.");
  //     }
  //     // const pi = intentResponse.paymentIntent.payment_method
  //     const sub = await updateCard(
  //       org,
  //       intentResponse.setupIntent.payment_method.toString()
  //     );
  //     setOpen(false);
  //     setDialogErrorMessage(undefined);
  //     setIsSaving(false);
  //     toast.success("Card updated", {
  //       duration: 3000,
  //     });
  //     setCurrentSubscription(sub);
  //     actions.setCurrentSubscription(sub);

  //     // console.log("intent response", intentResponse);
  //     // setIsSaving(true);
  //   } catch (e) {
  //     let message = "An error occured";
  //     if (e instanceof Error) {
  //       message = e.message;
  //     }
  //     setDialogErrorMessage(message);
  //     setIsSaving(false);
  //   }
  // };

  const showUpdateCardDialog = () => {
    console.log('show the dialog?')
    setOpen(true)
  }

  // const canUserUpdateCard = (): boolean => {
  //   return (
  //     (currentSubscription &&
  //       (currentSubscription.status === 'active' ||
  //         currentSubscription.status === 'past_due' ||
  //         currentSubscription.status === 'trialing')) ||
  //     false
  //   )
  // }

  const onUpdateDefaultCardIntentHandler = async (
    callback: OnIntentRequestCallback,
    data?: OnIntentData,
    setLoading?: LoadingFunction,
    setError?: DialogError
  ): Promise<void> => {
    if (!org) {
      return
    }

    // create the customer
    await createStripeCustomer({
      organisationUuid: org.uuid,
      organisationName: org.name,
      entityName: org.entityName || undefined,
      abn: org.abn || undefined,
    })

    const response = await updateCardRequest(org)
    const intentResponse = await callback({
      clientSecret: response.clientSecret,
      type: response.type,
      returnUrl: `${import.meta.env.VITE_APP_URL}/org/${org!.id}`,
    })

    setLoading && setLoading(true)
    // if we got a payment method, lets update the card.
    if (intentResponse && intentResponse.paymentMethod) {
      console.log('we are about to update the card', paymentMethodBeingAdded)
      try {
        const sub = await updateCard(
          org,
          intentResponse.paymentMethod,
          data ? data.email : undefined,
          paymentMethodBeingAdded === 'secondary' ? true : undefined
        )
        toast.success(currentBilling ? 'Card updated' : 'Card added', {
          duration: 3000,
        })
        intentResponse.finished()
        setCurrentBilling(sub)
        actions.setCurrentBilling(sub)
      } catch (e) {
        if (e instanceof Error) {
          setError && setError(e.message)
          toast.error(e.message, {
            duration: 5000,
          })
        }
      }
    }
    setLoading && setLoading(false)
    // else {
    // toast.error("Failed to get payment method. Please try another card.", {
    //   duration: 5000,
    // });
    // }
  }
  const onIntentError = (data: OnIntentRequestError) => {
    toast.error(data.message, {
      duration: 10000,
    })
  }

  const retryCharge = async (paymentMethod: string) => {
    if (org) {
      setLoading(true)
      try {
        const latestBilling = await retryStripeCharge(paymentMethod, org)
        setCurrentBilling(latestBilling)
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message, {
            duration: 3000,
          })
        } else {
          toast.error('An error occured. Please try again later.', {
            duration: 3000,
          })
        }
      }
      setLoading(false)
    } else {
      console.log('no org, cant retry billing')
    }
  }

  return (
    <Fragment>
      {loading && <PageLoader theme={org?.theme || undefined}></PageLoader>}
      {!loading && org && (
        <Fragment>
          <GroupSections>
            <GroupSection
              title="Default Payment Method"
              description="This is your default payment method. It will be used for any
                  requests via the api. If you have any outstanding invoices that require payment, these will be automatically paid when updating the card on file."
            >
              <GroupSectionItem>
                <form>
                  {(!currentBilling ||
                    !currentBilling.defaultPaymentMethod) && (
                    <div>
                      There is currently no card on file.
                      <div>
                        <Button
                          type="button"
                          label="Add one now"
                          onClick={() => {
                            setPaymentMethodBeingAdded('primary')
                            showUpdateCardDialog()
                          }}
                        ></Button>
                      </div>
                    </div>
                  )}
                  {currentBilling && currentBilling.defaultPaymentMethod && (
                    <Fragment>
                      <div>
                        <div>
                          <b>{currentBilling.defaultPaymentMethod?.name}</b>
                        </div>
                        {currentBilling.defaultPaymentMethod?.address?.line1}
                        {currentBilling.defaultPaymentMethod?.address
                          ?.line2 && (
                          <div>currentSubscription.payment?.address.line2</div>
                        )}
                        <br />
                        {
                          currentBilling.defaultPaymentMethod?.address?.city
                        }{' '}
                        {currentBilling.defaultPaymentMethod?.address?.state}{' '}
                        {
                          currentBilling.defaultPaymentMethod?.address
                            ?.postalCode
                        }
                        <br />
                        {currentBilling.defaultPaymentMethod?.address?.country}
                        <br />
                        {currentBilling.defaultPaymentMethod?.phone}
                      </div>
                      {currentBilling.defaultPaymentMethod && (
                        <CardPreview
                          org={org}
                          payment={currentBilling.defaultPaymentMethod}
                          updateCardOnClick={() => {
                            setPaymentMethodBeingAdded('primary')
                            showUpdateCardDialog()
                          }}
                          retryChargeCardOnClick={() => {
                            if (currentBilling.defaultPaymentMethod) {
                              retryCharge(
                                currentBilling.defaultPaymentMethod?.id
                              )
                            }
                          }}
                          className="mt-4"
                        ></CardPreview>
                      )}
                    </Fragment>
                  )}
                </form>
              </GroupSectionItem>
            </GroupSection>
            <GroupSection
              title="Secondary Payment Method"
              description="This is your fallback payment method. It will be used for any
                  purchases via the api only."
            >
              <GroupSectionItem>
                <form>
                  {(!currentBilling ||
                    !currentBilling.secondaryPaymentMethod) && (
                    <div>
                      There is currently no card on file.
                      <div>
                        <Button
                          type="button"
                          label="Add one now"
                          onClick={() => {
                            setPaymentMethodBeingAdded('secondary')
                            showUpdateCardDialog()
                          }}
                        ></Button>
                      </div>
                    </div>
                  )}
                  {currentBilling && currentBilling.secondaryPaymentMethod && (
                    <Fragment>
                      <div>
                        <div>
                          <b>{currentBilling.secondaryPaymentMethod?.name}</b>
                        </div>
                        {currentBilling.secondaryPaymentMethod?.address?.line1}
                        {currentBilling.secondaryPaymentMethod?.address
                          ?.line2 && (
                          <div>
                            currentBilling.secondaryPaymentMethod?.address.line2
                          </div>
                        )}
                        <br />
                        {
                          currentBilling.secondaryPaymentMethod?.address?.city
                        }{' '}
                        {currentBilling.secondaryPaymentMethod?.address?.state}{' '}
                        {
                          currentBilling.secondaryPaymentMethod?.address
                            ?.postalCode
                        }
                        <br />
                        {
                          currentBilling.secondaryPaymentMethod?.address
                            ?.country
                        }
                        <br />
                        {currentBilling.secondaryPaymentMethod?.phone}
                      </div>
                      {currentBilling.secondaryPaymentMethod && (
                        <CardPreview
                          org={org}
                          payment={currentBilling.secondaryPaymentMethod}
                          updateCardOnClick={() => {
                            setPaymentMethodBeingAdded('secondary')
                            showUpdateCardDialog()
                          }}
                          retryChargeCardOnClick={() => {
                            if (currentBilling.secondaryPaymentMethod) {
                              retryCharge(
                                currentBilling.secondaryPaymentMethod?.id
                              )
                            }
                          }}
                          className="mt-4"
                        ></CardPreview>
                      )}
                    </Fragment>
                  )}
                </form>
              </GroupSectionItem>
            </GroupSection>
            {/* {currentBilling && (
              <GroupSection
                title="Billing Address"
                description="Billing address for your organisation"
              >
                <GroupSectionItem>
                  {currentBilling && currentBilling.customer && (
                    <div>
                      <div>
                        <b>{currentBilling.customer?.name}</b>
                      </div>
                      {currentBilling.customer?.address?.line1}
                      {currentBilling.customer?.address?.line2 && (
                        <div>currentBilling.customer?.address?.line2</div>
                      )}
                      <br />
                      {currentBilling.customer?.address?.city}{' '}
                      {currentBilling.customer?.address?.state}{' '}
                      {currentBilling.customer?.address?.postalCode}
                      <br />
                      {currentBilling.customer?.address?.country}
                      <br />
                      {currentBilling.customer?.phone}
                    </div>
                  )}
                </GroupSectionItem>
              </GroupSection>
            )} */}
            {currentBilling && (
              <GroupSection
                title="Invoices"
                description="Your invoice history"
                underneath
              >
                {org && (
                  <SettingsTabBillingInvoices></SettingsTabBillingInvoices>
                )}
              </GroupSection>
            )}
          </GroupSections>
          {/* <div className="divide-y divide-gray"> */}
          {/* <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-black">
                  Card Information
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-dark">
                  Credit card details associated with your organisation.
                </p>
              </div>

              <form className="md:col-span-2">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                  <div className="col-span-full text-md">
                    {currentSubscription && org && (
                      <div>
                        {canUserUpdateCard() && currentSubscription.payment && (
                          <CardPreview
                            org={org}
                            payment={currentSubscription.payment}
                            updateCardOnClick={showUpdateCardDialog}
                          ></CardPreview>
                        )}

                        {!canUserUpdateCard() && (
                          <div>No active subscription</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-black">
                  Subscription
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-dark">
                  The status of your subscription
                </p>
              </div>

              <form className="md:col-span-2">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                  <div className="col-span-full text-md">
                    {currentSubscription && org && (
                      <div>
                        <div>
                          <b>
                            {currentSubscription.internalPlanName}{' '}
                            {currentSubscription.price && (
                              <span className="text-md font-medium leading-6 text-gray-semi-dark">
                                (
                                {formatMoney(
                                  currentSubscription.price / 100,
                                  currentSubscription.currency
                                )}{' '}
                                / month)
                              </span>
                            )}
                          </b>
                        </div>
                        <div>
                          <b>Status:</b>{' '}
                          {currentSubscription.status === 'past_due' && (
                            <Fragment>
                              <span>Suspended</span>
                              <span className="block text-sm font-medium leading-6  text-blood">
                                The last payment failed, please update your card
                                details above
                              </span>
                            </Fragment>
                          )}
                          {currentSubscription.status !== 'past_due' && (
                            <span>
                              {capitalizeFirstLetter(
                                currentSubscription.status
                              )}
                            </span>
                          )}
                        </div>

                        {currentSubscription.status !== 'canceled' &&
                          currentSubscription.cancelledAt && (
                            <span className="block text-sm font-medium leading-6 text-gray-dark">
                              Cancellation requested on{' '}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: getUserPrettyDate(
                                    new Date(
                                      currentSubscription.cancelledAt * 1000
                                    ),
                                    true
                                  ),
                                }}
                              ></span>
                              .<br />
                              Your subscription will end on{' '}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: getUserPrettyDate(
                                    new Date(
                                      currentSubscription.currentPeriodEnd *
                                        1000
                                    ),
                                    true
                                  ),
                                }}
                              ></span>
                              , but you able to resume your subscription between
                              now and{' '}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: getUserPrettyDate(
                                    new Date(
                                      currentSubscription.currentPeriodEnd *
                                        1000
                                    ),
                                    true
                                  ),
                                }}
                              ></span>{' '}
                              by pressing the "resume subscription" button below
                            </span>
                          )}
                        {!currentSubscription.cancelledAt && (
                          <Fragment>
                            <b>Next invoice:</b>{' '}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getUserPrettyDate(
                                  new Date(
                                    currentSubscription.currentPeriodEnd * 1000
                                  ),
                                  true
                                ),
                              }}
                            ></span>
                            <br />
                            <span
                              onClick={() => cancelSubscription(true)}
                              className="text-[--link-color] hover:text-[--link-hover-color] text-sm cursor-pointer"
                              style={
                                org
                                  ? ({
                                      '--link-color': theme?.linkColor,
                                      '--link-hover-color':
                                        theme?.linkHoverColor,
                                    } as React.CSSProperties)
                                  : {}
                              }
                            >
                              cancel subscription
                            </span>
                          </Fragment>
                        )}
                        {currentSubscription.status !== 'canceled' &&
                          currentSubscription.cancelledAt && (
                            <Button
                              type="button"
                              label="resume subscription"
                              theme={org.theme || undefined}
                              onClick={() => cancelSubscription(false)}
                              classNames="bg-[--button-color] hover:bg-[--button-hover-color] text-[--button-text-color] cursor-pointer "
                            ></Button>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div> */}

          <CardCaptureDialog
            show={open}
            theme={theme || undefined}
            options={{
              proceedText:
                (paymentMethodBeingAdded === 'primary' &&
                  currentBilling?.defaultPaymentMethod) ||
                (paymentMethodBeingAdded === 'secondary' &&
                  currentBilling?.secondaryPaymentMethod)
                  ? 'Update card'
                  : 'Add card',
              title:
                (paymentMethodBeingAdded === 'primary' &&
                  currentBilling?.defaultPaymentMethod) ||
                (paymentMethodBeingAdded === 'secondary' &&
                  currentBilling?.secondaryPaymentMethod)
                  ? 'Update card'
                  : 'Add card',
              requestBillingEmail: paymentMethodBeingAdded === 'primary',
            }}
            onClose={() => setOpen(false)}
            onIntentRequestHandler={onUpdateDefaultCardIntentHandler}
            onError={onIntentError}
          ></CardCaptureDialog>
          {/* </div> */}
        </Fragment>
      )}
    </Fragment>
  )
}

interface CardPreviewProps {
  payment: StripePayment
  org: OrganisationWithBranding
  updateCardOnClick: () => void
  retryChargeCardOnClick: () => void
  className?: string
}

export function CardPreview({
  payment,
  org,
  updateCardOnClick,
  retryChargeCardOnClick,
  className,
}: CardPreviewProps) {
  const getCardImage = () => {
    switch (payment.brand) {
      case 'visa':
        return VisaIcon
      case 'mastercard':
        return MastercardIcon
      case 'amex':
        return AmexIcon
      default:
        return GenericIcon
    }
  }

  return (
    <div className={className}>
      <img
        src={getCardImage()}
        alt={`payment method: ${payment.brand}`}
        className="w-10 inline mr-2 rounded-sm"
      />
      {capitalizeFirstLetter(payment.brand)} ending in <b>{payment.last4}</b>
      <span className="block text-sm font-medium leading-6 text-gray-dark">
        Expires{' '}
        {payment.expMonth && payment.expMonth < 10
          ? `0${payment.expMonth}`
          : payment.expMonth}
        /{payment.expYear}
      </span>
      {payment.lastChargeError && (
        <Fragment>
          <div className="text-blood font-semibold text-sm p-4 border border-blood rounded-md bg-blood/5 mb-2 mt-2 max-w-[50%]">
            {payment.lastChargeError.message}
            <br />
            Attempted to charge:{' '}
            {formatMoney(payment.lastChargeError.amount / 100, 'aud')}
          </div>
          <Button
            type={'button'}
            label="Retry Outstanding Charges"
            onClick={retryChargeCardOnClick}
            theme={org.theme || undefined}
            classNames="mr-2"
          ></Button>
        </Fragment>
      )}
      <Button
        type={'button'}
        label="Update Card"
        onClick={updateCardOnClick}
        color={'mandarin'}
      ></Button>
    </div>
  )
}
