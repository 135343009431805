import { Fragment, useCallback, useEffect, useState } from 'react'
import { StatusTagColors, TabItems, ThemeInterface } from '../../lib/interfaces'
import {
  BiomarkerPreviewItemWithTestTurnaroundTimes,
  BiomarkerResult,
  MixedPreviewResultGroupedBiomarker,
  ResultReferral,
} from '../../lib/validators'
import { TabbedMenu } from '../layout/TabbedMenu'
import { ResultTabOverview } from '../pages/result-tabs/ResultTabOverview'
import { ResultTabBiomarkers } from '../pages/result-tabs/ResultTabBiomarkers'
import { ResultTabAttachments } from '../pages/result-tabs/ResultTabAttachments'
import { useLocation, useNavigate } from 'react-router-dom'
import { OrganisationWithBranding } from '../context/OrganisationContext'
import 'react-tooltip/dist/react-tooltip.css'
import { HistoricalBiomarkerResultMap } from '../../lib/results'
import { ResultHeader } from './ResultHeader'
import { StatusTag } from '../layout/StatusTag'
import { ResultStatus } from '../../lib/types'

import { ApprovalBar } from '../pages/organisation/org/ui/ApprovalBar'
import { InfoSection } from './InfoSection'
import { UserImage } from '../layout/UserImage'
import { AvatarImageTypes, getSizedImage, OrgImageTypes } from '../../lib/image'
import { BuildingOffice2Icon } from '@heroicons/react/24/solid'

interface ResultViewerProps {
  resultItem: ResultReferral
  historicalData?: HistoricalBiomarkerResultMap
  profileUuid: string
  urlRegex: string
  org?: OrganisationWithBranding
  theme?: ThemeInterface
  patientName?: string
}

export function ResultBiomarkerViewer({
  resultItem,
  historicalData,
  profileUuid,
  urlRegex,
  org,
  theme,
  patientName,
}: ResultViewerProps) {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState<string>('')
  const [groupedBiomarkers, setGroupedBiomarkers] =
    useState<MixedPreviewResultGroupedBiomarker>([])
  const [result, setResult] = useState<ResultReferral>(resultItem)
  const [historicalBiomarkerData, setHistoricalBiomarkerData] = useState<
    HistoricalBiomarkerResultMap | undefined
  >(historicalData)

  const baseTabs: TabItems = {
    '': {
      name: 'Blood Test Results',
      href: '',
      current: false,
    },
    attachments: {
      name: 'Attachments',
      href: 'attachments',
      current: false,
    },
  }
  const [tabs, setTabs] = useState<TabItems>(baseTabs)

  const location = useLocation()

  const canViewNewResultsPage = true

  const getTabs = useCallback(
    (page: string) => {
      Object.entries(tabs).map(([key, val]) => {
        val.current = false
        if (key === page) {
          val.current = true
        }

        return val
      })
      return tabs
    },
    [tabs]
  )

  useEffect(() => {
    setHistoricalBiomarkerData(historicalData)
  }, [historicalData])

  const handlePageLoad = useCallback(
    (pathname: string): void => {
      if (canViewNewResultsPage) {
        const pathSegments = pathname.split('/')
        if (pathSegments.pop() === 'biomarkers') {
          navigate(pathSegments.join('/'))
        }
      }
      const re = new RegExp(urlRegex)
      const matches = re.exec(pathname)
      if (!matches || matches.length === 0) {
        throw new Error('Tab not found')
      }

      const tab = matches.pop()?.replace('/', '')
      if (tab === undefined) {
        throw new Error('Tab not found')
      }

      setCurrentPage(tab)
      const latestTabs = getTabs(tab)
      setTabs(latestTabs)
    },
    [canViewNewResultsPage, getTabs, navigate, urlRegex]
  )

  const transformBiomarkersIntoCategories = useCallback(
    (
      biomarkers: BiomarkerResult[],
      pendingBiomarkers: BiomarkerPreviewItemWithTestTurnaroundTimes[]
    ): MixedPreviewResultGroupedBiomarker => {
      const categoryGroups: MixedPreviewResultGroupedBiomarker = []
      const categories = Object.fromEntries(
        new Map(
          biomarkers.map((item) => [item.category?.id, item.category])
        ).entries()
      )
      const pendingCategories = Object.fromEntries(
        new Map(
          pendingBiomarkers.map((item) => [item.category?.id, item.category])
        ).entries()
      )
      const categorySlugs = Object.keys({ ...categories, ...pendingCategories })
      categorySlugs.sort()
      // for each category
      // this way is so slow, make it faster in future
      categorySlugs.forEach((category) => {
        const biomarkersFound: BiomarkerResult[] = []
        const pendingBiomarkersFound: BiomarkerPreviewItemWithTestTurnaroundTimes[] =
          []
        for (const b in biomarkers) {
          if (biomarkers[b].category?.id === category) {
            biomarkersFound.push(biomarkers[b])
          }
        }
        for (const b in pendingBiomarkers) {
          if (pendingBiomarkers[b].category?.id === category) {
            pendingBiomarkersFound.push(pendingBiomarkers[b])
          }
        }

        // pluck the biomarker out
        categoryGroups.push({
          category: categories[category] || pendingCategories[category],
          biomarkers: biomarkersFound,
          pendingBiomarkers: pendingBiomarkersFound,
        })
      })
      return categoryGroups
    },
    []
  )
  useEffect(() => {
    handlePageLoad(location.pathname)
  }, [location, handlePageLoad])

  useEffect(() => {
    setResult(resultItem)
    if (resultItem && resultItem.biomarkers) {
      const pendingBiomarkerMap: Record<
        string,
        BiomarkerPreviewItemWithTestTurnaroundTimes
      > = {}

      // add the biomarkers that are missing - they are under pendingTests
      if (resultItem.pendingTests) {
        resultItem.pendingTests.map((test) => {
          if (test?.biomarkers) {
            test.biomarkers.map((biomarker) => {
              pendingBiomarkerMap[biomarker.id] = {
                ...biomarker,
                turnAroundTimeAverage: test.turnAroundTimeAverage,
                turnAroundTimes: test.turnAroundTimes,
              }
            })
          }
        })
      }
      const pendingBiomarkerList: BiomarkerPreviewItemWithTestTurnaroundTimes[] =
        Object.values(pendingBiomarkerMap)
      const categoryGroups = transformBiomarkersIntoCategories(
        resultItem.biomarkers,
        pendingBiomarkerList
      )
      setGroupedBiomarkers(categoryGroups)
    }
  }, [resultItem, transformBiomarkersIntoCategories])

  const getPendingBiomarkerCount = (): number => {
    if (result.biomarkerSummary) {
      return result.biomarkerSummary.pending || 0
    }
    return 0
  }
  return (
    <Fragment>
      <ResultHeader
        patientName={patientName}
        result={result}
        actions={
          <div className="flex flex-row sm:flex-col ">
            {resultItem.reviewedByProfile && (
              <div className="text-black/70 dark:text-gray-light mb-2 mr-4 sm:mr-0">
                <div className="flex items-center">
                  <div>
                    <UserImage
                      size="sm"
                      image={getSizedImage(
                        AvatarImageTypes.Small,
                        resultItem.reviewedByProfile?.image
                      )}
                    ></UserImage>
                  </div>
                  <div className="ml-2 text-sm">
                    <div className="text-left font-bold">Reviewed By</div>
                    {resultItem.reviewedByProfile.firstName}{' '}
                    {resultItem.reviewedByProfile.lastName || ''}
                  </div>
                </div>
              </div>
            )}
            {resultItem.organisation?.name && (
              <div className="text-black/70 dark:text-gray-light">
                <div className="flex items-center">
                  <div>
                    {resultItem.organisation.theme?.logoSquare ? (
                      <img
                        className="max-w-[32px]"
                        src={getSizedImage(
                          OrgImageTypes.Favicon,
                          resultItem.organisation.theme?.logoSquare
                        )}
                      />
                    ) : (
                      <BuildingOffice2Icon width={28}></BuildingOffice2Icon>
                    )}
                  </div>
                  <div className="ml-2 text-sm">
                    <div className="text-left font-bold">Ordered Via</div>
                    {resultItem.organisation.name}
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      ></ResultHeader>
      {result && org && result.status === ResultStatus.InReview && (
        <ApprovalBar
          resultUuid={result.uuid}
          organisationUuid={org.uuid}
          name={result.patientName ?? undefined}
        ></ApprovalBar>
      )}

      {result.status !== ResultStatus.Complete &&
        result.pendingTests &&
        result.pendingTests?.length > 0 && (
          <StatusTag
            className="mb-4 !p-4"
            color={StatusTagColors.MANDARIN}
            label={
              <div>
                This is a partial result. We have received{' '}
                <b>
                  {(result.biomarkerSummary?.normal || 0) +
                    (result.biomarkerSummary?.abnormal || 0) +
                    (result.biomarkerSummary?.critical || 0)}{' '}
                  biomarkers
                </b>{' '}
                and are still waiting on
                <b>
                  {' '}
                  {getPendingBiomarkerCount()} additional biomarker
                  {getPendingBiomarkerCount() === 1 ? '' : 's'}
                </b>
                .
              </div>
            }
          ></StatusTag>
        )}
      {result &&
        org &&
        result.status === ResultStatus.Created &&
        org.withholdResults && (
          <InfoSection className="print:hidden">
            These results are still coming in; only you are able to view them.
            Once all the results are in, we will prompt you to review them and
            they will be released to {result.patientName}.
          </InfoSection>
        )}
      <TabbedMenu
        tabs={tabs}
        currentPage={currentPage}
        theme={theme}
      ></TabbedMenu>
      {currentPage === '' && !canViewNewResultsPage && (
        <ResultTabOverview
          resultReferral={result}
          profile={profileUuid}
        ></ResultTabOverview>
      )}
      {currentPage === '' && canViewNewResultsPage && (
        <ResultTabBiomarkers
          groupedBiomarkers={groupedBiomarkers}
          result={result}
          historicalData={historicalBiomarkerData}
          isResultOwner={result.profileUuid === profileUuid}
          theme={theme}
        ></ResultTabBiomarkers>
      )}
      {currentPage === 'biomarkers' && (
        <ResultTabBiomarkers
          groupedBiomarkers={groupedBiomarkers}
          result={result}
          historicalData={historicalBiomarkerData}
          isResultOwner={result.profileUuid === profileUuid}
          theme={org?.theme || theme}
        ></ResultTabBiomarkers>
      )}
      {currentPage === 'attachments' && (
        <ResultTabAttachments
          resultReferral={result}
          profile={profileUuid}
          org={org}
          theme={org?.theme || theme}
        ></ResultTabAttachments>
      )}
      {currentPage === null && <div>Unknown page</div>}
    </Fragment>
  )
}
