import { BeakerIcon } from '@heroicons/react/24/outline'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import bgtLogo from '../../../public/img/bgt-64.png?url'
import cyte4Logo from '../../../public/img/map/4cyte-logo.jpg?url'
import ACLLogo from '../../../public/img/map/acl-logo.png?url'
import wiseLogo from '../../../public/img/map/wise-logo.png?url'
import healiusLogo from '../../../public/img/map/healius-logo.png?url'
import { usePathologyProviders } from '../../lib/admin'
import { fetchBranding } from '../../lib/utils'
import { BasicOrganisation, ResultReferral } from '../../lib/validators'
import { BiomarkerResultOptimalRange } from '../biomarker/utils'
import { OptimalRangeOption } from '../pages/result-tabs/ResultTabMenuBar'
import { BuildingOffice2Icon } from '@heroicons/react/24/outline'

function getProviderLogoSrc(providerId: string): undefined | string {
  switch (providerId) {
    case 'bgt':
      return bgtLogo
    case '4cyte':
      return cyte4Logo
    case 'wise':
      return wiseLogo
    case 'acl':
      return ACLLogo
    case 'qml':
    case 'laverty':
    case 'abbott':
    case 'dorevitch':
    case 'tml':
    case 'wdp':
      return healiusLogo
    default:
      return undefined
  }
}

export function useOptimalRangeOptions(result: ResultReferral) {
  const [optimalRangeOptions, setOptimalRangeOptions] = useState<
    OptimalRangeOption[]
  >([])
  const [loading, setLoading] = useState(true)

  // Memoize the branding fetch cache with proper typing
  const brandingCache = useRef<Map<string, BasicOrganisation | null>>(new Map())

  const fetchOptimalRanges = useCallback(async () => {
    setLoading(true)

    try {
      const rangeOptions: OptimalRangeOption[] = []

      if (result.optimalRanges) {
        for (const range of result.optimalRanges) {
          if (range.organisationUuid) {
            // Check cache first
            let branding = brandingCache.current.get(range.organisationUuid)

            // If not cached, fetch branding and cache it
            if (branding === undefined) {
              branding = await fetchBranding({
                organisationUuid: range.organisationUuid,
              })
              brandingCache.current.set(range.organisationUuid, branding)
            }

            rangeOptions.push({
              key: range.uuid,
              name: branding ? `${branding.name} - ${range.name}` : range.name,
              icon: branding?.theme?.logoSquare ? (
                <img
                  className="rounded-md"
                  src={branding.theme.logoSquare}
                  alt={branding.name}
                />
              ) : (
                <BuildingOffice2Icon />
              ),
            })
          }
        }
      }

      setOptimalRangeOptions(rangeOptions)
    } catch (error) {
      console.error('Error fetching optimal ranges:', error)
    } finally {
      setLoading(false)
    }
  }, [result.optimalRanges])

  useEffect(() => {
    fetchOptimalRanges()
  }, [fetchOptimalRanges])

  return { options: optimalRangeOptions, loading }
}

export function usePathologyProviderOptions(result: ResultReferral) {
  const [pathologyProviders, pathologyProvidersLoading] =
    usePathologyProviders()

  const options = useMemo(() => {
    if (pathologyProvidersLoading || pathologyProviders.length === 0) return []

    // Get a set of providerIds from each biomarker on the result
    const rawProviderIds =
      result.biomarkers?.map((biomarker) => biomarker.providerId) ?? []
    const providerIds = new Set(rawProviderIds)

    return pathologyProviders
      .filter((provider) => providerIds.has(provider.id))
      .map((provider) => {
        const logoSrc = getProviderLogoSrc(provider.id)
        return {
          key: provider.id,
          name: provider.name,
          icon: logoSrc ? (
            <img className="rounded-md" src={logoSrc} alt={provider.name} />
          ) : (
            <BeakerIcon />
          ),
        }
      })
  }, [pathologyProviders, pathologyProvidersLoading, result.biomarkers])

  return { options, loading: pathologyProvidersLoading }
}

interface UseSelectedRangesProps {
  optimalRangeOptions: OptimalRangeOption[]
  selectedRangeKeys: string[]
  result: ResultReferral
}

export function useSelectedRanges({
  optimalRangeOptions,
  selectedRangeKeys,
  result,
}: UseSelectedRangesProps) {
  // Filter the selected ranges based on selected keys
  const selectedOptimalRanges = useMemo(() => {
    return optimalRangeOptions.filter((range) =>
      selectedRangeKeys.includes(range.key)
    )
  }, [optimalRangeOptions, selectedRangeKeys])

  // Map the selected ranges by biomarker ID
  const optimalRangesById = useMemo(() => {
    const lookup: Record<string, BiomarkerResultOptimalRange[]> = {}

    const numericBiomarkers =
      result.biomarkers?.filter(
        (biomarker) => biomarker.valueType === 'numeric'
      ) || []

    for (const biomarker of numericBiomarkers) {
      if (!lookup[biomarker.id]) {
        lookup[biomarker.id] = []
      }
      for (const option of selectedOptimalRanges) {
        if (option.key === biomarker.providerId) {
          lookup[biomarker.id].push({
            name: option.name,
            icon: option.icon,
            ranges: [
              {
                low: biomarker.refIntervalLow,
                high: biomarker.refIntervalHigh,
              },
            ],
            unit: biomarker.unit,
            isSourceProvider: true,
          })
        } else {
          const range = result.optimalRanges?.find((r) => r.uuid === option.key)

          if (!range) continue

          const spec = range.specs.find(
            (spec) => spec.biomarkerId === biomarker.id
          )
          if (!spec) continue

          lookup[biomarker.id].push({
            name: option.name,
            icon: option.icon,
            ranges: spec.ranges,
            unit: spec.unit,
            isSourceProvider: false,
          })
        }
      }
    }

    return lookup
  }, [selectedOptimalRanges, result.biomarkers, result.optimalRanges])

  // Return the list of all range options for use in the UI
  const allRangeOptions = useMemo(
    () => optimalRangeOptions,
    [optimalRangeOptions]
  )

  return {
    selectedOptimalRanges,
    optimalRangesById,
    allRangeOptions,
  }
}
