import { Fragment, useEffect, useState } from 'react'
import { useOrganisationContext } from '../../../../context/OrganisationContext'
import PageLoader from '../../../../layout/PageLoader'
import { ColorPicker, ColorPickerResult } from '../../../../form/ColorPicker'
import {
  ImageUpload,
  ImageUploadResultWithBlob,
  ThemeInterface,
} from '../../../../../lib/interfaces'
import { actions } from '../../../../../lib/store'
import { Button } from '../../../../form/button/Button'
import { updateOrganisationTheme } from '../../../../../lib/organisation'
import { refreshCurrentUser, setDocumentTitle } from '../../../../../lib/utils'
import toast from 'react-hot-toast'
import { acceptedImageFiles } from '../../../../form/FileDrop'
import { ImageUploaderAndCropper } from '../../../../form/ImageUploaderAndCropper'
import { authFetch } from '../../../../../providers/AuthProvider'
import {
  GroupSection,
  GroupSectionItem,
  GroupSections,
} from '../../../../layout/GroupSection'

export default function SettingsTabBranding() {
  const { org, theme } = useOrganisationContext()

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [updatedTheme, setUpdatedTheme] = useState<ThemeInterface>()
  const [hasChanged, setHasChanged] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [logo, setLogo] = useState<ImageUpload>()
  const [squareLogo, setSquareLogo] = useState<ImageUpload>()

  useEffect(() => {
    setDocumentTitle(`Branding Settings`, 'BRANDED')
  }, [])
  // just do it once
  useEffect(() => {
    if (!updatedTheme && theme) {
      setUpdatedTheme(theme)
    }
  }, [theme, updatedTheme])
  useEffect(() => {
    if (org) {
      setLoading(false)
      if (org.theme) {
        setUpdatedTheme(org.theme)
      }
    }
  }, [org])

  const onColorPicked = (color: ColorPickerResult) => {
    setHasChanged(true)
    setUpdatedTheme((prev) => ({
      ...prev,
      [color.name]: color.color.toLowerCase(),
    }))
  }
  const onImageUploaded = (img: ImageUploadResultWithBlob) => {
    setHasChanged(true)
    console.log(
      'the previous version of the updatedTheme is',
      updatedTheme,
      img
    )
    setUpdatedTheme((prev) => ({
      ...prev,
      [img.name]: img.url,
    }))
  }
  useEffect(() => {
    if (hasChanged && updatedTheme) {
      console.log('new theme is', updatedTheme)
      actions.setTemporaryOrganisationTheme(updatedTheme)
    }
  }, [updatedTheme, hasChanged])

  useEffect(() => {
    console.log('we got a new org state...', updatedTheme)
  }, [updatedTheme])

  const DEFAULT_COLOR = '#FFFFFF'

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!org) {
      return
    }

    setIsSaving(true)
    const uploads: Promise<ImageUploadResultWithBlob | undefined>[] = []
    if (logo) {
      uploads.push(
        uploadFile({
          name: logo.name,
          url: logo.url || '',
          blob: logo.croppedFile,
          filename: logo.filename,
        })
      )
      uploads.push(
        uploadFile({
          name: `${logo.name}Raw`,
          url: logo.url || '',
          blob: logo.originalFile,
          filename: `raw-${logo.originalFilename}`,
        })
      )
    }
    if (squareLogo) {
      uploads.push(
        uploadFile({
          name: squareLogo.name,
          url: squareLogo.url || '',
          blob: squareLogo.croppedFile,
          filename: squareLogo.filename,
        })
      )
      uploads.push(
        uploadFile({
          name: `${squareLogo.name}Raw`,
          url: squareLogo.url || '',
          blob: squareLogo.originalFile,
          filename: `raw-${squareLogo.originalFilename}`,
        })
      )
    }
    const completedUploads = await Promise.all(uploads)

    // update the state
    let newState: ThemeInterface = {}
    for (const i in completedUploads) {
      const upload = completedUploads[i]
      if (upload) {
        // TODO: this is horrible, fix this in future as its so clunky with typescript
        // refactor it to make the names more scaleable.
        if (upload.name === 'logoRaw' && logo) {
          newState = {
            ...newState,
            logoOriginal: {
              url: upload.url,
              crop: logo.crop,
            },
          }
          // onImageUploaded(upload);
        }
        console.log('meow about to check logoSquareRaw', upload, squareLogo)
        if (upload.name === 'logoSquareRaw' && squareLogo) {
          newState = {
            ...newState,
            logoSquareOriginal: {
              url: upload.url,
              crop: squareLogo.crop,
            },
          }
          // onImageUploaded(upload);
        }
        if (upload.name === 'logo' || upload.name == 'logoSquare') {
          newState = {
            ...newState,
            [upload.name]: upload.url,
          }
          onImageUploaded(upload)
        }
      }
    }

    const latestState: ThemeInterface = {
      ...updatedTheme,
      ...newState,
    }

    // just clean up the data so we don't do anything stupid

    if (latestState.logo && latestState.logo.indexOf('blob') === 0) {
      latestState.logo = null
    }
    if (
      latestState.logoSquare &&
      latestState.logoSquare.indexOf('blob') === 0
    ) {
      latestState.logoSquare = null
    }

    // we need to upload the images here
    // const imageUploadItem: ImageUploadResultWithBlob = {
    //   name: "logoSquare",
    //   url: data.url || "",
    //   blob: data.blob,
    //   filename: data.filename,
    // };
    // const uploadedFile = await uploadFile(imageUploadItem);
    // onImageUploaded(imageUploadItem);

    // we need to make sure we don't upload a tmp filename for the images.

    // console.log("we got it", latestState);
    await updateOrganisationTheme(org?.uuid, latestState)
    await refreshCurrentUser()
    toast.success('Branding saved', {
      duration: 3000,
    })
    setIsSaving(false)
  }

  const uploadFile = async (
    item: ImageUploadResultWithBlob
  ): Promise<ImageUploadResultWithBlob | undefined> => {
    const payload = new FormData()
    payload.append('file', item.blob)
    payload.append('name', item.name)
    payload.append('organisationUuid', org!.uuid)
    payload.append('filename', item.filename)
    // update its status
    // item.status = UploadStatus.Uploading;
    // setUploads((prev) => ({
    //   ...prev,
    //   [item.file.name]: item,
    // }));
    const result = await authFetch(
      `${import.meta.env.VITE_API_URL}/org/upload`,
      {
        method: 'POST',
        headers: {
          'content-type': 'multipart/form-data',
        },
        data: payload,
        onUploadProgress: (progressEvent) => {
          console.log('got some upload progress!')
          if (progressEvent && progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            console.log('progress is', item, percentCompleted)
            // item.uploadProgress = 100 - percentCompleted;
            // setUploads((prev) => ({
            //   ...prev,
            //   [item.file.name]: item,
            // }));
          }
        },
      }
    )
    if (result.data.error) {
      return undefined
    }
    return {
      blob: item.blob,
      filename: item.filename,
      url: result.data.url,
      name: item.name,
    }
  }

  return (
    <Fragment>
      {loading && <PageLoader theme={org?.theme || undefined}></PageLoader>}
      {!loading && org && (
        <form onSubmit={handleSubmit}>
          <GroupSections>
            <GroupSection
              title="Logo"
              description={
                <div>
                  <p className="mt-1 text-sm leading-6 text-gray-dark">
                    Pick your logo. For best results make sure it has a
                    transparent background to ensure best compatibility.
                  </p>
                  <p className="mt-1 text-sm leading-6 text-gray-dark">
                    The <b>primary</b> logo used in the following places:
                  </p>
                  <ul className="mt-1 text-sm leading-6 text-gray-dark">
                    <li className="list-inside list-disc">
                      header bar across the top of the website
                    </li>
                    <li className="list-inside list-disc">
                      referral email sent out when requesting a test via the api
                    </li>
                  </ul>
                </div>
              }
            >
              <GroupSectionItem title="Primary logo (Top banner)">
                {theme?.logo ? (
                  <div
                    className="inline"
                    style={{
                      backgroundImage:
                        'linear-gradient(45deg, rgba(0,0,0,0.1) 25%, transparent 25%), linear-gradient(-45deg, rgba(0,0,0,0.1) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(0,0,0,0.1) 75%), linear-gradient(-45deg, transparent 75%, rgba(0,0,0,0.1) 75%)',
                      backgroundSize: '20px 20px',
                      backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
                    }}
                  >
                    <img
                      className="max-w-[80dvw] sm:max-w-sm max-h-xs"
                      src={theme.logo}
                    ></img>
                  </div>
                ) : (
                  <span className="text-gray-dark mt-2 mb-2 text-sm">
                    No image selected
                  </span>
                )}
                <ImageUploaderAndCropper
                  acceptedFileTypes={acceptedImageFiles}
                  show={open}
                  originalImageWithCrop={theme?.logoOriginal || undefined}
                  onClose={() => setOpen(false)}
                  theme={theme || undefined}
                  desiredFilename={`logo.${Date.now()}`}
                  name="logo"
                  onImageSelected={async (data) => {
                    // lets try to upload this image
                    console.log('we selected an image', data)
                    setLogo({
                      crop: data.crop,
                      croppedFile: data.blob,
                      originalFile: data.rawFile,
                      originalFilename: data.rawFileFilename,
                      name: data.name,
                      filename: data.filename,
                    })
                    const imageUploadItem: ImageUploadResultWithBlob = {
                      name: data.name,
                      url: data.url || '',
                      blob: data.blob,
                      filename: data.filename,
                    }
                    onImageUploaded(imageUploadItem)
                  }}
                ></ImageUploaderAndCropper>
              </GroupSectionItem>
              <GroupSectionItem title="Square logo (Favicon / mobile)">
                {theme?.logoSquare ? (
                  <div
                    className="inline"
                    style={{
                      backgroundImage:
                        'linear-gradient(45deg, rgba(0,0,0,0.1) 25%, transparent 25%), linear-gradient(-45deg, rgba(0,0,0,0.1) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(0,0,0,0.1) 75%), linear-gradient(-45deg, transparent 75%, rgba(0,0,0,0.1) 75%)',
                      backgroundSize: '20px 20px',
                      backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
                    }}
                  >
                    <img
                      className="max-w-sm max-h-xs"
                      src={theme.logoSquare}
                    ></img>
                  </div>
                ) : (
                  <span className="text-gray-dark mt-2 mb-2 text-sm">
                    No image selected
                  </span>
                )}
                <ImageUploaderAndCropper
                  acceptedFileTypes={acceptedImageFiles}
                  show={open}
                  originalImageWithCrop={theme?.logoSquareOriginal || undefined}
                  onClose={() => setOpen(false)}
                  theme={theme || undefined}
                  aspectRatio={1}
                  desiredFilename={`logoSquare.${Date.now()}`}
                  name="logoSquare"
                  onImageSelected={async (data) => {
                    // lets try to upload this image
                    setSquareLogo({
                      crop: data.crop,
                      croppedFile: data.blob,
                      originalFile: data.rawFile,
                      originalFilename: data.rawFileFilename,
                      name: data.name,
                      filename: data.filename,
                    })
                    const imageUploadItem: ImageUploadResultWithBlob = {
                      name: data.name,
                      url: data.url || '',
                      blob: data.blob,
                      filename: data.filename,
                    }
                    onImageUploaded(imageUploadItem)
                  }}
                ></ImageUploaderAndCropper>
              </GroupSectionItem>
            </GroupSection>

            <GroupSection
              title="Key Colours"
              description="Pick your primary and secondary colours"
            >
              <GroupSectionItem title="Primary Colour">
                <ColorPicker
                  color={theme?.primaryColor || DEFAULT_COLOR}
                  name="primaryColor"
                  onPick={onColorPicked}
                ></ColorPicker>
              </GroupSectionItem>
              <GroupSectionItem title="Secondary Colour">
                <ColorPicker
                  color={theme?.secondaryColor || DEFAULT_COLOR}
                  name="secondaryColor"
                  onPick={onColorPicked}
                ></ColorPicker>
              </GroupSectionItem>
            </GroupSection>

            <GroupSection
              title="Button Colours"
              description={
                <div>
                  <p className="mt-1 text-sm leading-6 text-gray-dark">
                    Select the colours for buttons
                  </p>
                  <p className="mt-1 text-sm leading-6 text-gray-dark">
                    The button colours are used for buttons when they are the
                    primary action, ie "Send" or "Complete". The default red
                    button style is used for dismissive actions, ie "Cancel"
                  </p>
                </div>
              }
            >
              <GroupSectionItem title="Base Colour">
                <ColorPicker
                  color={theme?.buttonColor || DEFAULT_COLOR}
                  name="buttonColor"
                  onPick={onColorPicked}
                ></ColorPicker>
              </GroupSectionItem>
              <GroupSectionItem title="Hover Colour">
                <ColorPicker
                  color={theme?.buttonHoverColor || DEFAULT_COLOR}
                  name="buttonHoverColor"
                  onPick={onColorPicked}
                ></ColorPicker>
              </GroupSectionItem>
              <GroupSectionItem title="Text Color">
                <ColorPicker
                  color={theme?.buttonTextColor || DEFAULT_COLOR}
                  name="buttonTextColor"
                  onPick={onColorPicked}
                ></ColorPicker>
              </GroupSectionItem>
            </GroupSection>
            <GroupSection
              title="Header Bar Colour"
              description={
                <div>
                  <p className="mt-1 text-sm leading-6 text-gray-dark">
                    Pick the colour for the header background.
                  </p>
                  <p className="mt-1 text-sm leading-6 text-gray-dark">
                    The <b>header bar colour</b> is used in the following
                    locations:
                  </p>
                  <ul className="mt-1 text-sm leading-6 text-gray-dark">
                    <li className="list-inside list-disc">
                      the background header colour on any emails that are sent
                      out as part of the organisation
                    </li>
                  </ul>
                </div>
              }
            >
              <GroupSectionItem title="Header Background Colour">
                <ColorPicker
                  color={theme?.headerBarBackgroundColor || DEFAULT_COLOR}
                  name="headerBarBackgroundColor"
                  onPick={onColorPicked}
                ></ColorPicker>
              </GroupSectionItem>
            </GroupSection>
            <GroupSection
              title="Link colour"
              description={
                <p className="mt-1 text-sm leading-6 text-gray-dark">
                  This applies to all internal hyperlinks within the app
                </p>
              }
            >
              <GroupSectionItem title="Main link colour">
                <ColorPicker
                  color={theme?.linkColor || DEFAULT_COLOR}
                  name="linkColor"
                  onPick={onColorPicked}
                ></ColorPicker>
              </GroupSectionItem>
            </GroupSection>

            <GroupSection title="" reducedPadding>
              <GroupSectionItem>
                <Button
                  type="submit"
                  loading={isSaving}
                  disabled={isSaving}
                  label="Save Branding"
                  theme={theme || undefined}
                ></Button>
              </GroupSectionItem>
            </GroupSection>
          </GroupSections>
        </form>
      )}
    </Fragment>
  )
}
