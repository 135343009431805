export enum OrgImageTypes {
  Favicon = 'favicon',
  Banner = 'banner',
  Preview = 'preview',
}
export enum AvatarImageTypes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export function getSizedImage(
  type: OrgImageTypes | AvatarImageTypes,
  url?: string | null
): string | undefined {
  if (!url) {
    return
  }
  if (url.substring(0, 5) === 'blob:') {
    return url
  }
  if (url.indexOf('user-assets-bucket') === -1) {
    return url
  }
  const urlParts: string[] = url.split('.')
  const extension = urlParts.pop()
  return [urlParts.join('.'), type, extension].join('.')
}
