import { Fragment, useEffect, useState } from 'react'
import '../../App.css'
// import { authFetch } from '../../providers/AuthProvider'
import { useBranding, useUser } from '../../lib/store'
import { Result } from '../../lib/validators'
import { PageHeading } from '../layout/PageHeading'
import { BasicTableGroupProps, BasicTableProps } from '../layout/BasicTable'

import { Link } from 'react-router-dom'
import { resultUrl } from '../../lib/routes'
import PageSection from '../layout/PageSection'
import { getNiceDate, months, setDocumentTitle } from '../../lib/utils'
import Page from '../Page'
import { StatusTagColors } from '../../lib/interfaces'
import { Button } from '../form/button/Button'

import { EmptyState } from '../layout/EmptyState'
import TestTube from '../../../public/img/test-tube.svg'
import PageLoader from '../layout/PageLoader'
import { BuyTestButton } from '../form/button/BuyTestButton'
import { getResultsData } from '../../lib/results'
import { BiomarkerSummaryIndicator } from '../ui/BiomarkerSummaryIndicator'
import { LoadMore } from '../ui/LoadMore'
import { PageError } from '../ui/PageError'
import { ResultStatus } from '../../lib/types'
import { getSizedImage, OrgImageTypes } from '../../lib/image'
import { Tooltip } from 'react-tooltip'

export default function ResultsPage() {
  const [results, setResults] = useState<Result[]>([])
  const [tableData, setTableData] = useState<BasicTableProps | undefined>()
  const [profile, setProfile] = useState('')
  const branding = useBranding()?.theme || undefined
  const user = useUser()
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(true)
  const [cursor, setCursor] = useState<string | null | undefined>(undefined)
  const [pageDidError, setPageDidError] = useState(false)

  const DEFAULT_FETCH_COUNT = 50

  setDocumentTitle('Results')

  // if (!user?.profileUuid) {
  //   throw new Error("Missing Profile");
  // }

  useEffect(() => {
    // set the profile to be the first one
    if (user) {
      setProfile(user?.profileUuid)
    }
    if (profile) {
      fetchMore()
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [user, profile])

  const fetchMore = () => {
    setLoadingMore(true)
    getResultsData(profile, undefined, DEFAULT_FETCH_COUNT, cursor)
      .then((results) => {
        setCursor(results.cursor.nextCursor)
        setResults((prev) => [...prev, ...results.results])
        setLoading(false)
        setLoadingMore(false)
      })
      .catch(() => {
        setLoading(false)
        setLoadingMore(false)
        setPageDidError(true)
      })
  }

  const loadMore = fetchMore

  useEffect(() => {
    // set the profile to be the first one
    // console.log("we are here, the tests should have updated", results);
    if (results) {
      massageResultsDataIntoTable(results)
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [results])

  const massageResultsDataIntoTable = (results: Result[]) => {
    const result = []
    const resultsByYear: Record<string, BasicTableGroupProps> = {}
    // console.log("referrals", results);
    for (const result of results) {
      const d = new Date(result.resultDate)
      const year = d.getFullYear()

      // if (year !== currentYear) {
      if (!resultsByYear[year]) {
        resultsByYear[year] = {
          groupLabel: year.toString(),
          rows: [],
        }
      }

      resultsByYear[year].rows.push([
        result.status === ResultStatus.InReview ||
        result.status === ResultStatus.Created ? (
          <div className="text-gray-500">
            <ResultNameWithOrganisationLogo
              result={result}
            ></ResultNameWithOrganisationLogo>
          </div>
        ) : (
          <Link
            className="hover:text-bgt-primary text-gray-600 text-sm dark:text-white inline-block"
            to={`${resultUrl.replace(':uuid', result.uuid!).replace('/*', '')}`}
          >
            <ResultNameWithOrganisationLogo
              result={result}
            ></ResultNameWithOrganisationLogo>
          </Link>
        ),
        `${getNiceDate(d.getDate())} ${
          months[d.getMonth()]
        } ${d.getFullYear()}`,
        result.status === ResultStatus.Created ||
        result.status === ResultStatus.InReview ? (
          <div>-</div>
        ) : (
          <Fragment>
            {/* TSC being dumb, for some reason we have to bang the summary */}
            <BiomarkerSummaryIndicator
              color={StatusTagColors.JADE}
              text="normal"
              value={result.biomarkerSummary!.normal}
              roundedStatusMode={true}
            ></BiomarkerSummaryIndicator>
            <BiomarkerSummaryIndicator
              color={StatusTagColors.MANDARIN}
              text="abnormal"
              value={result.biomarkerSummary!.abnormal}
              roundedStatusMode={true}
            ></BiomarkerSummaryIndicator>
            {result.biomarkerSummary!.pending !== undefined &&
              result.biomarkerSummary!.pending > 0 && (
                <Fragment>
                  <BiomarkerSummaryIndicator
                    color={StatusTagColors.GRAY}
                    text="pending"
                    value={result.biomarkerSummary!.pending}
                    roundedStatusMode={true}
                  ></BiomarkerSummaryIndicator>
                </Fragment>
              )}
          </Fragment>
        ),
        result.status === ResultStatus.Created ? (
          <div>Results pending</div>
        ) : result.status === ResultStatus.InReview ? (
          <div>
            <div
              className="inline-block"
              data-tooltip-id={`result-tooltip-${result.uuid}`}
            >
              In Review
            </div>
            <Tooltip
              id={`result-tooltip-${result.uuid}`}
              place="top"
              content={`These results are currently being reviewed by ${result.organisation?.name}`}
              className="rounded-lg"
            />
          </div>
        ) : (
          <Link
            className="hover:text-bgt-primary"
            to={`${resultUrl.replace(':uuid', result.uuid!).replace('/*', '')}`}
          >
            <Button
              color={result.status === 'COMPLETE' ? `jade` : 'mandarin'}
              type="button"
              label={
                result.status === 'COMPLETE'
                  ? `View Results`
                  : 'View Partial Results'
              }
            ></Button>
          </Link>
        ),
      ])
    }

    const keys = Object.keys(resultsByYear)
    keys.sort().reverse()
    for (const y in keys) {
      result.push(resultsByYear[keys[y]])
    }
    if (result.length > 0) {
      setTableData({
        columns: ['Name', 'Results Date', 'Summary', ''],
        data: result,
      })
    }
  }

  // const columns = ["Name", "Reference", "Date Purchased", "Status"];
  // const data = [
  //   {
  //     groupLabel: "2024",
  //     rows: [
  //       [
  //         "Lindsay Walton With a really l ong name",
  //         "Front-end Developer",
  //         "lindsay.walton@example.com2",
  //         <StatusTag color="green" label="Active"></StatusTag>,
  //       ],
  //     ],
  //   },
  // ];

  const colClasses = [
    'text-left col-span-3 sm:col-span-4 border-b-1 border-gray-dark content-center pt-4    rounded-tl-lg rounded-tr-lg sm:rounded-tr-none rounded-tl-lg rounded-tr-lg rounded-bl-lg sm:rounded-br-none border-0', //name
    'text-left text-left sm:text-center text-gray-semi-dark col-span-3 sm:col-span-2 content-center', //reference
    'text-left text-left sm:text-left text-gray-semi-dark col-span-3 sm:col-span-3 content-center', //purchase date
    'text-left sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3 content-center pb-4 rounded-tr-lg rounded-br-lg', // status
    // "sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3", // status
  ]

  return (
    <Page>
      {pageDidError && <PageError></PageError>}
      {!pageDidError && !loading && tableData && tableData.data && (
        <PageSection>
          <PageHeading title="Results"></PageHeading>

          <div
            key={`test-table`}
            className="grid grid-cols-3 sm:grid-cols-12 mt-2 sm:mt-10"
          >
            {/* <GridTableHeader className="col-span-3 sm:col-span-8">
                Pathology Report
              </GridTableHeader> */}
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm px-2 py-1 sm:p-3 sm:px-4 hidden sm:block ${colClasses[0]}`}
            >
              {tableData.columns[0]}
            </div>
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[1]}`}
            >
              {tableData.columns[1]}
            </div>
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm px-2 py-1 sm:p-3 hidden sm:block ${colClasses[2]}`}
            >
              {tableData.columns[2]}
            </div>
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[3]}`}
            >
              {tableData.columns[3]}
            </div>
            {tableData.data.map((group, g) => (
              <Fragment key={`group-fragment-${g}`}>
                {tableData.data.length > 1 && (
                  <div
                    key={`group-label-${g}`}
                    className={`py-1 sm:p-3 sm:px-4col-span-3 sm:col-span-12 text-sm text-gray-semi-dark bg-gray-light dark:text-white dark:bg-dark-gray-light font-bold rounded-md sm:rounded-none px-2 sm:px-4 sm:mb-0 mb-2 mt-4 sm:rounded-tl-lg sm:rounded-tr-lg`}
                  >
                    {group.groupLabel}
                  </div>
                )}
                {group.rows.map((row, k) =>
                  row.map((item, i) => (
                    <div
                      key={`item-row-${k}-${i}`}
                      className={`py-1 px-2 sm:p-3 sm:px-4 col-span-1 sm:col-span-2 text-md text-black dark:text-white  dark:bg-dark-gray-lighterer rounded-bl-none sm:rounded-tl-none ${
                        k % 2 === 1
                          ? 'bg-gray-light dark:!bg-dark-gray-light dark:border-0'
                          : ''
                      }  ${colClasses[i]} ${
                        i !== row.length - 1
                          ? ''
                          : 'last-row sm:border-none border-b border-gray dark:border-0 rounded-tl-none rounded-tr-none  rounded-br-lg sm:mb-0 mb-2 !rounded-bl-lg sm:!rounded-bl-none sm:!rounded-br-none'
                      } ${k === group.rows.length - 1 ? 'border-none' : ''}`}
                    >
                      {item}
                    </div>
                  ))
                )}
              </Fragment>
            ))}
          </div>
          {cursor && (
            <LoadMore onClick={loadMore} loading={loadingMore}></LoadMore>
          )}
        </PageSection>
      )}
      {!pageDidError && !loading && !tableData && (
        <EmptyState
          image={TestTube}
          title="Looking for Results?"
          secondTitle="They're Just a Heartbeat Away!"
          alt="Test Tube Image"
        >
          Seems like your tests are playing hide and seek. But don't worry,
          they'll show up soon, ready or not! In the meantime, why not take a
          peek at our other{' '}
          <a
            target="bgt-store"
            className="text-blood hover:underline"
            href={`${import.meta.env.VITE_SHOP_URL}/blood-tests`}
          >
            health checks
          </a>
          ? They’re waiting for you — no appointment needed!
          <div className="mt-8">
            <BuyTestButton theme={branding}></BuyTestButton>
          </div>
        </EmptyState>
      )}
      {loading && <PageLoader></PageLoader>}
    </Page>
  )
}

function ResultNameWithOrganisationLogo({ result }: { result: Result }) {
  return (
    <Fragment>
      <div className="text-lg text-inherit">{result.name}</div>
      {result.organisation?.theme?.logoSquare && (
        <img
          className={`inline-block mr-2 max-h-4 max-w-4`}
          src={getSizedImage(
            OrgImageTypes.Favicon,
            result.organisation?.theme?.logoSquare
          )}
        ></img>
      )}
      {!result.organisation?.theme?.logoSquare &&
        result.organisation?.theme?.logo && (
          <img
            className={`inline-block mr-2 max-h-4 max-w-4`}
            src={getSizedImage(
              OrgImageTypes.Banner,
              result.organisation?.theme?.logo
            )}
          ></img>
        )}
      {result.organisation?.name}
    </Fragment>
  )
}
