import { Fragment, useCallback, useEffect, useState } from 'react'
import { TabItems, ThemeInterface } from '../../lib/interfaces'
import { ResultReferral, File } from '../../lib/validators'
import { TabbedMenu } from '../layout/TabbedMenu'
import { ResultTabAttachments } from '../pages/result-tabs/ResultTabAttachments'
import { useLocation } from 'react-router-dom'
import { OrganisationWithBranding } from '../context/OrganisationContext'
import 'react-tooltip/dist/react-tooltip.css'
import {
  fetchAttachmentsForResult,
  HistoricalBiomarkerResultMap,
} from '../../lib/results'
import { ResultHeader } from './ResultHeader'
import { ResultTabDocument } from '../pages/result-tabs/ResultTabDocument'
import { downloadFile } from '../../lib/utils'
import { useUser } from '../../lib/store'
import { ResultStatus } from '../../lib/types'
import { ApprovalBar } from '../pages/organisation/org/ui/ApprovalBar'
import { UserImage } from '../layout/UserImage'
import { AvatarImageTypes, getSizedImage, OrgImageTypes } from '../../lib/image'
import { BuildingOffice2Icon } from '@heroicons/react/24/solid'

interface ResultViewerProps {
  resultItem: ResultReferral
  historicalData?: HistoricalBiomarkerResultMap
  profileUuid: string
  urlRegex: string
  org?: OrganisationWithBranding
  theme?: ThemeInterface
  patientName?: string
}

export function ResultDocumentViewer({
  resultItem,
  profileUuid,
  urlRegex,
  org,
  patientName,
  theme,
}: ResultViewerProps) {
  const [currentPage, setCurrentPage] = useState<string>('')
  const [result, setResult] = useState<ResultReferral>(resultItem)
  const [attachments, setAttachments] = useState<File[]>([])
  const [attachmentUrls, setAttachmentUrls] = useState<string[]>([])

  const user = useUser()

  const baseTabs: TabItems = {
    '': {
      name: 'Test Results',
      href: '',
      current: false,
    },
    attachments: {
      name: 'Attachments',
      href: 'attachments',
      current: false,
    },
  }
  const [tabs, setTabs] = useState<TabItems>(baseTabs)

  const location = useLocation()

  const getTabs = useCallback(
    (page: string) => {
      Object.entries(tabs).map(([key, val]) => {
        val.current = false
        if (key === page) {
          val.current = true
        }

        return val
      })
      return tabs
    },
    [tabs]
  )

  const getResultAttachments = useCallback(async (): Promise<void> => {
    // setTimeout(async () => {
    if (!user) {
      throw new Error('Missing user')
    }
    if (!result) {
      throw new Error('Missing result')
    }
    const resultFiles = await fetchAttachmentsForResult(
      result.uuid,
      profileUuid,
      org?.uuid
    )

    setAttachments(resultFiles)
  }, [profileUuid, result, org, user])

  const handlePageLoad = useCallback(
    (pathname: string): void => {
      // console.log("++++ handle page load");
      const re = new RegExp(urlRegex)
      const matches = re.exec(pathname)
      // console.log("matches", matches, re.test(location.pathname));
      if (!matches || matches.length === 0) {
        throw new Error('Tab not found')
      }

      const tab = matches.pop()?.replace('/', '')
      if (tab === undefined) {
        throw new Error('Tab not found')
      }

      setCurrentPage(tab)
      const latestTabs = getTabs(tab)
      setTabs(latestTabs)
    },
    [getTabs, urlRegex]
  )

  useEffect(() => {
    handlePageLoad(location.pathname)
    console.log('the page loaded, lets get the data')
    getResultAttachments()
  }, [location, handlePageLoad, getResultAttachments])

  useEffect(() => {
    setResult(resultItem)
  }, [resultItem])

  const fetchAttachmentUrls = useCallback(async () => {
    const urls = await Promise.all(
      attachments.map((attachment) =>
        downloadFile({
          user: user || undefined,
          fileUuid: attachment.uuid,
          profileUuid: profileUuid,
          organisationUuid: org?.uuid,
          returnTheUrl: true,
          forceDownload: false,
        })
      )
    )
    if (urls) {
      setAttachmentUrls(urls.filter((url) => typeof url === 'string'))
    }
  }, [attachments, org, profileUuid, user])

  useEffect(() => {
    console.log('we got some attachments', attachments)
    if (attachments) {
      fetchAttachmentUrls()
    }
  }, [attachments, profileUuid, user, org, fetchAttachmentUrls])

  return (
    <Fragment>
      <ResultHeader
        patientName={patientName}
        result={result}
        actions={
          <div className="flex flex-row sm:flex-col ">
            {resultItem.reviewedByProfile && (
              <div className="text-black/70 dark:text-gray-light mb-2 mr-4 sm:mr-0">
                <div className="flex items-center">
                  <div>
                    <UserImage
                      size="sm"
                      image={getSizedImage(
                        AvatarImageTypes.Small,
                        resultItem.reviewedByProfile?.image
                      )}
                    ></UserImage>
                  </div>
                  <div className="ml-2 text-sm">
                    <div className="text-left font-bold">Reviewed By</div>
                    {resultItem.reviewedByProfile.firstName}{' '}
                    {resultItem.reviewedByProfile.lastName || ''}
                  </div>
                </div>
              </div>
            )}
            {resultItem.organisation?.name && (
              <div className="text-black/70 dark:text-gray-light">
                <div className="flex items-center">
                  <div>
                    {resultItem.organisation.theme?.logoSquare ? (
                      <img
                        className="max-w-[32px]"
                        src={getSizedImage(
                          OrgImageTypes.Favicon,
                          resultItem.organisation.theme?.logoSquare
                        )}
                      />
                    ) : (
                      <BuildingOffice2Icon width={28}></BuildingOffice2Icon>
                    )}
                  </div>
                  <div className="ml-2 text-sm">
                    <div className="text-left font-bold">Ordered Via</div>
                    {resultItem.organisation.name}
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      ></ResultHeader>
      {result && org && result.status === ResultStatus.InReview && (
        <ApprovalBar
          resultUuid={result.uuid}
          organisationUuid={org.uuid}
          name={result.patientName ?? undefined}
        ></ApprovalBar>
      )}
      <TabbedMenu
        tabs={tabs}
        currentPage={currentPage}
        theme={theme}
      ></TabbedMenu>

      {currentPage === '' && (
        <ResultTabDocument
          resultReferral={result}
          profile={profileUuid}
          org={org}
          files={attachmentUrls}
        ></ResultTabDocument>
      )}
      {currentPage === 'attachments' && (
        <ResultTabAttachments
          resultReferral={result}
          profile={profileUuid}
          org={org}
          theme={org?.theme || theme}
        ></ResultTabAttachments>
      )}
      {currentPage === null && <div>Unknown page</div>}
    </Fragment>
  )
}
