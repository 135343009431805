import { Fragment, useEffect, useState } from 'react'
import { refreshCurrentUser, setDocumentTitle } from '../../../../../lib/utils'
import { SettingsTabProps } from '../Settings'
import { useOrganisationContext } from '../../../../context/OrganisationContext'
import PageLoader from '../../../../layout/PageLoader'
import toast from 'react-hot-toast'
import TextInputField, {
  onChangeInterface,
} from '../../../../form/TextInputField'
import { Button } from '../../../../form/button/Button'
// import { OrganisationPayload } from '../../../../../lib/interfaces'
import { OrganisationUpdate } from '../../../../../lib/validators'
import { updateOrganisation } from '../../../../../lib/organisation'
import { useOrganisation } from '../../../../../lib/store'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ClipboardDocumentListIcon } from '@heroicons/react/24/solid'
import {
  GroupSection,
  GroupSectionItem,
  GroupSections,
} from '../../../../layout/GroupSection'
import { Textarea } from '../../../../form/Textarea'
import Switch from '../../../../form/Switch'

export const SettingsTabGeneral: React.FC<SettingsTabProps> = () => {
  const { theme } = useOrganisationContext()
  const org = useOrganisation()

  const [formData, setFormData] = useState<OrganisationUpdate>({})
  const [loading, setLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)

  const handleElementChange = (e: onChangeInterface) => {
    if (formData) {
      setFormData((prev: OrganisationUpdate) => ({
        ...prev,
        [e.name]: e.value,
      }))
    }
  }
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!org) {
      return
    }

    setIsSaving(true)
    if (formData) {
      await updateOrganisation(org?.uuid, formData)
      await refreshCurrentUser()
      toast.success('Organisation changes saved', {
        duration: 3000,
      })
    }
    setIsSaving(false)
  }

  useEffect(() => {
    console.log('we got an org,', org)
    if (org) {
      setLoading(false)
      setFormData({
        name: org.name,
        resultsEmail: org.resultsEmail,
        prettyResultsEmail: org.prettyResultsEmail,
        billingFailedEmail: org.billingFailedEmail,
        referralsEmail: org.referralsEmail,
        collectionCentreUrl: org.collectionCentreUrl,
        apiTermsAccepted: org.apiTermsAccepted ? true : undefined,
        orgTermsAccepted: org.orgTermsAccepted ? true : undefined,
        abn: org.abn,
        entityName: org.entityName,
        enableSubdomain: org.enableSubdomain || false,
        withholdResults: org.withholdResults || false,
      })
    }
  }, [org])

  useEffect(() => {
    setDocumentTitle(`General Settings`, 'BRANDED')
  }, [])

  return (
    <Fragment>
      {loading && <PageLoader theme={org?.theme || undefined}></PageLoader>}
      {!loading && org && (
        <form onSubmit={handleSubmit}>
          <GroupSections>
            <GroupSection
              title="Organisation"
              description={
                <p className="mt-1 text-sm leading-6 text-gray-dark">
                  General settings for the organisation
                </p>
              }
            >
              <GroupSectionItem>
                <div className="mb-4">
                  <TextInputField
                    id="name"
                    name="name"
                    type="text"
                    label="Organisation Name"
                    className="!max-w-60"
                    value={formData?.name || ''}
                    required={true}
                    handleChange={handleElementChange}
                  ></TextInputField>
                </div>
                <div className="mb-4">
                  <TextInputField
                    id="entityName"
                    name="entityName"
                    type="text"
                    label="Entity Name"
                    placeholder="Company Pty Ltd"
                    className="!max-w-60"
                    value={formData?.entityName || ''}
                    handleChange={handleElementChange}
                  ></TextInputField>
                </div>
                <div className="mb-4">
                  <TextInputField
                    id="abn"
                    name="abn"
                    type="text"
                    label="Australian Business Number"
                    className="!max-w-60"
                    value={formData?.abn || ''}
                    handleChange={(e: onChangeInterface) => {
                      if (e.strValue.length <= 11) {
                        handleElementChange(e)
                      }
                    }}
                  ></TextInputField>
                </div>
                <div className="mb-4">
                  <Switch
                    name="internal"
                    checked={formData?.enableSubdomain || false}
                    label={
                      <div>
                        Enable user to access {org.name} results via a branded
                        portal at:
                        <br />{' '}
                        <a
                          className="font-bold"
                          href={`https://${org.id}.${import.meta.env.VITE_APP_URL.replace('https://', '')}`}
                          target={`${org.id}-portal-tab`}
                        >
                          {`https://${org.id}.${import.meta.env.VITE_APP_URL.replace('https://', '')}`}
                        </a>
                      </div>
                    }
                    theme={theme || undefined}
                    onChange={(value) => {
                      if (formData && formData.enableSubdomain !== value) {
                        setFormData((prev) => ({
                          ...prev,
                          enableSubdomain: value,
                        }))
                      }
                    }}
                  ></Switch>
                </div>
                <div className="mb-4">
                  <Switch
                    name="withholdResults"
                    checked={formData?.withholdResults || false}
                    label={
                      <div>
                        Withhold results from clients until explicitly approved
                        by you.
                      </div>
                    }
                    theme={theme || undefined}
                    onChange={(value) => {
                      if (formData && formData.withholdResults !== value) {
                        setFormData((prev) => ({
                          ...prev,
                          withholdResults: value,
                        }))
                      }
                    }}
                  ></Switch>
                </div>
              </GroupSectionItem>
            </GroupSection>
            <GroupSection
              title="Result Email Notifications"
              description="Provide an email address where you'd like to receive result
                  notifications for tests sold via your organisation."
            >
              <GroupSectionItem>
                <TextInputField
                  id="prettyResultsEmail"
                  name="prettyResultsEmail"
                  type="email"
                  label="Email address"
                  className="!max-w-60"
                  value={formData?.prettyResultsEmail || ''}
                  handleChange={handleElementChange}
                ></TextInputField>
              </GroupSectionItem>
            </GroupSection>
            <GroupSection
              title="Billing Failed Email Notifications"
              description="If the cards on file fail to charge, an email will be sent to these emails every time a charge fails."
            >
              <GroupSectionItem>
                <Textarea
                  id="billingFailedEmail"
                  name="billingFailedEmail"
                  label="Email addresses"
                  value={formData?.billingFailedEmail || ''}
                  handleChange={handleElementChange}
                ></Textarea>
              </GroupSectionItem>
            </GroupSection>
            <GroupSection
              title="(Advanced) API Result Emails"
              description="Provide an email address where you'd like to receive api result
                  notifications in JSON format. If you aren't using the API, you can leave this field blank."
            >
              <GroupSectionItem>
                <TextInputField
                  id="resultsEmail"
                  name="resultsEmail"
                  type="email"
                  label="Email address"
                  className="!max-w-60"
                  value={formData?.resultsEmail || ''}
                  handleChange={handleElementChange}
                ></TextInputField>
              </GroupSectionItem>
            </GroupSection>
            <GroupSection
              title="(Advanced) Referrals Email"
              description="Provide an email address where you'd like to receive api referral
                  notifications in JSON format. If you aren't using the API, you can leave this field blank."
            >
              <GroupSectionItem>
                <TextInputField
                  id="referralsEmail"
                  name="referralsEmail"
                  type="email"
                  label="Email address"
                  className="!max-w-60"
                  value={formData?.referralsEmail || ''}
                  handleChange={handleElementChange}
                ></TextInputField>
              </GroupSectionItem>
            </GroupSection>
            <GroupSection
              title="Collection Centre Map URL"
              description={
                <div>
                  <p>
                    If you'd like to use the Bloody Good Tests collection centre
                    iframe, please follow the integrations steps and enter the
                    URL in the field provided.
                  </p>
                  <p>
                    This URL will be used when referral emails are sent out to
                    customers. You can specify this to direct them your own
                    website with the collection map.
                  </p>
                </div>
              }
            >
              <GroupSectionItem>
                <TextInputField
                  id="collectionCentreUrl"
                  name="collectionCentreUrl"
                  type="text"
                  label="Collection Centre iframe URL"
                  className="!max-w-60"
                  value={formData?.collectionCentreUrl || ''}
                  handleChange={handleElementChange}
                ></TextInputField>
              </GroupSectionItem>
              <GroupSectionItem>
                To use the collection centre iframe, create an iframe on your
                website with the following source:
                <p className="border-l-4 border-gray-dark/50 p-2">
                  https://app.bloodygoodtests.com.au/collection-centres?chromeless
                  <CopyToClipboard
                    text="https://app.bloodygoodtests.com.au/collection-centres?chromeless"
                    onCopy={() => {
                      console.log('clicked')
                      toast.success('Iframe URL copied', {
                        duration: 3000,
                      })
                    }}
                  >
                    <ClipboardDocumentListIcon
                      width={20}
                      title="Copy to clipboard"
                      className="inline cursor-pointer hover:text-black"
                    ></ClipboardDocumentListIcon>
                  </CopyToClipboard>
                </p>
                <p className="border-l-4 border-gray-dark/50 p-2">
                  If not specified, the default collection centre map will be
                  used
                </p>
                <div className="mt-2">
                  Example usage
                  <code className="bg-gray-dark/10 rounded-xl block p-4">
                    &lt;iframe
                    src=&quot;https://app.bloodygoodtests.com.au/collection-centres?chromeless&quot;
                    style=&quot;height: calc(100dvh - 84px); border:0px #ffffff
                    none;&quot; name=&quot;aniFrame&quot;
                    scrolling=&quot;yes&quot; frameborder=&quot;0&quot;
                    marginheight=&quot;0px&quot; marginwidth=&quot;0px&quot;
                    height=&quot;800px&quot; width=&quot;100%&quot;
                    allowfullscreen=&quot;&quot; allow=&quot;geolocation
                    *&quot;&gt;&lt;/iframe&gt;
                  </code>
                </div>
              </GroupSectionItem>
            </GroupSection>
            <GroupSection title="" reducedPadding>
              <GroupSectionItem>
                <Button
                  type="submit"
                  loading={isSaving}
                  disabled={isSaving}
                  label="Save"
                  theme={theme || undefined}
                ></Button>
              </GroupSectionItem>
            </GroupSection>
          </GroupSections>
        </form>
      )}
    </Fragment>
  )
}
