import { Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useState } from 'react'
import 'react-tooltip/dist/react-tooltip.css'
import { ThemeInterface } from '../../lib/interfaces'
import { classNames } from '../../lib/utils'
import {
  BiomarkerPreviewItemWithTestTurnaroundTimes,
  ResultReferral,
} from '../../lib/validators'
import ResultInfoSection from '../result/ResultInfoSection'
import { ExpectedResultsDate } from '../ui/ExpectedResultsDate'
import { getCurrentProviderTurnaroundTimes } from '../../lib/results'
import { Highlight } from '../ui/Highlight'

interface BiomarkerRowProps {
  className?: string
  biomarker: BiomarkerPreviewItemWithTestTurnaroundTimes
  startOpen?: boolean
  theme?: ThemeInterface
  result: ResultReferral
  highlight?: string
}

export default function BiomarkerRow({
  biomarker,
  theme,
  startOpen = false,
  result,
  highlight,
}: BiomarkerRowProps) {
  const [expandedOpen, setExpandedOpen] = useState(false)

  useEffect(() => {
    setExpandedOpen(startOpen)
  }, [startOpen])
  const baseTransitionClasses =
    'transition-all duration-500 overflow-hidden transform '
  return (
    <Fragment>
      <div
        className="cursor-pointer "
        onClick={() => setExpandedOpen((expandedOpen) => !expandedOpen)}
      >
        <div
          className={classNames(
            'float-right cursor-pointer text-sm hover:underline',
            theme ? `text-[--link-color]` : `text-blood`
          )}
          style={
            theme
              ? ({
                  '--link-color': theme.linkColor,
                } as React.CSSProperties)
              : {}
          }
          key={`view-more-${biomarker.name}`}
        >
          Click to view more
          {expandedOpen ? (
            <ChevronUpIcon
              className="inline-block"
              style={{ marginTop: '-1px' }}
              width={16}
            ></ChevronUpIcon>
          ) : (
            <ChevronDownIcon
              className="inline-block"
              width={16}
              style={{ marginTop: '-1px' }}
            ></ChevronDownIcon>
          )}
        </div>
        <div className="leading-5 text-md font-bold">
          <Highlight term={highlight} text={biomarker.name}></Highlight>
        </div>

        <ExpectedResultsDate
          identifier={`${result.uuid}-${biomarker.id}-expected`}
          startDate={result.createdAt}
          minimumTime={
            biomarker.turnAroundTimes
              ? getCurrentProviderTurnaroundTimes(
                  biomarker.turnAroundTimes,
                  result.pathologyProviderId,
                  biomarker.turnAroundTimeAverage || undefined
                )?.minimumTime
              : undefined
          }
          maximumTime={
            biomarker.turnAroundTimes
              ? getCurrentProviderTurnaroundTimes(
                  biomarker.turnAroundTimes,
                  result.pathologyProviderId,
                  biomarker.turnAroundTimeAverage || undefined
                )?.maximumTime
              : undefined
          }
        ></ExpectedResultsDate>
      </div>
      <Transition
        show={expandedOpen}
        as={Fragment}
        appear={true}
        enterFrom={`${baseTransitionClasses} scale-95 opacity-0 max-h-0`}
        enterTo={`${baseTransitionClasses} scale-100 opacity-100 max-h-screen`}
        leaveFrom={`${baseTransitionClasses} scale-100 opacity-100 max-h-screen`}
        leaveTo={`${baseTransitionClasses} scale-95 opacity-0 max-h-0`}
      >
        <div className="mt-10">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 sm:col-span-12">
              <ResultInfoSection title={`What is ${biomarker.commonName}?`}>
                {biomarker.description}
              </ResultInfoSection>
            </div>
          </div>
        </div>
      </Transition>
    </Fragment>
  )
}
