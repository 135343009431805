import { Fragment, useEffect, useState } from 'react'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { Modal } from '../../../../layout/Modal'
import { Result, TestTurnaroundTimeBase } from '../../../../../lib/validators'

import RoundedSection from '../../../../layout/RoundedSection'
import { ExpectedResultsDate } from '../../../../ui/ExpectedResultsDate'
import { BiomarkerPill } from '../../../../ui/BiomarkerPill'

interface PackageInfoModalProps {
  show: boolean
  result?: Result
  turnaroundTime?: TestTurnaroundTimeBase
  theme?: ThemeInterface
  setShow: (openState: boolean) => void
}

export function PendingTestsModal({
  show,
  setShow,
  theme,
  result,
  turnaroundTime,
}: PackageInfoModalProps) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(show)
  }, [show, result])

  return (
    <Modal
      show={open}
      options={{
        mainActions: [
          {
            label: 'Dismiss',
            handler: () => setShow(false),
          },
        ],
        title:
          result && result.name
            ? `Pending Tests for: ${result.name}`
            : 'Pending Tests & Biomarkers',
        content: (
          <div>
            {result && (
              <Fragment>
                <div className="mb-4 mt-2 text-lg">
                  Customer: {result.patientName}
                </div>
                <div className="mb-2">
                  <ExpectedResultsDate
                    className={'sm:!inline-block !block'}
                    hideTitle={false}
                    unknownText={'Unknown'}
                    tooltipOverride="Estimated time as we may not have data for turnaround times for all tests."
                    identifier={`${result.uuid}-expected-spotlight`}
                    startDate={result.createdAt}
                    minimumTime={
                      turnaroundTime ? turnaroundTime.minimumTime : undefined
                    }
                    maximumTime={
                      turnaroundTime ? turnaroundTime.maximumTime : undefined
                    }
                  ></ExpectedResultsDate>
                </div>

                {result?.pendingTests?.map((t) => (
                  <RoundedSection
                    size="2xl"
                    className="mb-4"
                    key={`product-${t.id}`}
                    reducedPadding={true}
                  >
                    <b>
                      {t.name}{' '}
                      <ExpectedResultsDate
                        hideTitle={false}
                        unknownText={'Unknown'}
                        className={'sm:!inline-block !block'}
                        tooltipOverride="Estimated time as we may not have data for turnaround times for all tests."
                        identifier={`${result.uuid}-expected-spotlight`}
                        startDate={result.createdAt}
                        minimumTime={t.turnAroundTimeAverage?.minimumTime}
                        maximumTime={t.turnAroundTimeAverage?.maximumTime}
                      ></ExpectedResultsDate>
                    </b>
                    <div className="text-gray-dark text-sm">
                      {t.biomarkers &&
                        t.biomarkers.map((b) => (
                          <BiomarkerPill
                            key={`biomarker-${b.id}`}
                            biomarker={b}
                          ></BiomarkerPill>
                        ))}
                      {t.biomarkers && t.biomarkers.length === 0 && (
                        <div>
                          This test results in a report from the provider
                          handling the test
                        </div>
                      )}
                    </div>
                  </RoundedSection>
                ))}
              </Fragment>
            )}
          </div>
        ),
      }}
      onClose={() => setShow(false)}
      theme={theme || undefined}
      size="2xl"
    ></Modal>
  )
}
