import { Fragment, FunctionComponent } from 'react'
import { StatusTagProps, StatusTagColors } from '../../lib/interfaces'

export const tagColors = [
  StatusTagColors.GREEN,
  StatusTagColors.RED,
  StatusTagColors.YELLOW,
  StatusTagColors.ORANGE,
  StatusTagColors.BLUE,
  StatusTagColors.BGT,
  StatusTagColors.BLOOD,
  StatusTagColors.JADE,
] as const
export type TagColors = (typeof tagColors)[number]

export const StatusTag: FunctionComponent<StatusTagProps> = ({
  className,
  color = StatusTagColors.GREEN,
  label,
  filled = false,
  roundedStatusMode = false,
  value,
}) => {
  let textColor = 'text-green-700'
  let ringColor = 'ring-green-600'
  let bgColor = 'bg-green-50'
  if (!filled) {
    if (color === StatusTagColors.RED) {
      textColor = 'text-red-700'
      ringColor = 'ring-red-600'
      bgColor = 'bg-red-50'
    } else if (color === StatusTagColors.YELLOW) {
      textColor = 'text-yellow-700'
      ringColor = 'ring-yellow-600'
      bgColor = 'bg-yellow-50'
    } else if (color === StatusTagColors.ORANGE) {
      textColor = 'text-orange-700'
      ringColor = 'ring-orange-600'
      bgColor = 'bg-orange-50'
    } else if (color === StatusTagColors.BLUE) {
      textColor = 'text-blue-700'
      ringColor = 'ring-blue-600'
      bgColor = 'bg-blue-50'
    } else if (color === StatusTagColors.BGT) {
      textColor = 'text-bgt-primary'
      ringColor = 'ring-bgt-primary/80'
      bgColor = 'bg-bgt-primary/5'
    } else if (color === StatusTagColors.BLOOD) {
      textColor = 'text-blood'
      ringColor = 'ring-blood'
      bgColor = 'bg-blood/5'
    } else if (color === StatusTagColors.JADE) {
      textColor = 'text-jade'
      ringColor = 'ring-jade'
      bgColor = 'bg-jade/5'
    } else if (color === StatusTagColors.MANDARIN) {
      textColor = 'text-mandarin'
      ringColor = 'ring-mandarin'
      bgColor = 'bg-mandarin/5'
    } else if (color === StatusTagColors.GRAY) {
      textColor = 'text-gray-semi-dark'
      ringColor = 'ring-gray-semi-dark'
      bgColor = 'bg-gray-semi-dark/5'
    }
  } else {
    textColor = 'text-white'
    ringColor = 'ring-green-600'
    bgColor = 'bg-green-600'
    if (color === StatusTagColors.RED) {
      textColor = 'text-white'
      ringColor = 'ring-red-600'
      bgColor = 'bg-red-600/80'
    } else if (color === StatusTagColors.YELLOW) {
      textColor = 'text-white'
      ringColor = 'ring-yellow-600'
      bgColor = 'bg-yellow-600/80'
    } else if (color === StatusTagColors.ORANGE) {
      textColor = 'text-white'
      ringColor = 'ring-orange-600'
      bgColor = 'bg-orange-600/80'
    } else if (color === StatusTagColors.BLUE) {
      textColor = 'text-white'
      ringColor = 'ring-blue-600'
      bgColor = 'bg-blue-500/80'
    } else if (color === StatusTagColors.BGT) {
      textColor = 'text-white'
      ringColor = 'ring-bgt-primary/80'
      bgColor = 'bg-bgt-primary/80'
    } else if (color === StatusTagColors.BLOOD) {
      textColor = 'text-white'
      ringColor = 'ring-blood/80'
      bgColor = 'bg-blood/80'
    } else if (color === StatusTagColors.JADE) {
      textColor = 'text-white'
      ringColor = 'ring-jade/80'
      bgColor = 'bg-jade/80'
    } else if (color === StatusTagColors.MANDARIN) {
      textColor = 'text-white'
      ringColor = 'ring-mandarin/80'
      bgColor = 'bg-mandarin/80'
    } else if (color === StatusTagColors.GRAY) {
      textColor = 'text-white'
      ringColor = 'gray-semi-dark/80'
      bgColor = 'bg-gray-semi-dark/80'
    }
  }

  return (
    <Fragment>
      {roundedStatusMode && (
        <div className="inline-flex h-10 items-center mr-2">
          <span
            className={`flex-none font-bold content-center text-center align-middle rounded-full w-6 h-6 mt-1 mb-1  text-sm ring-2 ring-inset ${ringColor} ${bgColor} ${textColor} dark:bg-transparent mr-1 ${className ? className : ''}`}
          >
            {value && value}
          </span>
          <span>{label}</span>
        </div>
      )}
      {!roundedStatusMode && (
        <span
          className={`inline-flex items-center rounded-md ${bgColor} px-2 py-1 mt-1 mb-1 text-sm font-medium ${textColor} ring-1 ring-inset ${ringColor} dark:bg-transparent ${className ? className : ''}`}
        >
          {label}
        </span>
      )}
    </Fragment>
  )
}
