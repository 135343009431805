import { Fragment, PropsWithChildren } from 'react'
import BeakerIcon from '../../../public/img/beaker.svg'

interface TestCountProps extends PropsWithChildren {}

export function TestCount({ children }: TestCountProps) {
  return (
    <Fragment>
      <img src={BeakerIcon} className="w-4 inline-block mr-1"></img>
      {children}
    </Fragment>
  )
}
