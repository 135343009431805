import { Fragment, PropsWithChildren } from 'react'
import BiomarkerIcon from '../../../public/img/biomarker-icon.svg'

interface BiomarkerCountProps extends PropsWithChildren {}

export function BiomarkerCount({ children }: BiomarkerCountProps) {
  return (
    <Fragment>
      <img src={BiomarkerIcon} className="w-4 inline-block mr-1"></img>
      {children}
    </Fragment>
  )
}
