import { authFetch } from "../providers/AuthProvider"
import { Product, ProductSchema } from "./validators"

export async function archiveProduct(productId: string): Promise<Product> {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        mutation {
          archiveProduct(productId: "${productId}") {
            id
            name
            description
            externalIdentifier
            createdAt
            archivedAt
          }
        }
      `,
    }),
  })
  const data = await result.data
  if (data.errors) {
    throw new Error('Unable to archive product')
  }

  const parsedData = ProductSchema.parse(data.data.archiveProduct)

  return parsedData
}

export async function unarchiveProduct(productId: string): Promise<Product> {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        mutation {
          unarchiveProduct(productId: "${productId}") {
            id
            name
            description
            externalIdentifier
            createdAt
            archivedAt
          }
        }
      `,
    }),
  })
  const data = await result.data
  if (data.errors) {
    throw new Error('Unable to unarchive product')
  }

  const parsedData = ProductSchema.parse(data.data.unarchiveProduct)

  return parsedData
}
