import { z } from 'zod'
import { GenderTypes, ReferralStatus, ResultStatus } from './types'

export const ImageWithCrop = z.object({
  url: z.string().url().optional().nullable(),
  crop: z
    .object({
      x: z.number(),
      y: z.number(),
      height: z.number(),
      width: z.number(),
      unit: z.union([z.literal('px'), z.literal('%')]),
    })
    .optional()
    .nullable(),
})
export const CursorSchema = z.object({
  results: z.number(),
  requested: z.number(),
  hasMoreResults: z.boolean(),
  nextCursor: z.string().nullable(),
})

export const OrganisationThemeSchema = z.object({
  linkColor: z.string().nullable().optional(),
  logo: z.string().nullable().optional(),
  logoOriginal: ImageWithCrop.nullable().optional(),
  logoSquare: z.string().nullable().optional(),
  logoSquareOriginal: ImageWithCrop.nullable().optional(),
  primaryColor: z.string().nullable().optional(),
  secondaryColor: z.string().nullable().optional(),
  headerBarBackgroundColor: z.string().nullable().optional(),
  buttonColor: z.string().nullable().optional(),
  buttonHoverColor: z.string().nullable().optional(),
  buttonTextColor: z.string().nullable().optional(),
})
export type OrganisationTheme = z.infer<typeof OrganisationThemeSchema>

export const BasicOrganisationSchema = z.object({
  id: z.string(),
  uuid: z.string().uuid(),
  name: z.string(),
  withholdResults: z.boolean().nullable().optional(),
  theme: OrganisationThemeSchema.optional().nullable(),
})
export type BasicOrganisation = z.infer<typeof BasicOrganisationSchema>

export const OrganisationSchema = BasicOrganisationSchema.extend({
  id: z.string(),
  uuid: z.string().uuid(),
  name: z.string(),
  resultsEmail: z.string().nullable().optional(),
  prettyResultsEmail: z.string().nullable().optional(),
  billingFailedEmail: z.string().nullable().optional(),
  referralsEmail: z.string().nullable().optional(),
  collectionCentreUrl: z.string().url().nullable().optional(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  theme: OrganisationThemeSchema.optional().nullable(),
  apiTermsAccepted: z.string().nullable().optional(),
  orgTermsAccepted: z.string().nullable().optional(),
  entityName: z.string().optional().nullable(),
  abn: z.string().optional().nullable(),
  enableSubdomain: z.boolean().nullable().default(false),
})
export type Organisation = z.infer<typeof OrganisationSchema>

export const OrganisationListSchema = z.array(OrganisationSchema)
export type OrganisationList = z.infer<typeof OrganisationListSchema>

export const OrganisationUpdateSchema = z.object({
  name: z.string().optional().nullable(),
  resultsEmail: z.string().nullable().optional(),
  prettyResultsEmail: z.string().nullable().optional(),
  billingFailedEmail: z.string().nullable().optional(),
  referralsEmail: z.string().nullable().optional(),
  collectionCentreUrl: z.string().url().nullable().optional(),
  apiTermsAccepted: z.boolean().optional(),
  orgTermsAccepted: z.boolean().optional(),
  entityName: z.string().optional().nullable(),
  abn: z.string().optional().nullable(),
  enableSubdomain: z.boolean().nullable().optional(),
  withholdResults: z.boolean().nullable().optional(),
})
export type OrganisationUpdate = z.infer<typeof OrganisationUpdateSchema>

export const OrganisationUpdatedSchema = z.object({
  name: z.string().optional().nullable(),
  resultsEmail: z.string().nullable().optional(),
  prettyResultsEmail: z.string().nullable().optional(),
  billingFailedEmail: z.string().nullable().optional(),
  referralsEmail: z.string().nullable().optional(),
  collectionCentreUrl: z.string().url().nullable().optional(),
  apiTermsAccepted: z.string().nullable().optional(),
  orgTermsAccepted: z.string().nullable().optional(),
  withholdResults: z.boolean().nullable().optional(),
})
export type OrganisationUpdated = z.infer<typeof OrganisationUpdatedSchema>

export const UserAndProfileSchema = z.object({
  uuid: z.string().uuid(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  image: z.string().url().nullish(),
  imageCrop: ImageWithCrop.optional().nullable(),
  disabled: z.boolean(),
  emailVerified: z.boolean(),
  userSetPassword: z.boolean(),
  acceptedLatestTerms: z.boolean(),
  isAdministrator: z.boolean().default(false),
  profileUuid: z.string().uuid(),
  missingProfileData: z.array(z.string()),
  profileInitComplete: z.boolean(),
  organisations: OrganisationListSchema,
  activeOrganisation: z.string().uuid().nullable(),
  practitionerType: z.string().optional().nullable(),
})
export type UserAndProfile = z.infer<typeof UserAndProfileSchema>

export const UserSchema = z.object({
  uuid: z.string().uuid(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  image: z.string().url().nullish(),
  imageCrop: ImageWithCrop.optional().nullable(),
  disabled: z.boolean(),
  emailVerified: z.boolean(),
  userSetPassword: z.boolean(),
  acceptedLatestTerms: z.boolean(),
  isAdministrator: z.boolean().default(false),
  profileUuid: z.string().uuid(),
  practitionerType: z.string().optional().nullable(),
})
export type User = z.infer<typeof UserSchema>

export const UserSlimSchema = z.object({
  uuid: z.string().uuid(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string().nullable(),
})
export type UserSlim = z.infer<typeof UserSlimSchema>

export const ProfileAddressSchema = z.object({
  addressLine1: z.string(),
  addressLine2: z.string().optional().nullable(),
  postCode: z.number().optional().nullable(),
  city: z.string(),
  country: z.string(),
  state: z.string(),
})

export const ProfileSchema = z.object({
  uuid: z.string().uuid(),
  dob: z.string().pipe(z.coerce.date()).optional(),
  gender: z.enum([GenderTypes.Female, GenderTypes.Male]).optional(),
  phone: z.string().optional(),
  edm: z.boolean().optional(),
  address: ProfileAddressSchema,
})
export type Profile = z.infer<typeof ProfileSchema>
export type ProfileAddress = z.infer<typeof ProfileAddressSchema>

export const FileSchema = z.object({
  uuid: z.string().uuid(),
  filename: z.string(),
  filesize: z.number(),
  extension: z.string().optional().nullable(),
  createdAt: z.coerce.date(),
})
export type File = z.infer<typeof FileSchema>

export const FileListSchema = z.array(FileSchema)
export type FileList = z.infer<typeof FileListSchema>

export const TestTurnaroundTimeBaseSchema = z.object({
  minimumTime: z.number().nullable(),
  maximumTime: z.number().nullable(),
})

export type TestTurnaroundTimeBase = z.infer<
  typeof TestTurnaroundTimeBaseSchema
>

export const ProviderTestTurnaroundTimeSchema =
  TestTurnaroundTimeBaseSchema.extend({
    providerId: z.string(),
    providerName: z.string(),
  })

export type ProviderTestTurnaroundTime = z.infer<
  typeof ProviderTestTurnaroundTimeSchema
>

export const ReferralSchema = z.object({
  uuid: z.string().uuid(),
  status: z.enum([
    ReferralStatus.Complete,
    ReferralStatus.Created,
    ReferralStatus.InsufficientData,
    ReferralStatus.Issued,
    ReferralStatus.PendingVerification,
    ReferralStatus.Refunded,
    ReferralStatus.TestFailed,
    ReferralStatus.PartialResults,
    ReferralStatus.Transferred,
  ]),
  profileUuid: z.string().uuid().nullable().optional(),
  organisationUuid: z.string().uuid().nullable().optional(),
  saleUuid: z.string().uuid(),
  createdAt: z.string().pipe(z.coerce.date()),
  reference: z.string(),
  testName: z.string(),
  resultUuid: z.string().nullable(),
  files: FileListSchema.optional().nullable(),
  fileUuids: z.array(z.string().uuid()).optional().nullable(),
  customerName: z.string().optional().nullable(),
  result: z
    .object({
      uuid: z.string().uuid(),
      status: z.enum([
        ResultStatus.Complete,
        ResultStatus.Created,
        ResultStatus.InReview,
        ResultStatus.PartialResults,
      ]),
    })
    .optional()
    .nullable(),
})

export type Referral = z.infer<typeof ReferralSchema>

export const ReferralListApiSchema = z.object({
  data: z.object({
    referrals: z.array(ReferralSchema),
  }),
})

export const profileAddressDataSchema = z.object({
  addressLine1: z.string(),
  addressLine2: z.string().optional(),
  postCode: z.coerce.number().optional().nullable(),
  city: z.string(),
  country: z.string(),
  state: z.string(),
})
export type ProfileAddressData = z.infer<typeof profileAddressDataSchema>

export const BiomarkerPreviewItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  commonName: z.string(),
  prefix: z.boolean().default(false),
  suffix: z.boolean().default(false),
  unit: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  category: z
    .object({
      name: z.string(),
      id: z.string(),
      description: z.string(),
    })
    .nullable()
    .optional(),
})
export type BiomarkerPreviewItem = z.infer<typeof BiomarkerPreviewItemSchema>

/* result schema defs */
export const ResultSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  createdAt: z.coerce.date(),
  referralUuid: z.string().uuid(),
  patientName: z.string().optional().nullable(),
  status: z.string().optional(),
  resultDate: z.coerce.date(),
  biomarkerSummary: z
    .object({
      total: z.number(),
      abnormal: z.number(),
      normal: z.number(),
      critical: z.number(),
      pending: z.number().optional(),
    })
    .optional()
    .nullable(),
  pendingTests: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        shortName: z.string(),
        biomarkers: z.array(BiomarkerPreviewItemSchema).nullable().optional(),
        turnAroundTimes: z
          .array(ProviderTestTurnaroundTimeSchema)
          .nullable()
          .optional(),
        turnAroundTimeAverage:
          TestTurnaroundTimeBaseSchema.nullable().optional(),
      })
    )
    .nullable()
    .optional(),
  organisation: BasicOrganisationSchema.nullable().optional(),
})

export type Result = z.infer<typeof ResultSchema>

export const ResultSchemaApiWithCursorSchema = z.object({
  results: z.array(ResultSchema),
  cursor: CursorSchema,
})
export type ResultSchemaApiWithCursor = z.infer<
  typeof ResultSchemaApiWithCursorSchema
>

export const ResultSchemaApiSchema = z.object({
  data: z.object({
    results: z.object({
      results: z.array(ResultSchema),
    }),
  }),
})

/* end result schema defs */

export type ApiReferralList = z.infer<typeof ReferralListApiSchema>

export const ReferralStatusSchema = z.object({
  uuid: z.string().uuid(),
  status: z.enum([
    ReferralStatus.Complete,
    ReferralStatus.Created,
    ReferralStatus.InsufficientData,
    ReferralStatus.Issued,
    ReferralStatus.PendingVerification,
    ReferralStatus.Refunded,
    ReferralStatus.TestFailed,
    ReferralStatus.PartialResults,
  ]),
  reference: z.string(),
  testName: z.string(),
  customerName: z.string(),
  profileUuid: z.string().uuid().nullable(),
  organisationUuid: z.string().uuid().nullable().optional(),
  createdAt: z.coerce.date(),
  resultUuid: z.string().uuid().nullable().optional(),
  organisation: BasicOrganisationSchema.optional().nullable(),
})
export const ReferralStatusSchemaList = z.array(ReferralStatusSchema)
export type ReferralStatusType = z.infer<typeof ReferralStatusSchema>

// Definition of a biomarker without a result
// this is basically a union of string and numeric biomarker definitions at the moment
export const BiomarkerItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  commonName: z.string(),
  prefix: z.boolean().default(false),
  suffix: z.boolean().default(false),
  unit: z.string().optional().nullable(),
  refIntervalLow: z.number().nullable(),
  refIntervalHigh: z.number().nullable(),
  description: z.string(),
  refIntervalLowNotes: z.string().nullable().optional(),
  refIntervalHighNotes: z.string().nullable().optional(),
  refIntervalMidNotes: z.string().nullable().optional(),
  valueType: z.union([z.literal('numeric'), z.literal('string')]),
})
export type BiomarkerItem = z.infer<typeof BiomarkerItemSchema>

export const BiomarkerListSchema = z.array(BiomarkerItemSchema)
export type BiomarkerList = z.infer<typeof BiomarkerListSchema>

// pathology providers

export const PathologyProviderSchema = z.object({
  name: z.string(),
  id: z.string(),
})
export type PathologyProvider = z.infer<typeof PathologyProviderSchema>

export const PathologyProviderListSchema = z.array(PathologyProviderSchema)
export type PathologyProviderList = z.infer<typeof PathologyProviderListSchema>

// results

export const BiomarkerCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
})

export const BiomarkerResultBaseSchema = z.object({
  id: z.string(),
  name: z.string(),
  commonName: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  prefix: z.boolean().nullable().optional(),
  suffix: z.boolean().nullable().optional(),
  unit: z.string().nullable().optional(),
  providerId: z.string(),
  category: BiomarkerCategorySchema.nullable().optional(),
})

export const NumericBiomarkerResultSchema = BiomarkerResultBaseSchema.extend({
  valueType: z.literal('numeric'),
  numericValue: z.number(),
  subValueType: z.enum(['lessthan', 'greaterthan']).nullable().optional(),
  refIntervalHighNotes: z.string(),
  refIntervalLowNotes: z.string(),
  refIntervalMidNotes: z.string(),
  refIntervalHigh: z.number().nullable(),
  refIntervalLow: z.number().nullable(),
})

export type NumericBiomarkerResult = z.infer<
  typeof NumericBiomarkerResultSchema
>

export const StringBiomarkerResultSchema = BiomarkerResultBaseSchema.extend({
  valueType: z.literal('string'),
  stringValue: z.string(),
})

export type StringBiomarkerResult = z.infer<typeof StringBiomarkerResultSchema>

export const BiomarkerResultSchema = z.discriminatedUnion('valueType', [
  NumericBiomarkerResultSchema,
  StringBiomarkerResultSchema,
])

export type BiomarkerResult = z.infer<typeof BiomarkerResultSchema>

export const GroupedBiomarkerSchema = z.array(
  z.object({
    category: BiomarkerCategorySchema,
    biomarkers: z.array(BiomarkerResultSchema),
  })
)
export type GroupedBiomarkers = z.infer<typeof GroupedBiomarkerSchema>

export const BiomarkerPreviewItemWithTurnaroundTimesSchema =
  BiomarkerPreviewItemSchema.extend({
    turnAroundTimes: z
      .array(ProviderTestTurnaroundTimeSchema)
      .nullable()
      .optional(),
    turnAroundTimeAverage: TestTurnaroundTimeBaseSchema.nullable().optional(),
  })

export type BiomarkerPreviewItemWithTestTurnaroundTimes = z.infer<
  typeof BiomarkerPreviewItemWithTurnaroundTimesSchema
>

export const MixedPreviewResultGroupedBiomarkerSchema = z.array(
  z.object({
    category: BiomarkerCategorySchema,
    biomarkers: z.array(BiomarkerResultSchema),
    pendingBiomarkers: z.array(BiomarkerPreviewItemWithTurnaroundTimesSchema),
  })
)
export type MixedPreviewResultGroupedBiomarker = z.infer<
  typeof MixedPreviewResultGroupedBiomarkerSchema
>

export const StoreLinkSchema = z.object({
  storeUuid: z.string(),
  storeProvider: z.string(),
  storeName: z.string(),
})

export const ProductSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable().optional(),
  externalIdentifier: z.string().nullable().optional(),
  createdAt: z.string().pipe(z.coerce.date()).optional().nullable(),
  archivedAt: z.coerce.date().nullable(),
  storeLinks: z.array(StoreLinkSchema).optional(),
})

export type Product = z.infer<typeof ProductSchema>

export const ProductListSchema = z.array(ProductSchema)

export type ProductList = z.infer<typeof ProductListSchema>

export const ReferralResultSchema = z.object({
  uuid: z.string(),
  createdAt: z.string().pipe(z.coerce.date()),
  status: z.string(),
  testName: z.string(),
  reference: z.string(),
  product: ProductSchema.optional().nullable(),
  result: z
    .object({
      name: z.string(),
      uuid: z.string(),
      notes: z.string().optional().nullable(),
      createdAt: z.string().pipe(z.coerce.date()),
      biomarkers: z.array(BiomarkerResultSchema).nullable(),
      pathologyProviderId: z.string(),
      resultDate: z.coerce.date(),
      status: z.string(),
      files: z
        .array(
          z.object({
            filename: z.string().optional(),
            filesize: z.number().optional(),
          })
        )
        .optional(),
    })
    .nullable()
    .optional(),
})
export type ReferralResult = z.infer<typeof ReferralResultSchema>

// PDF extracted parsed biomarker
export const PdfBiomarkerResultSchema = z.object({
  value: z.union([z.string(), z.number()]),
  description: z.string(),
  refIntervalHighNotes: z.string(),
  refIntervalLowNotes: z.string(),
  refIntervalMidNotes: z.string(),
  id: z.string(),
  name: z.string(),
  commonName: z.string(),
  prefix: z.boolean(),
  suffix: z.boolean(),
  actualUnit: z.string().optional(),
  expectedUnit: z.string().optional(),
  refIntervalLow: z.number().nullable(),
  refIntervalHigh: z.number().nullable(),
  valueType: z.union([z.literal('numeric'), z.literal('string')]),
})

export type PdfBiomarkerResult = z.infer<typeof PdfBiomarkerResultSchema>

export const CreateOrganisationSchema = z.object({
  uuid: z.string().uuid(),
  id: z.string(),
  name: z.string(),
})
export type CreateOrganisation = z.infer<typeof CreateOrganisationSchema>

export const StripeAddressSchema = z.object({
  line1: z.string(),
  line2: z.string().optional().nullable(),
  postalCode: z.string().optional().nullable(),
  city: z.string().nullable(),
  country: z.string().nullable(),
  state: z.string().nullable(),
})
export type StripeAddress = z.infer<typeof StripeAddressSchema>

export const CreateStripeCustomerSchema = z.object({
  id: z.string(),
  name: z.string(),
  address: StripeAddressSchema,
  phone: z.string(),
})

export type CreateStripeCustomer = z.infer<typeof CreateStripeCustomerSchema>

export const CreateStripeCustomerBasicSchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  phone: z.string().nullable(),
  address: StripeAddressSchema.nullable(),
  email: z.string().nullable(),
})

export type CreateStripeCustomerBasic = z.infer<
  typeof CreateStripeCustomerBasicSchema
>

export const CreateStripeSubscriptionSchema = z.object({
  clientSecret: z.string(),
  type: z.string(),
  productId: z.string(),
  customerId: z.string(),
  subscriptionId: z.string().nullable(),
})
export type CreateStripeSubscription = z.infer<
  typeof CreateStripeSubscriptionSchema
>
// it returns the same payload
export const UpdateCardRequestResponseSchema = CreateStripeSubscriptionSchema
export type UpdateCardRequestResponse = z.infer<
  typeof CreateStripeSubscriptionSchema
>

export const AddCardResponseSchema = z.object({
  clientSecret: z.string(),
  type: z.string(),
  customerId: z.string(),
})
export type AddCardResponse = z.infer<typeof AddCardResponseSchema>

export const CreateOrganisationBillingSchema = z.object({
  clientSecret: z.string(),
  type: z.string(),
  productId: z.string(),
  customerId: z.string(),
  // subscriptionId: z.string(),
})
export type CreateOrganisationBilling = z.infer<
  typeof CreateOrganisationBillingSchema
>

export const StripePaymentMethodSchema = z.object({
  id: z.string(),
  type: z.string(),
  brand: z.string().nullable(),
  expMonth: z.coerce.number(),
  expYear: z.coerce.number(),
  last4: z.string(),
  name: z.string().nullable(),
  phone: z.string().nullable(),
  email: z.string().nullable(),
  address: StripeAddressSchema.nullable(),
  lastChargeError: z
    .object({
      message: z.string(),
      code: z.string(),
      succeeded: z.boolean(),
      amount: z.number().int(),
      currency: z.string(),
    })
    .nullable()
    .optional(),
})
export type StripePayment = z.infer<typeof StripePaymentMethodSchema>

export const BillingCurrentSubscriptionSchema = z.object({
  id: z.string(),
  currency: z.string(),
  currentPeriodStart: z.number(),
  currentPeriodEnd: z.number(),
  trialStart: z.number().nullable(),
  trialEnd: z.number().nullable(),
  planActive: z.boolean(),
  price: z.coerce.number(),
  interval: z.string(),
  status: z.enum([
    'incomplete',
    'incomplete_expired',
    'trialing',
    'active',
    'past_due',
    'canceled',
    'unpaid',
    'paused',
  ]),
  cancelAtEndPeriod: z.boolean(),
  cancelledAt: z.number().nullable(),
  internalPlanId: z.string(),
  internalPlanName: z.string(),
  payment: StripePaymentMethodSchema.nullable().optional(),
})
export type BillingCurrentSubscription = z.infer<
  typeof BillingCurrentSubscriptionSchema
>

export const BillingCurrentSchema = z.object({
  uuid: z.string(),
  subscription: BillingCurrentSubscriptionSchema.nullable(),
  defaultPaymentMethod: StripePaymentMethodSchema.nullable(),
  secondaryPaymentMethod: StripePaymentMethodSchema.nullable(),
  stripeProductId: z.string().nullable(),
  currentPlanId: z.string().nullable(),
  hasTrialed: z.boolean().nullable(),
  customer: CreateStripeCustomerBasicSchema.nullable(),
})
export type BillingCurrent = z.infer<typeof BillingCurrentSchema>

export const BillingInvoiceSchema = z.object({
  id: z.string(),
  hostedInvoiceUrl: z.string().url().nullable(),
  invoicePdfUrl: z.string().url().nullable(),
  paid: z.boolean(),
  number: z.string().nullable(),
  effectiveAt: z.number().nullable(),
  periodStart: z.number(),
  periodEnd: z.number(),
  status: z.string(),
  total: z.number(),
  currency: z.string().nullable(),
})
export type BillingInvoice = z.infer<typeof BillingInvoiceSchema>

export const BillingInvoiceListSchema = z.array(BillingInvoiceSchema)
export type BillingInvoiceList = z.infer<typeof BillingInvoiceListSchema>

export const OrganisationApiCredentialsSchema = z.object({
  clientId: z.string(),
  clientSecret: z.string(),
  active: z.boolean(),
})
export type OrganisationApiCredentials = z.infer<
  typeof OrganisationApiCredentialsSchema
>

export const TestWithBiomarkersSchema = z.object({
  id: z.string(),
  name: z.string(),
  shortName: z.string(),
  price: z.coerce.number(),
  priceCategory: z.string().nullable().optional(),
  reviewFee: z.coerce.number().nullable().optional(),
  standalone: z.boolean().nullable().optional(),
  testType: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  providerId: z.string().nullable().optional(),
  turnAroundTimes: z.array(ProviderTestTurnaroundTimeSchema).nullable(),
  turnAroundTimeAverage: TestTurnaroundTimeBaseSchema.nullable(),
  biomarkers: z.array(BiomarkerPreviewItemSchema).optional().nullable(),
})
export type TestWithBiomarkers = z.infer<typeof TestWithBiomarkersSchema>

export const TestsWithBiomarkersSchema = z.array(TestWithBiomarkersSchema)
export type TestsWithBiomarkers = z.infer<typeof TestsWithBiomarkersSchema>

export const PackageWithTestsAndBiomarkersSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  internal: z.coerce.boolean().optional(),
  tests: TestsWithBiomarkersSchema.nullable().optional(),
  price: z.number(),
  markupAmount: z.number().nullable(),
  createdAt: z.coerce.date(),
  archivedAt: z.coerce.date().nullable(),
  turnAroundTimeAverage: TestTurnaroundTimeBaseSchema.nullable().optional(),
  storeLinks: z.array(StoreLinkSchema).optional(),
})

export type PackageWithTestsAndBiomarkers = z.infer<
  typeof PackageWithTestsAndBiomarkersSchema
>

export const PackagesWithTestsAndBiomarkersSchema = z.array(
  PackageWithTestsAndBiomarkersSchema
)
export type PackagesWithTestsAndBiomarkers = z.infer<
  typeof PackagesWithTestsAndBiomarkersSchema
>

export interface TestWithBiomarkersSelected extends TestWithBiomarkers {
  checked?: boolean
  // price?: number
}

export interface ProductWithTestsAndBiomarkersSelected
  extends PackageWithTestsAndBiomarkers {
  checked?: boolean
}

export const OrganisationProductSchema = z.object({
  id: z.string(),
  // gender: z.union([z.literal('f'), z.literal('m'), z.literal('mf')]),
  // tests: z.array(z.string()).nonempty(),
})
export type OrganisationProduct = z.infer<typeof OrganisationProductSchema>

export const ProductCheckoutSchema = z.object({
  clientSecret: z.string(),
})
export type ProductCheckout = z.infer<typeof ProductCheckoutSchema>

export const ProductCheckoutSessionSchema = z.object({
  status: z.union([z.literal('complete'), z.literal('open')]),
  items: z.array(
    z.object({
      id: z.string(),
      amountTotal: z.number(),
      amountTax: z.number(),
      description: z.string().nullable(),
      discount: z.string().nullable(),
    })
  ),
})
export type ProductCheckoutSession = z.infer<
  typeof ProductCheckoutSessionSchema
>

export const SendPackageResponseSchema = z.array(
  z.object({
    name: z.string(),
    email: z.string(),
    status: z.string(),
    // gender: z.union([z.literal('f'), z.literal('m'), z.literal('mf')]),
    // tests: z.array(z.string()).nonempty(),
  })
)
export type SendPackageResponse = z.infer<typeof SendPackageResponseSchema>

export const CalculatedPriceSchema = z.object({
  testsTotal: z.number(),
  subTotal: z.number(),
  grandTotal: z.number(),
  grandTotalIncTax: z.number(),
  discounts: z.array(
    z.object({
      amount: z.number(),
      reason: z.string(),
    })
  ),
  fees: z.array(
    z.object({
      amount: z.number(),
      reason: z.string(),
    })
  ),
  tax: z
    .array(
      z.object({
        amount: z.number(),
        reason: z.string(),
      })
    )
    .optional()
    .nullable(),
  tests: z.array(
    z.object({
      name: z.string(),
      price: z.number(),
    })
  ),
  // .optional()
  // .nullable(),

  calculating: z.boolean().default(false),
  markup: z.number().nullable(),
})

export type CalculatedPrice = z.infer<typeof CalculatedPriceSchema>

export const BillingAndProductInfoSchema = z.object({
  price: z.number().optional(),
  hasDefaultPaymentMethod: z.boolean(),
})
export type BillingAndProductInfo = z.infer<typeof BillingAndProductInfoSchema>

export const StoreSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  id: z.string(),
  storeProvider: z.string(),
  storeSigningSecret: z.string().optional().nullable(),
  organisationUuid: z.string().nullable(),
})
//
export type Store = z.infer<typeof StoreSchema>

export const StoreListSchema = z.array(StoreSchema)
export type StoreList = z.infer<typeof StoreListSchema>

export const CreateStoreSchema = z.object({
  name: z.string(),
  id: z.string(),
  storeProvider: z.string(),
  storeSigningSecret: z.string().optional().nullable(),
  organisationUuid: z.string().nullable(),
})
//
export type CreateStore = z.infer<typeof CreateStoreSchema>

export const UpdateStoreSchema = z.object({
  uuid: z.string().uuid(),
  storeSigningSecret: z.string().optional().nullable(),
  organisationUuid: z.string().nullable(),
})
//
export type UpdateStore = z.infer<typeof UpdateStoreSchema>

export interface ActiveProduct {
  id: string
  name: string
  externalId?: string
}
export type ActiveProducts = Record<string, ActiveProduct>

export const ConsentDataSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  encodedSignature: z.string(),
})

export type ConsentData = z.infer<typeof ConsentDataSchema>

export const ConsentSchema = z.object({
  uuid: z.string(),
  profileUuid: z.string(),
  entity: z.string(),
  entityUuid: z.string(),
  consentData: ConsentDataSchema,
  signedAt: z.coerce.date(),
})

export type Consent = z.infer<typeof ConsentSchema>

export const ResultAiSummarySchema = z.object({
  summary: z.string(),
})

export type ResultAiSummary = z.infer<typeof ResultAiSummarySchema>

export const HistoricalBiomarkerResultBaseSchema = z.object({
  id: z.string(),
  unit: z.string().optional().nullable(),
  provider: z.string(),
  date: z.coerce.date(),
})

export const HistoricalNumericBiomarkerResultSchema =
  HistoricalBiomarkerResultBaseSchema.extend({
    valueType: z.literal('numeric'),
    subValueType: z.enum(['lessthan', 'greaterthan']).nullable().optional(),
    numericValue: z.number(),
    refIntervalLow: z.number().nullable(),
    refIntervalHigh: z.number().nullable(),
  })

export type HistoricalNumericBiomarkerResult = z.infer<
  typeof HistoricalNumericBiomarkerResultSchema
>

export const HistoricalStringBiomarkerResultSchema =
  HistoricalBiomarkerResultBaseSchema.extend({
    valueType: z.literal('string'),
    stringValue: z.string(),
  })

export type HistoricalStringBiomarkerResult = z.infer<
  typeof HistoricalStringBiomarkerResultSchema
>

export const HistoricalBiomarkerResultSchema = z.discriminatedUnion(
  'valueType',
  [
    HistoricalNumericBiomarkerResultSchema,
    HistoricalStringBiomarkerResultSchema,
  ]
)

export type HistoricalBiomarkerResult = z.infer<
  typeof HistoricalBiomarkerResultSchema
>
export const HistoricalBiomarkerResultsSchema = z.object({
  biomarkers: z.array(HistoricalBiomarkerResultSchema),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
})

export type HistoricalBiomarkerResults = z.infer<
  typeof HistoricalBiomarkerResultsSchema
>

export const FeedbackValueSchema = z.object({
  type: z.enum(['string', 'boolean', 'number']),
  value: z.union([z.string(), z.boolean(), z.number()]),
})

export type FeedbackValue = z.infer<typeof FeedbackValueSchema>

export const FeedbackSchema = z.object({
  uuid: z.string(),
  profileUuid: z.string(),
  feature: z.string(),
  feedback: z.preprocess((input) => {
    if (typeof input === 'string') {
      try {
        return JSON.parse(input)
      } catch {
        throw new Error('Invalid JSON string for feedback')
      }
    }
    return input
  }, FeedbackValueSchema),
  source: z.string(),
  agent: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Feedback = z.infer<typeof FeedbackSchema>

export const HL7TriageSchema = z.object({
  uuid: z.string().uuid(),
  sourceUuid: z.string().uuid(),
  hl7Key: z.string(),
  jsonKey: z.string(),
  reference: z.string().nullable(),
  provider: z.string().nullable(),
  conversationKey: z.string().optional().nullable(),
  status: z.enum(['PENDING', 'REVIEW', 'COMPLETE']),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  hl7Content: z.string().optional().nullable(),
  jsonContent: z.string().optional().nullable(),
})

export type HL7Triage = z.infer<typeof HL7TriageSchema>

// Gender-specific criteria schema
export const OptimalRangeGenderCriteriaSchema = z
  .array(z.string())
  .transform((arr) => Array.from(new Set(arr))) // Deduplicate strings first
  .refine(
    (arr) =>
      arr.every((value) =>
        Object.values(GenderTypes).includes(value as GenderTypes)
      ),
    {
      message: `Gender values must be one of: ${Object.values(GenderTypes).join(
        ', '
      )}`,
    }
  )

// Shared criteria schema for biomarker ranges (currently only gender)
export const OptimalRangeCriteriaSchema = z.object({
  gender: OptimalRangeGenderCriteriaSchema.optional().nullable().default(null),
})

export const BiomarkerRangeSchema = z
  .object({
    low: z.number().optional().nullable().default(null),
    high: z.number().optional().nullable().default(null),
  })
  .refine(
    (range) => range.low !== null || range.high !== null, // Check for null explicitly
    { message: 'Either low or high must be defined for the range.' }
  )
  .refine((range) => (range.low ?? -Infinity) <= (range.high ?? Infinity), {
    message: 'The high value must not be lower than the low value.',
  })

export type BiomarkerRange = z.infer<typeof BiomarkerRangeSchema>

// Schema for a single optimal range spec
export const OptimalRangeSpecSchema = z.object({
  biomarkerId: z.string(),
  unit: z.string().min(1, 'Unit must not be empty.'),
  ranges: z
    .array(BiomarkerRangeSchema)
    .min(1, 'At least one range must be defined for each biomarker.'),
})

// Parent schema for optimal range
export const OptimalRangeSchema = z.object({
  uuid: z.string().uuid(),
  organisationUuid: z.string().uuid().optional().nullable().default(null),
  name: z.string().min(3, 'Title must be at least 3 characters.'),
  // criteriaKey: z.string().default(''),
  criteria: OptimalRangeCriteriaSchema,
  specs: z
    .array(OptimalRangeSpecSchema)
    .min(1, 'At least one biomarker range must be defined.'),
  totalBiomarkers: z.number(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  // deletedAt: z.coerce.date().nullable(),
})

export const BiomarkerRangeProfileUpsertSchema = OptimalRangeSchema.extend({
  uuid: z.string().uuid().optional(),
}).omit({
  totalBiomarkers: true, // We derive this field in upsert
  createdAt: true,
  updatedAt: true,
  // deletedAt: true,
})

export const OptimalRangeLiteSchema = z.object({
  uuid: z.string().uuid(),
  organisationUuid: z.string().uuid().optional().nullable().default(null),
  name: z.string(),
  totalBiomarkers: z.number(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type OptimalRangeGenderCriteria = z.infer<
  typeof OptimalRangeGenderCriteriaSchema
>
export type OptimalRangeCriteria = z.infer<typeof OptimalRangeCriteriaSchema>
export type OptimalRangeSpec = z.infer<typeof OptimalRangeSpecSchema>
export type OptimalRange = z.infer<typeof OptimalRangeSchema>
export type OptimalRangeLite = z.infer<typeof OptimalRangeLiteSchema>

export const ResultProfileSchema = z.object({
  gender: z
    .string()
    .nullable()
    .transform((x) => (x === null ? undefined : x))
    .pipe(z.enum([GenderTypes.Female, GenderTypes.Male]).optional()),

  age: z
    .number()
    .nullable()
    .transform((x) => (x === null ? undefined : x))
    .pipe(z.number().optional()),
  dob: z
    .string()
    .nullable()
    .transform((x) => (x === null ? undefined : x))
    .pipe(z.coerce.date().optional()),
})

export const BasicOrgSchema = z.object({
  name: z.string().optional(),
  logo: z.string().optional(),
})

export type BasicOrg = z.infer<typeof BasicOrgSchema>

export const ResultReferralErrorSchema = z.object({
  error: z.boolean(),
  code: z.string(),
  org: BasicOrgSchema.optional().nullable(),
})
export type ResultReferralError = z.infer<typeof ResultReferralErrorSchema>

export const ResultReferralSchema = z.object({
  name: z.string(),
  uuid: z.string().uuid(),
  profileUuid: z.string().uuid().nullable(),
  notes: z.string().optional().nullable(),
  createdAt: z.string().pipe(z.coerce.date()),
  biomarkers: z.array(BiomarkerResultSchema).nullable(),
  pathologyProviderId: z.string(),
  aiSummaryConsentUuid: z.string().uuid().optional().nullable(),
  aiSummary: z.string().optional().nullable(),
  resultDate: z.coerce.date(),
  reviewedByProfile: z
    .object({
      firstName: z.string(),
      lastName: z.string(),
      image: z.string().url().nullish(),
    })
    .nullable()
    .optional(),
  patientName: z.string().optional().nullable(),
  status: z.string(),
  pendingTests: z
    .array(
      TestWithBiomarkersSchema.omit({
        price: true,
        priceCategory: true,
        providerId: true,
        reviewFee: true,
        standalone: true,
      })
    )
    .nullable(),
  files: z
    .array(
      z.object({
        filename: z.string().optional(),
        filesize: z.number().optional(),
      })
    )
    .optional(),
  referral: z
    .object({
      uuid: z.string(),
      createdAt: z.string().pipe(z.coerce.date()),
      status: z.string(),
      testName: z.string(),
      reference: z.string(),
      referralType: z.string(),
      product: z
        .object({
          id: z.string(),
          name: z.string(),
          description: z.string().nullable().optional(),
        })
        .optional()
        .nullable(),
    })
    .optional()
    .nullable(),
  biomarkerSummary: z
    .object({
      total: z.number(),
      abnormal: z.number(),
      normal: z.number(),
      critical: z.number(),
      pending: z.number().optional(),
    })
    .optional()
    .nullable(),
  organisation: BasicOrganisationSchema.optional().nullable(),
  resultProfile: ResultProfileSchema.optional(),
  optimalRanges: z.array(OptimalRangeSchema).optional(),
})
export type ResultReferral = z.infer<typeof ResultReferralSchema>

export const PayoutSchema = z.object({
  uuid: z.string().uuid(),
  organisationUuid: z.string().uuid(),
  productId: z.string(),
  productName: z.string(),
  markupAmount: z.number().positive(),
  payoutAmount: z.number().positive(),
  feeAmount: z.number().positive(),
  feePercentage: z.number().positive(),
  purchasedBy: z
    .object({
      uuid: z.string().uuid(),
      firstName: z.string(),
      lastName: z.string().nullable(),
    })
    .nullable(),
  status: z.string(),
  paidOutAt: z.coerce.date().nullable(),
  estimatedPaidOutAt: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})
export type Payout = z.infer<typeof PayoutSchema>

export const PayoutHistoryResponseSchema = z.object({
  payouts: z.array(PayoutSchema),
  cursor: CursorSchema,
  unpaidTotal: z.number(),
  estimatedPaidOutAt: z.string().nullable(),
})

export type PayoutHistoryResponse = z.infer<typeof PayoutHistoryResponseSchema>

export const ProductWithPriceSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable().optional(),
  stripeProductId: z.string(),
  createdAt: z.string().pipe(z.coerce.date()).optional().nullable(),
  archivedAt: z.string().pipe(z.coerce.date()).optional().nullable(),
  price: z.number(),
  testsIncluded: z.array(z.string()),
  organisation: BasicOrganisationSchema,
  turnAroundTimeAverage: TestTurnaroundTimeBaseSchema.nullable(),
})
export type ProductWithPrice = z.infer<typeof ProductWithPriceSchema>

const UserAndProfileCreateSchema = UserSchema.merge(ProfileSchema)

export const CreateUserAndProfileSchema = UserAndProfileCreateSchema.omit({
  disabled: true,
  emailVerified: true,
  image: true,
  imageCrop: true,
  isAdministrator: true,
  profileUuid: true,
  uuid: true,
}).extend({
  password: z.string(),
  organisationUuid: z.string().uuid(),
})
export type CreateUserAndProfile = z.infer<typeof CreateUserAndProfileSchema>

export const PaymentIntentSecretSchema = z.object({
  clientSecret: z.string(),
})
export type PaymentIntentSecret = z.infer<typeof PaymentIntentSecretSchema>

export const UserAndProfileCreateResponseSchema = z.object({
  userUuid: z.string().uuid(),
  profileUuid: z.string().uuid(),
  messageTs: z.string().optional().nullable(),
})
export type UserAndProfileCreateResponse = z.infer<
  typeof UserAndProfileCreateResponseSchema
>

export const TestPriceSchema = z.object({
  id: z.string(),
  price: z.number().nullable(),
  eligibleForDiscount: z.boolean(),
})
export type TestPrice = z.infer<typeof TestPriceSchema>

export const ApproveResultResponseSchema = z.object({
  approved: z.boolean(),
})
export type ApproveResultResponse = z.infer<typeof ApproveResultResponseSchema>
