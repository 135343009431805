import React, { FormEvent, useState, useEffect, Fragment } from 'react'
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react'
import { useDebouncedCallback } from 'use-debounce'
import {
  dateInThePast,
  getPrettyDate,
  setDocumentTitle,
} from '../../../lib/utils'
import Checkbox from '../../form/Checkbox'
import TextInputField, { onChangeInterface } from '../../form/TextInputField'
import { PageHeading } from '../../layout/PageHeading'
import PageSection from '../../layout/PageSection'
import AdminPage from './AdminPage'
import {
  profileAddressDataSchema,
  TestWithBiomarkers,
  UserSlim,
} from '../../../lib/validators'
import {
  getReferralForReference,
  getReferralsForTransfer,
  ReferralForReference,
  ReferralForReferencePerson,
  ReferralForReferenceSchema,
  ReferralForTransfer,
  searchUsers,
  transferReferrals,
  updateReferral,
  validTransferStatuses,
} from '../../../lib/admin'
import toast from 'react-hot-toast'
import { StatusTag } from '../../layout/StatusTag'
import { StatusTagColors } from '../../../lib/interfaces'
import { InfoSection } from '../../ui/InfoSection'
import RoundedSection from '../../layout/RoundedSection'
import { Button } from '../../form/button/Button'

import { Pill } from '../../ui/BiomarkerPill'
import { useForm, useStore } from '@tanstack/react-form'
import { z } from 'zod'
import { getTests } from '../../../lib/organisation'
import { ReferralStatus } from '../../../lib/types'
import SimpleSelectField from '../../form/SimpleSelectField'

function TransferReferralsSection() {
  const [userSearch, setUserSearch] = useState<string>('')
  const [users, setUsers] = useState<Array<UserSlim>>([])
  const [selectedUser, setSelectedUser] = useState<UserSlim | null>(null)
  const [selectedReferrals, setSelectedReferrals] = useState<string[]>([])
  const [recipientEmail, setRecipientEmail] = useState<string>('')
  const [recipientFirstName, setRecipientFirstName] = useState<string>('')
  const [referrals, setReferrals] = useState<ReferralForTransfer[]>([])

  const setUserSearchDebounced = useDebouncedCallback((searchTerm: string) => {
    setUserSearch(searchTerm)
  }, 500)

  useEffect(() => {
    async function fetchSearchUsers() {
      console.log('searchUsers with', userSearch)
      const users = await searchUsers(userSearch)
      setUsers(users)
    }
    fetchSearchUsers()
  }, [userSearch])

  useEffect(() => {
    async function fetchReferrals(user: UserSlim) {
      console.log('fetch referrals for user', user)
      const referrals = await getReferralsForTransfer(
        user.uuid,
        validTransferStatuses
      )
      console.log('got referrals', referrals)
      setReferrals(referrals)
    }

    setSelectedReferrals([])

    if (selectedUser) {
      fetchReferrals(selectedUser)
    } else {
      setReferrals([])
    }
  }, [selectedUser])

  const handleReferralCheckboxChange = (referralId: string) => {
    setSelectedReferrals(
      (prev) =>
        prev.includes(referralId)
          ? prev.filter((id) => id !== referralId) // Remove if already selected
          : [...prev, referralId] // Add if not selected
    )
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    console.log('Transferring Referral', {
      selectedUser,
      selectedReferrals,
      recipientEmail,
      recipientFirstName,
    })
    try {
      await transferReferrals({
        recipientFirstName,
        recipientEmail,
        referrals: selectedReferrals,
        fromUserUuid: selectedUser!.uuid,
      })
      toast.success('Transferred referrals')
      clearForm()
    } catch (error) {
      toast.error('Failed to transfer referrals')
    }
  }

  const clearForm = () => {
    setUserSearch('')
    setSelectedUser(null)
    setUsers([])
    setSelectedReferrals([])
    setRecipientEmail('')
    setRecipientFirstName('')
    setReferrals([])
  }

  return (
    <>
      <h2 className="text-lg font-semibold text-black/70 dark:text-white mb-2 select-none">
        Transfer Referral
      </h2>
      <InfoSection>
        This tool allows you to transfer referrals to a new recipient for
        referrals of status {validTransferStatuses.toString()}. Referrals can be
        transferred in bulk for a single organisation at a time i.e. the
        selected referrals Organisation UUID must be the same or none for
        selected referrals.
      </InfoSection>
      <form className="max-w-5xl space-y-4" onSubmit={handleSubmit}>
        {/* User Search Combobox */}
        <div className="pb-5">
          <label className="block text-sm font-medium text-gray-700">
            User Search
          </label>
          <Combobox value={selectedUser} onChange={setSelectedUser}>
            <div className="relative mt-1">
              <ComboboxInput
                className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm leading-5 shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500"
                onChange={(event) => {
                  setUserSearchDebounced(event.target.value)
                  setSelectedUser(selectedUser)
                  setReferrals([])
                }}
                displayValue={(user: UserSlim | null) =>
                  user
                    ? `${user.firstName} ${user.lastName} | ${user.email}`
                    : ''
                }
                placeholder="Search for users..."
              />
              <ComboboxOptions className="absolute w-full mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-50">
                {users.length === 0 && userSearch !== '' ? (
                  <div className="cursor-default select-none py-2 px-4 text-gray-700">
                    No users found.
                  </div>
                ) : (
                  users.map((user) => (
                    <ComboboxOption
                      key={user.uuid}
                      value={user}
                      className={({ focus }) =>
                        `cursor-default select-none relative py-2 pl-10 pr-4 ${
                          focus ? 'text-white bg-indigo-600' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {user.firstName} {user.lastName} | {user.email}
                          </span>
                        </>
                      )}
                    </ComboboxOption>
                  ))
                )}
              </ComboboxOptions>
            </div>
          </Combobox>
        </div>

        {/* Multi-Select Referrals with Checkboxes */}
        <div className="pb-5">
          <label className="block text-sm font-medium text-gray-700">
            Select Referrals (Reference | Test Name | Status | Customer Name |
            Organisation UUID)
          </label>
          <div className="mt-1 flex flex-col gap-4">
            {referrals.map((referral) => (
              <div key={referral.uuid} className="inline-flex items-center">
                <Checkbox
                  id={referral.uuid}
                  name="selectedReferrals"
                  type="checkbox"
                  checked={selectedReferrals.includes(referral.uuid)}
                  onChange={() => handleReferralCheckboxChange(referral.uuid)}
                  checkboxLabel={
                    <span>
                      {referral.reference} | {referral.testName} |{' '}
                      <StatusTag
                        color={StatusTagColors.JADE}
                        label={referral.status}
                      />{' '}
                      | {referral.customerName} |{' '}
                      {referral.organisationUuid ?? ''}
                    </span>
                  }
                />
              </div>
            ))}
          </div>
        </div>

        {/* Recipient First Name */}
        <div className="pb-5">
          <TextInputField
            id={'recipientFirstName'}
            label="Recipient First Name"
            name="recipientFirstName"
            required={true}
            type={'text'}
            value={recipientFirstName}
            handleChange={(data: onChangeInterface) =>
              setRecipientFirstName(data.value.toString())
            }
            placeholder="Enter recipient's first name..."
          />
        </div>

        {/* Recipient Email Address */}
        <div className="pb-5">
          <TextInputField
            id="recipientEmail"
            label="Recipient Email"
            name="recipientEmail"
            required={true}
            type="email"
            value={recipientEmail}
            handleChange={(data: onChangeInterface) =>
              setRecipientEmail(data.value.toString())
            }
            placeholder="Enter recipient email address..."
          />
        </div>

        {/* Submit and Clear Buttons */}
        <div className="flex space-x-4 pt-5">
          <button
            type="button"
            onClick={clearForm}
            className="flex-1 px-4 py-2 font-semibold text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
          >
            Reset
          </button>
          <button
            type="submit"
            className="flex-1 px-4 py-2 font-semibold text-white bg-jade rounded-md hover:bg-jade/80 disabled:!cursor-default disabled:bg-gray-dark disabled:!text-gray-700"
            disabled={
              !selectedUser ||
              selectedReferrals.length === 0 ||
              !recipientFirstName ||
              !recipientEmail
            }
          >
            Transfer Referral
          </button>
        </div>
      </form>
    </>
  )
}

function UpdateReferralSection() {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searching, setSearching] = useState<boolean>(false)
  const [referral, setReferral] = useState<ReferralForReference>()
  const [tests, setTests] = useState<TestWithBiomarkers[]>()
  const handleSearch = (e: FormEvent) => {
    e.preventDefault()
    if (searchTerm === '') {
      return
    }
    setSearching(true)
    ;(async () => {
      try {
        const referral = await getReferralForReference(searchTerm)
        const tests = await getTests()
        setReferral(referral)
        setTests(tests)
        setSearching(false)
      } catch (e) {
        console.log(e)
        if (e instanceof Error) {
          toast.error(e.message)
        }
        setSearching(false)
        setReferral(undefined)
      }
    })()
  }

  const handleSubmit = async (data: ReferralForReferenceData) => {
    try {
      await updateReferral({
        referral: ReferralForReferenceFormSchema.parse(data),
      })
      toast.success(`Referral ${data.reference} Updated`)
      setReferral(undefined)
      setSearchTerm('')
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message)
      } else {
        toast.error('An unknown error occured.')
      }
    }
  }

  return (
    <div>
      <h2 className="text-lg font-semibold text-black/70 dark:text-white mb-2 select-none">
        View/Update Referral
      </h2>
      <form className="max-w-5xl space-y-4" onSubmit={handleSearch}>
        {/* User Search Combobox */}
        <div className="pb-5">
          <TextInputField
            id="referenceSearch"
            name="referenceSearch"
            type="text"
            handleChange={(e) => setSearchTerm(e.strValue)}
            label={
              <div className="inline-block">
                Reference Number Search - note only{' '}
                <StatusTag
                  label="ISSUED"
                  color={StatusTagColors.GREEN}
                ></StatusTag>{' '}
                <StatusTag
                  label="CREATED"
                  color={StatusTagColors.GREEN}
                ></StatusTag>{' '}
                referrals can be edited.
              </div>
            }
            placeholder="Enter reference number"
            required={true}
            value={searchTerm}
          ></TextInputField>
          <Button
            classNames="mt-2"
            type="submit"
            disabled={searchTerm.length < 'SM43WHAM'.length || searching}
          >
            {searching ? <span>Searching...</span> : <span>Search</span>}
          </Button>
          <Button
            classNames="ml-2 mt-2"
            type="button"
            disabled={!(searchTerm.length > 1) || searching}
            onClick={() => {
              setSearchTerm('')
              setReferral(undefined)
            }}
          >
            Clear
          </Button>
        </div>
      </form>
      {referral && tests && (
        <EditReferralForm
          onSubmit={handleSubmit}
          referral={referral}
          tests={tests}
        ></EditReferralForm>
      )}
    </div>
  )
}

const isReferralEditable = (referral: ReferralForReference): boolean => {
  return (
    referral.status === ReferralStatus.Created ||
    referral.status === ReferralStatus.Issued
  )
}

interface EditReferralFormProps {
  referral: ReferralForReference
  tests: TestWithBiomarkers[]
  onSubmit: (data: ReferralForReferenceData) => void
}
const ReferralForReferenceFormSchema = ReferralForReferenceSchema.omit({
  organisation: true,
  organisationUuid: true,
}).extend({
  person: ReferralForReferencePerson.extend({
    dob: z.string(),
    lastName: z.string(),
    address: profileAddressDataSchema.extend({
      postCode: z.string(),
    }),
  }),
  shouldEmailReferral: z.boolean(),
  organisationUuid: z.string(),
  organisationId: z.string(),
  status: z.string(),
})
export type ReferralForReferenceData = z.infer<
  typeof ReferralForReferenceFormSchema
>
export const EditReferralForm: React.FC<EditReferralFormProps> = ({
  referral,
  tests,
  onSubmit,
}) => {
  const [testQuery, setTestQuery] = useState('')
  const testArrayKey = 'testList' as const

  const defaultValues: ReferralForReferenceData = React.useMemo(
    () => ({
      ...referral,
      person: {
        ...referral.person,
        dob: referral.person.dob ? getPrettyDate(referral.person.dob) : '',
        lastName: referral.person.lastName || '',
        address: {
          ...referral.person.address,
          postCode: referral.person.address.postCode
            ? referral.person.address.postCode.toString()
            : '',
        },
      },
      shouldEmailReferral: false,
      organisationId: referral.organisation?.id || '',
      organisationUuid: referral.organisation?.uuid || '',
      status: referral.status,
    }),
    [referral]
  )

  const form = useForm<ReferralForReferenceData>({
    defaultValues: defaultValues,
    onSubmit: async (formData) => {
      onSubmit(formData.value)
    },
    validators: {
      onChange: ReferralForReferenceSchema,
      onSubmit: ReferralForReferenceSchema,
      onBlur: ReferralForReferenceSchema,
      onMount: ReferralForReferenceSchema,
    },
  })

  useEffect(() => {
    form.reset()
  }, [form, defaultValues])

  // Get the list of already selected biomarkers in `specs`
  const existingTestItems = useStore(
    form.store,
    (state) => state.values.testList
  )
  const filteredTestList = tests.filter(
    (test) =>
      !existingTestItems.includes(test.id) &&
      (test.name.toLowerCase().includes(testQuery.toLowerCase()) ||
        test.shortName.toLowerCase().includes(testQuery.toLowerCase()))
  )

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
      className="p-6 space-y-8"
    >
      <h2 className="text-lg font-semibold text-black/70 dark:text-white mb-2 select-none">
        Referral Details
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-2">
        {!isReferralEditable(referral) && (
          <div className="sm:col-span-2">
            <InfoSection>
              <b>This referral is not editable as its status is </b>{' '}
              <StatusTag
                color={
                  referral.status === ReferralStatus.Issued
                    ? StatusTagColors.GREEN
                    : StatusTagColors.MANDARIN
                }
                label={referral.status}
              ></StatusTag>{' '}
              {referral.status !== ReferralStatus.Transferred && (
                <span>but you can still change its status.</span>
              )}
            </InfoSection>
          </div>
        )}
        {referral.organisation && (
          <Fragment>
            <div className="block text-sm font-medium leading-6 text-gray-dark mt-4">
              Organisation
            </div>
            <div className="mt-4">
              <span className="font-bold">{referral.organisation.name}</span>
              {referral.organisation.theme &&
                referral.organisation.theme.logo && (
                  <div
                    className=" rounded-lg p-2 max-w-40 bg-[--bg-color] mb-2"
                    style={
                      {
                        '--bg-color':
                          referral.organisation.theme
                            .headerBarBackgroundColor || 'transparent',
                      } as React.CSSProperties
                    }
                  >
                    <img src={referral.organisation.theme.logo}></img>
                  </div>
                )}
            </div>
          </Fragment>
        )}
        {referral.organisation && (
          <Fragment>
            <form.Field
              name="organisationUuid"
              validators={{
                onChange: ReferralForReferenceFormSchema.shape.organisationUuid,
              }}
            >
              {(field) => (
                <Fragment>
                  <TextInputField
                    id={field.name}
                    disabled={true}
                    name={field.name}
                    type="text"
                    label={'Organisation UUID'}
                    value={field.state.value}
                  ></TextInputField>
                  {field.state.meta.isTouched &&
                    field.state.meta.errors.length > 0 && (
                      <p className="text-red-500 text-sm mt-2">
                        {field.state.meta.errors.join(', ')}
                      </p>
                    )}
                </Fragment>
              )}
            </form.Field>
            <form.Field
              name="organisationId"
              validators={{
                onChange: ReferralForReferenceFormSchema.shape.organisationId,
              }}
            >
              {(field) => (
                <Fragment>
                  <TextInputField
                    id={field.name}
                    disabled={true}
                    name={field.name}
                    type="text"
                    label={'Organisation ID'}
                    value={field.state.value}
                  ></TextInputField>
                  {field.state.meta.isTouched &&
                    field.state.meta.errors.length > 0 && (
                      <p className="text-red-500 text-sm mt-2">
                        {field.state.meta.errors.join(', ')}
                      </p>
                    )}
                </Fragment>
              )}
            </form.Field>
          </Fragment>
        )}
        <div className="block text-sm font-medium leading-6 text-gray-dark mt-4">
          Status
        </div>
        <div className="mt-4">
          <StatusTag
            color={
              referral.status === ReferralStatus.Issued
                ? StatusTagColors.GREEN
                : StatusTagColors.MANDARIN
            }
            label={referral.status}
          ></StatusTag>
          <div>
            <form.Field
              name="status"
              validators={{
                onChange: ReferralForReferenceFormSchema.shape.status,
              }}
            >
              {(field) => (
                <Fragment>
                  <SimpleSelectField
                    name={field.name}
                    label="Set New Status"
                    disabled={field.state.value === ReferralStatus.Transferred}
                    options={[
                      {
                        label: '',
                        value: '',
                      },

                      {
                        label: 'Partial Results',
                        value: ReferralStatus.PartialResults,
                      },
                      {
                        label: 'Complete',
                        value: ReferralStatus.Complete,
                      },
                      {
                        label: 'Refunded',
                        value: ReferralStatus.Refunded,
                      },
                      {
                        label: 'Transferred (not reversible)',
                        value: ReferralStatus.Transferred,
                      },
                    ]}
                    value={field.state.value}
                    handleChange={(e) => field.handleChange(e.strValue)}
                  ></SimpleSelectField>
                  {field.state.meta.isTouched &&
                    field.state.meta.errors.length > 0 && (
                      <p className="text-red-500 text-sm mt-2">
                        {field.state.meta.errors.join(', ')}
                      </p>
                    )}
                </Fragment>
              )}
            </form.Field>
          </div>
        </div>
        <div className="block text-sm font-medium leading-6 text-gray-dark mt-4">
          Via API
        </div>
        <div className="mt-4">
          {referral.organisationUuid && !referral.profileUuid ? 'YES' : 'NO'}
        </div>
        <form.Field
          name="uuid"
          validators={{ onChange: ReferralForReferenceFormSchema.shape.uuid }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                disabled={true}
                name={field.name}
                type="text"
                label={'UUID'}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="reference"
          validators={{
            onChange: ReferralForReferenceFormSchema.shape.reference,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                disabled={true}
                name={field.name}
                type="text"
                label={'Reference'}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="person.email"
          validators={{
            onChange: ReferralForReferenceFormSchema.shape.person.shape.email,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                disabled={true}
                name={field.name}
                type="text"
                label={'Email'}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="person.firstName"
          validators={{
            onChange:
              ReferralForReferenceFormSchema.shape.person.shape.firstName,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                name={field.name}
                disabled={!isReferralEditable(referral)}
                type="text"
                label={'First Name'}
                required={true}
                handleChange={(e) => field.handleChange(e.strValue)}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="person.lastName"
          validators={{
            onChange:
              ReferralForReferenceFormSchema.shape.person.shape.lastName,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                name={field.name}
                type="text"
                disabled={!isReferralEditable(referral)}
                label={'Last Name'}
                handleChange={(e) => field.handleChange(e.strValue)}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="person.address.addressLine1"
          validators={{
            onChange:
              ReferralForReferenceFormSchema.shape.person.shape.address.shape
                .addressLine1,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                name={field.name}
                required={true}
                disabled={!isReferralEditable(referral)}
                type="text"
                label={'Address Line 1'}
                handleChange={(e) => field.handleChange(e.strValue)}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="person.address.addressLine2"
          validators={{
            onChange:
              ReferralForReferenceFormSchema.shape.person.shape.address.shape
                .addressLine2,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                name={field.name}
                type="text"
                label={'Address Line 2'}
                disabled={!isReferralEditable(referral)}
                handleChange={(e) => field.handleChange(e.strValue)}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="person.address.city"
          validators={{
            onChange:
              ReferralForReferenceFormSchema.shape.person.shape.address.shape
                .city,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                name={field.name}
                type="text"
                label={'City'}
                disabled={!isReferralEditable(referral)}
                required={true}
                handleChange={(e) => field.handleChange(e.strValue)}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="person.address.state"
          validators={{
            onChange:
              ReferralForReferenceFormSchema.shape.person.shape.address.shape
                .state,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                name={field.name}
                type="text"
                label={'State'}
                required={true}
                disabled={!isReferralEditable(referral)}
                handleChange={(e) => field.handleChange(e.strValue)}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="person.address.country"
          validators={{
            onChange:
              ReferralForReferenceFormSchema.shape.person.shape.address.shape
                .country,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                name={field.name}
                type="text"
                label={'Country'}
                required={true}
                disabled={!isReferralEditable(referral)}
                handleChange={(e) => field.handleChange(e.strValue)}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="person.address.postCode"
          validators={{
            onChange:
              ReferralForReferenceFormSchema.shape.person.shape.address.shape
                .postCode,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                name={field.name}
                type="text"
                label={'Post Code'}
                disabled={!isReferralEditable(referral)}
                handleChange={(e) => field.handleChange(e.strValue)}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="person.phone"
          validators={{
            onChange: ReferralForReferenceFormSchema.shape.person.shape.phone,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                name={field.name}
                type="text"
                label={'Phone Number'}
                required={true}
                disabled={!isReferralEditable(referral)}
                handleChange={(e) => field.handleChange(e.strValue)}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Field
          name="person.dob"
          validators={{
            onChange: ReferralForReferenceFormSchema.shape.person.shape.dob,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                name={field.name}
                label={'Date of Birth'}
                type="date"
                min={dateInThePast(130)}
                max={dateInThePast(18)}
                required={true}
                disabled={!isReferralEditable(referral)}
                handleChange={(e) => field.handleChange(e.strValue)}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <div>--</div>
        <div>--</div>
        <form.Field
          name="testName"
          validators={{
            onChange: ReferralForReferenceFormSchema.shape.testName,
          }}
        >
          {(field) => (
            <Fragment>
              <TextInputField
                id={field.name}
                name={field.name}
                type="text"
                label={'Product Name'}
                disabled={true}
                handleChange={(e) => field.handleChange(e.strValue)}
                value={field.state.value}
              ></TextInputField>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <div className="block text-sm font-medium leading-6 text-gray-dark mt-4">
          Tests on Referral
        </div>
        <div className="">
          <form.Field name={testArrayKey} mode="array">
            {(field) => (
              <Fragment>
                {field.state.value.map((test, idx) => (
                  <Pill
                    editable={isReferralEditable(referral)}
                    key={test}
                    onDelete={() => field.removeValue(idx)}
                  >
                    {tests.find((t) => test === t.id)?.name ?? test}
                  </Pill>
                ))}
                {field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0 && (
                    <p className="text-red-500 text-sm mt-2 mb-2">
                      {field.state.meta.errors.join(', ')}
                    </p>
                  )}
                <Combobox
                  immediate={true}
                  disabled={!isReferralEditable(referral)}
                  onChange={(selectedTest: TestWithBiomarkers | null) => {
                    if (selectedTest === null) return

                    field.pushValue(selectedTest.id)
                    setTestQuery('')

                    setTimeout(() => {
                      const elem = document.getElementById('test-selector')
                      if (elem) {
                        elem.blur()
                      }
                    }, 1)
                  }}
                >
                  <div className="relative mt-1">
                    <ComboboxInput
                      className="cursor-pointer border-0 w-full rounded-md py-2 px-3 focus:outline-none ring-1 ring-inset ring-gray-dark/30 focus:ring-2 shadow-sm focus:ring-gray-dark/30 dark:bg-dark-gray-light dark:text-white"
                      id="test-selector"
                      onChange={(event) => setTestQuery(event.target.value)}
                      placeholder="Add a test to this referral"
                      onBlur={() => setTestQuery('')}
                    />
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 min-w-fit max-w-sm overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredTestList.map((test) => (
                        <ComboboxOption
                          key={test.id}
                          value={test}
                          className={({ focus }) =>
                            `cursor-pointer select-none relative py-2 pl-3 pr-9 ${
                              focus ? 'bg-gray-400 text-white' : 'text-gray-900'
                            }`
                          }
                        >
                          {({ focus }) => (
                            <span
                              className={`block break-words ${
                                focus ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {test.name} ({test.shortName})
                            </span>
                          )}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </div>
                </Combobox>
              </Fragment>
            )}
          </form.Field>
        </div>
        <div className="block text-sm font-medium leading-6 text-gray-dark mt-4">
          Email new referral to user
        </div>
        <form.Field
          name="shouldEmailReferral"
          validators={{
            onChange: ReferralForReferenceFormSchema.shape.shouldEmailReferral,
          }}
        >
          {(field) => (
            <Fragment>
              <Checkbox
                type="checkbox"
                id={field.name}
                name={field.name}
                label={''}
                disabled={!isReferralEditable(referral)}
                className={'w-6 h-6 mt-2 mb-2'}
                onChange={(e) => {
                  console.log(e)
                  field.handleChange(e.strValue === 'true' ? true : false)
                }}
                checked={field.state.value}
              ></Checkbox>
              {field.state.meta.isTouched &&
                field.state.meta.errors.length > 0 && (
                  <p className="text-red-500 text-sm mt-2">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )}
            </Fragment>
          )}
        </form.Field>
        <form.Subscribe
          selector={(state) => [
            state.canSubmit,
            state.isSubmitting,
            state.isDirty,
          ]}
        >
          {([canSubmit, isSubmitting, isDirty]) => {
            return (
              <Fragment>
                {referral.status === 'CREATED' && (
                  <Fragment>
                    <div></div>
                    <div>
                      <StatusTag
                        color={StatusTagColors.MANDARIN}
                        className="!font-bold"
                        label="Saving this form will automatically mark this referral as ISSUED"
                      ></StatusTag>
                    </div>
                  </Fragment>
                )}
                <div></div>

                <div>
                  <Button
                    color="jade"
                    label="Save"
                    type="submit"
                    disabled={!isDirty || !canSubmit || isSubmitting}
                    classNames="mr-4"
                  />
                  <Button
                    color="blood"
                    label="Reset"
                    type="button"
                    onClick={() => form.reset()}
                    disabled={isSubmitting}
                  />
                </div>
              </Fragment>
            )
          }}
        </form.Subscribe>
      </div>
    </form>
  )
}

export default function AdminReferralsPage() {
  setDocumentTitle('Referrals Admin', 'CLEAN')

  return (
    <AdminPage>
      <PageSection>
        <PageHeading title="Referrals Admin" />
        <RoundedSection size="xl" className="mb-8">
          <TransferReferralsSection />
        </RoundedSection>
        <RoundedSection size="xl">
          <UpdateReferralSection />
        </RoundedSection>
      </PageSection>
    </AdminPage>
  )
}
