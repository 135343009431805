interface LoadMoreProps {
  onClick: () => void
  loading?: boolean
}

export function LoadMore({ onClick, loading }: LoadMoreProps) {
  const handleClick = () => {
    if (loading) {
      return
    }
    onClick()
  }

  return (
    <div
      className={`rounded-md border  text-center block border-gray-semi-dark/50 bg-gray-light shadow-sm px-4 py-2 text-blood hover:text-blood/80  ml-auto mr-auto ${loading ? 'text-gray-700 cursor-wait' : 'text-blood cursor-pointer hover:text-blood/80'}`}
      onClick={() => handleClick()}
    >
      Load more
    </div>
  )
}
