import PageSection from '../../../../layout/PageSection'
import PriceSummary from '../../../../organisation/PriceSummary'
import {
  CalculatedPrice,
  TestWithBiomarkersSelected,
} from '../../../../../lib/validators'

interface TestCreatorSummaryProps {
  hide: boolean

  totalPrice: CalculatedPrice
  tests: TestWithBiomarkersSelected[]
  bundleMarkup?: number
  setBundleMarkup: (markup: number) => void
}

export function TestCreatorSummary({
  hide,
  totalPrice,
  tests,
  bundleMarkup,
  setBundleMarkup,
}: TestCreatorSummaryProps) {
  return (
    <div
      className={`sm:flex-none sm:min-w-96 sm:max-w-96 ${hide ? 'hidden' : 'block'}`}
    >
      <div
        className={`block mt-2 sm:mt-0 sm:sticky sm:m-t-[8.8rem] sm:top-[4.4rem] sm:left-[1328px] ml-0 sm:ml-2 transition duration-200 ${hide ? 'hidden' : ''}`}
      >
        <PageSection>
          <PriceSummary
            price={totalPrice}
            showSummary={true}
            tests={tests}
            markup={bundleMarkup}
            onMarkupChange={(markup) => setBundleMarkup(markup || 0)}
          ></PriceSummary>
        </PageSection>
      </div>
    </div>
  )
}
