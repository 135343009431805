import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import {
  fieldColorClass,
  fieldHoverBgColorClass,
  fieldHoverTextColorClass,
  fieldShapeClass,
  fieldTextColorClass,
  listBoxButtonClass,
} from '../form/styles'

import {
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Fragment, useMemo } from 'react'

export type MultiSelectOption = {
  key: string
  name: string
  icon?: JSX.Element
}

type FilterMultiSelectProps = {
  filterName: string
  filterNamePlural: string
  options: MultiSelectOption[]
  selectedKeys: string[]
  onChange: (selectedKeys: string[]) => void
}

export const FilterMultiSelect: React.FC<FilterMultiSelectProps> = ({
  options,
  selectedKeys,
  onChange,
  filterName,
  filterNamePlural,
}) => {
  const toggleOption = (key: string) => {
    const updatedKeys = selectedKeys.includes(key)
      ? selectedKeys.filter((k) => k !== key)
      : [...selectedKeys, key]

    onChange(updatedKeys)
  }

  const isAllSelected = useMemo(() => {
    return selectedKeys.filter((tt) => tt === 'all').length === 1 ? true : false
  }, [selectedKeys])

  return (
    <div className="relative sm:w-60 w-full">
      <Listbox as="div" value={selectedKeys} onChange={() => {}} multiple>
        <ListboxButton className={listBoxButtonClass}>
          <span>
            {isAllSelected
              ? `${filterName} - Showing all`
              : `${selectedKeys.length} ${(selectedKeys.length !== 1 ? filterNamePlural : filterName).toLowerCase()} selected`}
          </span>
          {!isAllSelected && (
            <XMarkIcon
              className="h-5 w-5 text-gray-500 hover:text-blood"
              aria-hidden="true"
              onClick={() => toggleOption('all')}
            />
          )}
          {isAllSelected && (
            <ChevronDownIcon
              className="h-5 w-5 text-gray-500 hover:text-blood"
              aria-hidden="true"
            />
          )}
        </ListboxButton>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ListboxOptions
            className={`absolute z-10 mt-2 max-h-60 w-full overflow-auto py-1 ${fieldShapeClass} ${fieldColorClass} shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            {options.map((option) => (
              <ListboxOption key={option.key} value={option.key}>
                {({ selected }) => (
                  <div
                    onClick={() => toggleOption(option.key)}
                    className={`flex cursor-pointer items-center gap-2 px-4 py-2 ${fieldTextColorClass} ${fieldHoverBgColorClass} ${fieldHoverTextColorClass}`}
                  >
                    {option.icon && (
                      <span className="h-5 w-5 text-jade/80">
                        {option.icon}
                      </span>
                    )}
                    <span>{option.name}</span>
                    {selected && (
                      <CheckIcon className="ml-auto h-5 w-5 text-jade/80" />
                    )}
                  </div>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </Listbox>
    </div>
  )
}
