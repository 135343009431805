interface HighlightProps {
  text: string
  term?: string
}

function escapeRegExp(string: string): string {
  // Escapes any characters that would be interpreted specially by a regex
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function Highlight({ text, term }: HighlightProps) {
  // If no term or term is too short, just render the text as is.
  if (!term || term.length < 2) {
    return <span>{text}</span>
  }

  const escapedTerm = escapeRegExp(term)
  // Create a regex that captures the term (all occurrences, case-insensitive)
  const regex = new RegExp(`(${escapedTerm})`, 'gi')
  // Split the text; the matched parts will be included in the array.
  const parts = text.split(regex)

  // A helper regex that tests if a given part exactly matches the term
  const isMatch = (part: string) =>
    new RegExp(`^${escapedTerm}$`, 'i').test(part)

  return (
    <span>
      {parts.map((part, index) =>
        isMatch(part) ? (
          <span
            key={index}
            className="rounded-sm bg-mandarin/40 dark:bg-mandarin/20"
          >
            {part}
          </span>
        ) : (
          part
        )
      )}
    </span>
  )
}
