import { Fragment, PropsWithChildren } from 'react'
import { inputClass } from '../form/styles'

interface TestSearBarProps extends PropsWithChildren {
  searchTerm: string
  onSearchChange: (term: string) => void
}

export function TestSearchBar({
  onSearchChange,
  searchTerm,
  children,
}: TestSearBarProps) {
  return (
    <Fragment>
      <div>
        {/* Search Bar */}
        <div className="flex-grow">
          <input
            type="search"
            placeholder="Search for a test or biomarker"
            className={inputClass}
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </div>
      </div>
      {children}
    </Fragment>
  )
}
