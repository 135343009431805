import toast from 'react-hot-toast'
import { Button } from '../../../../form/button/Button'
import { Fragment, useState } from 'react'
import { approveResultForOrganisation } from '../../../../../lib/organisation'
import { Transition } from '@headlessui/react'

const baseTransitionClasses =
  'transition-all duration-500 overflow-hidden transform '

export function ApprovalBar({
  resultUuid,
  organisationUuid,
  name,
}: {
  resultUuid: string
  organisationUuid: string
  name?: string
}) {
  const [approved, setApproved] = useState(false)
  const [saving, setSaving] = useState(false)
  const onClick = async () => {
    const toastId = toast.loading('Approving result')
    try {
      setSaving(true)
      const response = await approveResultForOrganisation(
        resultUuid,
        organisationUuid
      )
      console.log(response)
      toast.success(
        `Result approved; we have notified ${name ? name : 'the customer'} via email`,
        {
          duration: 5000,
          id: toastId,
        }
      )
      setApproved(true)
    } catch (e) {
      setSaving(false)
      if (e instanceof Error) {
        toast.error(e.message, {
          duration: 5000,
          id: toastId,
        })
      } else {
        toast.error('Unable to approve result', {
          duration: 5000,
          id: toastId,
        })
      }
    }
  }
  return (
    <Fragment>
      <Transition
        show={!approved}
        as={Fragment}
        appear={true}
        enterFrom={`${baseTransitionClasses} scale-95 opacity-0 max-h-0`}
        enterTo={`${baseTransitionClasses} scale-100 opacity-100 max-h-screen`}
        leaveFrom={`${baseTransitionClasses} scale-100 opacity-100 max-h-screen`}
        leaveTo={`${baseTransitionClasses} scale-95 opacity-0 max-h-0`}
      >
        <div className="sticky top-16 rounded-lg bg-mandarin/20 backdrop-blur-md p-4 z-50 flex sm:flex-row flex-col items-center sm:mb-0 mb-4 text-sm text-center sm:text-left shadow-md">
          <div>
            Ready for Review{' '}
            <span className="block sm:inline-block ">
              <span className="hidden sm:inline-block"> - </span> Currently only
              you can see these results.
            </span>
          </div>
          <div className="flex-grow text-right mt-4 sm:mt-0">
            <Button color={'jade'} onClick={onClick} disabled={saving}>
              Approve & Release
            </Button>
          </div>
        </div>
      </Transition>
    </Fragment>
  )
}
