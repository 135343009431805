import { Fragment, useEffect, useState } from 'react'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { Modal } from '../../../../layout/Modal'
import { PackageWithTestsAndBiomarkers } from '../../../../../lib/validators'
import { BiomarkerPill } from '../../../../ui/BiomarkerPill'
import { formatMoney } from '../../../../../lib/utils'
import { TestTurnaroundTime } from '../../../../ui/TestTurnaroundTime'
import RoundedSection from '../../../../layout/RoundedSection'

interface PackageInfoModalProps {
  show: boolean
  product?: PackageWithTestsAndBiomarkers
  theme?: ThemeInterface
  setShow: (openState: boolean) => void
}

export function PackageInfoModal({
  show,
  setShow,
  theme,
  product,
}: PackageInfoModalProps) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(show)
  }, [show, product])

  return (
    <Modal
      show={open}
      options={{
        mainActions: [
          {
            label: 'Dismiss',
            handler: () => setShow(false),
          },
        ],
        title: product && product.name ? product.name : '',
        content: (
          <div>
            {product && (
              <Fragment>
                <div className="mb-4 mt-2 text-lg">{product.description}</div>
                {product?.tests?.map((t) => (
                  <RoundedSection
                    size="2xl"
                    className="mb-4"
                    key={`product-${t.id}`}
                    reducedPadding={true}
                  >
                    <b>
                      {t.name}{' '}
                      <TestTurnaroundTime
                        showMissingText={false}
                        hideHeading={true}
                        turnaroundTimes={t.turnAroundTimeAverage}
                      ></TestTurnaroundTime>
                    </b>
                    <div className="text-gray-dark text-sm">
                      {t.biomarkers &&
                        t.biomarkers.map((b) => (
                          <BiomarkerPill
                            key={`biomarker-${b.id}`}
                            biomarker={b}
                          ></BiomarkerPill>
                        ))}
                      {t.biomarkers && t.biomarkers.length === 0 && (
                        <div>
                          This test results in a report from the provider
                          handling the test
                        </div>
                      )}
                    </div>
                  </RoundedSection>
                ))}
                <div className="mt-2 font-bold">
                  Price:
                  {(product && formatMoney(product.price / 100, 'aud')) ||
                    'no selected test'}
                </div>
                <div className="mt-2 font-bold">
                  <TestTurnaroundTime
                    headingText="Estimated total package turnaround time"
                    turnaroundTimes={product.turnAroundTimeAverage}
                  ></TestTurnaroundTime>
                </div>
              </Fragment>
            )}
          </div>
        ),
      }}
      onClose={() => setShow(false)}
      theme={theme || undefined}
      size="2xl"
    ></Modal>
  )
}
