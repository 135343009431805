import { Fragment, useCallback, useEffect, useState } from 'react'
import '../../App.css'
import Clipboard from '../../../public/img/clipboard.svg'

import { useBranding, useUser } from '../../lib/store'
import { useParams } from 'react-router-dom'
import { BasicOrg, ResultReferral } from '../../lib/validators'

import PageSection from '../layout/PageSection'
import { resultUrlRegex } from '../../lib/routes'
import Page from '../Page'
import {
  getHistoricalResultsForBiomarkers,
  getResultsDataForProfileAndUuid,
  HistoricalBiomarkerResultMap,
  transformHistoricalResultData,
} from '../../lib/results'
import PageLoader from '../layout/PageLoader'
import { ResultBiomarkerViewer } from '../ui/ResultBiomarkerViewer'
import { ResultDocumentViewer } from '../ui/ResultDocumentViewer'
import { setDocumentTitle } from '../../lib/utils'
import { EmptyState } from '../layout/EmptyState'
import { getSizedImage, OrgImageTypes } from '../../lib/image'

export default function ResultPage() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [result, setResult] = useState<ResultReferral>()
  const [errorOrg, setErrorOrg] = useState<BasicOrg>()
  const [historicalData, setHistoricalData] = useState<
    HistoricalBiomarkerResultMap | undefined
  >()

  const [profile, setProfile] = useState('')
  const branding = useBranding()?.theme

  const user = useUser()
  const params = useParams()

  const getResultsData = useCallback(async (): Promise<void> => {
    // setTimeout(async () => {
    if (!user) {
      throw new Error('Missing user')
    }
    if (!params.uuid) {
      throw new Error('Missing url param')
    }

    try {
      const parsedData = await getResultsDataForProfileAndUuid({
        resultUuid: params.uuid,
        profileUuid: profile,
      })
      if ('error' in parsedData) {
        console.log(parsedData)
        setErrorOrg(parsedData.org || undefined)
        setError(parsedData.code)
      } else {
        setResult(parsedData)
        setDocumentTitle(
          `${parsedData.name} results [Ref #${parsedData.referral?.reference}]`
        )
      }
    } catch (e) {
      if (e instanceof Error) {
        console.log('Error retrieving results.', e.message)
        setError(e.message)
      }
    }
    setLoading(false)
  }, [params.uuid, profile, user])

  useEffect(() => {
    // set the profile to be the first one
    if (user && !profile) {
      setProfile(user.profileUuid)
      return
    }
    if (profile) {
      getResultsData()
    }

    // fetch the tests here
  }, [user, profile, getResultsData])

  const getHistoricalBiomarkerData = useCallback(
    async (biomarkers: string[]): Promise<void> => {
      // setTimeout(async () => {
      if (!user) {
        throw new Error('Missing user')
      }
      if (!params.uuid) {
        throw new Error('Missing url param')
      }

      const parsedData = await getHistoricalResultsForBiomarkers({
        profileUuid: profile,
        biomarkers: biomarkers,
      })
      const transformed = transformHistoricalResultData(parsedData)
      setHistoricalData(transformed)
    },
    [params.uuid, profile, user]
  )

  useEffect(() => {
    // set the profile to be the first one
    if (result && result.biomarkers) {
      const biomarkerIds = result.biomarkers.map((b) => b.id)
      getHistoricalBiomarkerData(biomarkerIds)
    }

    // fetch the tests here
  }, [result, profile, getHistoricalBiomarkerData])

  return (
    <Page>
      {!loading &&
        !error &&
        result &&
        result.referral?.referralType === 'blood' && (
          <PageSection>
            <ResultBiomarkerViewer
              profileUuid={profile}
              resultItem={result}
              historicalData={historicalData}
              urlRegex={resultUrlRegex}
              theme={branding || undefined}
            ></ResultBiomarkerViewer>
          </PageSection>
        )}
      {!loading &&
        !error &&
        result &&
        result.referral?.referralType !== 'blood' && (
          <PageSection>
            <ResultDocumentViewer
              profileUuid={profile}
              resultItem={result}
              urlRegex={resultUrlRegex}
              theme={branding || undefined}
            ></ResultDocumentViewer>
          </PageSection>
        )}

      {loading && !error && <PageLoader></PageLoader>}
      {!loading && error && (
        <Fragment>
          {error === 'RESULT_IN_REVIEW' ? (
            <EmptyState
              image={
                errorOrg && errorOrg.logo
                  ? getSizedImage(OrgImageTypes.Banner, errorOrg.logo)
                  : Clipboard
              }
              title="Results In Review"
              alt="Clipboard Image"
            >
              Looks like these results are currently in review{' '}
              {errorOrg ? (
                <span>
                  {' '}
                  with <span className="font-bold">{errorOrg.name}</span>
                </span>
              ) : (
                ''
              )}
              . Please check back later.
            </EmptyState>
          ) : (
            <EmptyState
              image={Clipboard}
              title="Results Not Found"
              alt="Clipboard Icon"
            >
              These results do not exist.
            </EmptyState>
          )}
        </Fragment>
      )}
    </Page>
  )
}
