import { StatusTagColors } from '../../lib/interfaces'
import { ExpectedTimes, getExpectedResultTime } from '../../lib/utils'
import { StatusTag } from '../layout/StatusTag'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

interface ExpectedResultsDateProps {
  identifier: string
  startDate: Date
  hideTitle?: boolean
  minimumTime?: number | null
  maximumTime?: number | null
  tooltipOverride?: string
  unknownText?: string
  className?: string
}

const getColorForStatusTag = (timeStatus: ExpectedTimes): StatusTagColors => {
  switch (timeStatus) {
    case ExpectedTimes.Overdue:
      return StatusTagColors.BLOOD
    case ExpectedTimes.WithinExpectedTimeframe:
      return StatusTagColors.GREEN
    case ExpectedTimes.Unknown:
    default:
      return StatusTagColors.GRAY
  }
}

export function ExpectedResultsDate({
  maximumTime,
  minimumTime,
  startDate,
  identifier,
  hideTitle = false,
  tooltipOverride,
  unknownText,
  className,
}: ExpectedResultsDateProps) {
  const { status, dayInfoText, infoText } = getExpectedResultTime(
    startDate,
    minimumTime,
    maximumTime
  )

  return (
    <StatusTag
      className={`${className ? className : ''}`}
      color={getColorForStatusTag(status)}
      label={
        <div data-tooltip-id={identifier}>
          {!hideTitle && (
            <div className="font-bold inline-block pr-2">
              Expected results date:
            </div>
          )}
          <span>
            {status === ExpectedTimes.Unknown && unknownText
              ? unknownText
              : infoText}
          </span>
          <Tooltip
            id={identifier}
            place="top"
            content={tooltipOverride || dayInfoText}
            className="rounded-lg"
            float={true}
          />
        </div>
      }
    ></StatusTag>
  )
}
