import { z } from 'zod'
import { NameEmailAndNote } from '../components/pages/organisation/org/modals/SendPackageModal'
import { authFetch } from '../providers/AuthProvider'
import { SaveProductForOrganisationInput, ThemeInterface } from './interfaces'
import {
  ActiveProduct,
  ApproveResultResponse,
  ApproveResultResponseSchema,
  BillingAndProductInfo,
  BillingAndProductInfoSchema,
  CalculatedPrice,
  CalculatedPriceSchema,
  Organisation,
  OrganisationListSchema,
  OrganisationProduct,
  OrganisationProductSchema,
  OrganisationTheme,
  OrganisationThemeSchema,
  OrganisationUpdate,
  OrganisationUpdated,
  OrganisationUpdatedSchema,
  PackageWithTestsAndBiomarkers,
  PackageWithTestsAndBiomarkersSchema,
  PackagesWithTestsAndBiomarkersSchema,
  PayoutHistoryResponse,
  PayoutHistoryResponseSchema,
  SendPackageResponse,
  SendPackageResponseSchema,
  TestPrice,
  TestPriceSchema,
  TestWithBiomarkers,
  TestsWithBiomarkersSchema,
} from './validators'
import {
  CreateStore,
  Product,
  ProductListSchema,
  Store,
  StoreListSchema,
  StoreSchema,
  UpdateStore,
} from './validators'

const baseTestQuery = `
tests {
  id
  name
  shortName
  price
  reviewFee
  standalone
  priceCategory
  testType
  providerId
  description
  turnAroundTimes {
    providerId
    providerName
    minimumTime
    maximumTime
  }
  turnAroundTimeAverage {
    minimumTime
    maximumTime
  }
  biomarkers {
    id
    name
    commonName
    description
    unit
    prefix
    suffix
    category {
      id
      name
      description
    }
  }
}
`

export const updateOrganisationTheme = async (
  organisationUuid: string,
  theme: ThemeInterface
): Promise<OrganisationTheme> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($organisationUuid: UUID!, $data:UpdateOrganisationThemeInput!) {
            updateOrganisationTheme(organisationUuid: $organisationUuid data: $data) {
              logo
              logoSquare
              primaryColor
              secondaryColor
              headerBarBackgroundColor
              buttonColor
              buttonHoverColor
              buttonTextColor
              linkColor
            }
          }`,
      variables: {
        organisationUuid: organisationUuid,
        data: theme,
      },
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
  }
  const parsedData = OrganisationThemeSchema.parse(
    data.data.updateOrganisationTheme
  )
  return parsedData
}

export const saveOrganisationTestPackage = async (
  organisationUuid: string,
  product: SaveProductForOrganisationInput,
  productId?: string
): Promise<OrganisationProduct> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($organisationUuid: UUID! $data:SaveProductForOrganisationInput! ${productId ? '$productId:String' : ''}) {
            saveProductForOrganisation(organisationUuid:$organisationUuid data:$data ${productId ? 'productId:$productId' : ''}) {
              id
            }
          }`,
      variables: {
        organisationUuid: organisationUuid,
        data: product,
        productId: productId,
      },
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
  }
  const parsedData = OrganisationProductSchema.parse(
    data.data.saveProductForOrganisation
  )
  return parsedData
}

export const sendPackageToUser = async (
  organisationUuid: string,
  productId: string,
  userInfo: NameEmailAndNote
): Promise<SendPackageResponse> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($organisationUuid: UUID!, $productId: String!, $data:[PackageRecipientInput!]!) {
            sendPackageToEmail(organisationUuid: $organisationUuid productId: $productId data: $data) {
              name
              email
              status
            }
          }`,
      variables: {
        organisationUuid: organisationUuid,
        productId: productId,
        data: [
          {
            name: userInfo.name,
            email: userInfo.email,
            note: userInfo.note,
          },
        ],
      },
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
  }
  const parsedData = SendPackageResponseSchema.parse(
    data.data.sendPackageToEmail
  )
  return parsedData
}

export const buyPackageForEmail = async (
  organisationUuid: string,
  productId: string,
  userInfo: NameEmailAndNote
): Promise<SendPackageResponse> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($organisationUuid: UUID!, $productId: String!, $data:[PackageRecipientInput!]!) {
            buyPackageForEmail(organisationUuid: $organisationUuid productId: $productId data: $data) {
              name
              email
              status
            }
          }`,
      variables: {
        organisationUuid: organisationUuid,
        productId: productId,
        data: [
          {
            name: userInfo.name,
            email: userInfo.email,
            note: userInfo.note,
          },
        ],
      },
    }),
  })
  const data = result.data
  if (
    data.errors &&
    data.errors.length > 0 &&
    data.errors[0].extensions &&
    data.errors[0].extensions.code
  ) {
    throw new Error(data.errors[0].extensions.code)
  } else if (data.errors && data.errors.length > 0) {
    throw new Error(data.errors[0].message)
  }
  const parsedData = SendPackageResponseSchema.parse(
    data.data.buyPackageForEmail
  )
  return parsedData
}

export const updateOrganisation = async (
  organisationUuid: string,
  payload: OrganisationUpdate
): Promise<OrganisationUpdated> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($organisationUuid: UUID!, $data:UpdateOrganisationInput!) {
            updateOrganisation(organisationUuid: $organisationUuid data: $data) {
              id
              name
              apiTermsAccepted
              orgTermsAccepted
            }
          }`,
      variables: {
        organisationUuid: organisationUuid,
        data: {
          resultsEmail: payload.resultsEmail || null,
          prettyResultsEmail: payload.prettyResultsEmail || null,
          billingFailedEmail: payload.billingFailedEmail || null,
          referralsEmail: payload.referralsEmail || null,
          collectionCentreUrl: payload.collectionCentreUrl || null,
          name: payload.name || null,
          orgTermsAccepted: payload.orgTermsAccepted || null,
          apiTermsAccepted: payload.apiTermsAccepted || null,
          abn: payload.abn || null,
          entityName: payload.entityName || null,
          enableSubdomain: payload.enableSubdomain || null,
          withholdResults: payload.withholdResults,
        } as OrganisationUpdate,
      },
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
  }

  const org = OrganisationUpdatedSchema.parse(data.data.updateOrganisation)
  return org
}

export const getProductsForOrganisation = async ({
  organisationUuid,
  archivedOnly = false,
}: {
  organisationUuid: string
  archivedOnly?: boolean
}): Promise<PackageWithTestsAndBiomarkers[]> => {
  // console.log("we have a profile", profile);
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          products(organisationUuid: "${organisationUuid}" onlyOrgProducts: true ${archivedOnly ? 'archivedOnly: true' : ''}) {
            id
            name
            description
            internal
            createdAt
            archivedAt
            price
            markupAmount
            turnAroundTimeAverage {
              minimumTime
              maximumTime
            }
            storeLinks {
              storeUuid
              storeProvider
              storeName
            }
            ${baseTestQuery}
          }
        }`,
    }),
  })
  const data = result.data
  console.log('got the data back/', data)
  const parsedPackages = PackagesWithTestsAndBiomarkersSchema.parse(
    data.data.products
  )

  return parsedPackages
}

export const getBasicProductsForOrganisation = async (
  organisationUuid: string
): Promise<PackageWithTestsAndBiomarkers[]> => {
  // console.log("we have a profile", profile);
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          products(organisationUuid: "${organisationUuid}" onlyOrgProducts: true) {
            id
            name
            description
            internal
            createdAt
            archivedAt
            price
            markupAmount
            storeLinks {
              storeUuid
              storeProvider
              storeName
            }
          }
        }`,
    }),
  })
  const data = result.data
  const parsedPackages = PackagesWithTestsAndBiomarkersSchema.parse(
    data.data.products
  )

  return parsedPackages
}

export const getProductForOrganisation = async (
  organisationUuid: string,
  productId: string
): Promise<PackageWithTestsAndBiomarkers> => {
  // console.log("we have a profile", profile);
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          product(organisationUuid: "${organisationUuid}" productId: "${productId}") {
            id
            name
            description
            internal
            createdAt
            archivedAt
            storeLinks {
              storeUuid
              storeProvider
              storeName
            }
            price
            markupAmount
            ${baseTestQuery}
          }
        }`,
    }),
  })
  const data = result.data
  console.log('got the data back', data)
  const parsedPackages = PackageWithTestsAndBiomarkersSchema.parse(
    data.data.product
  )

  return parsedPackages
}
export const getTestsAndProducts = async (
  organisationUuid: string
): Promise<[TestWithBiomarkers[], PackageWithTestsAndBiomarkers[]]> => {
  // console.log("we have a profile", profile);
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          ${baseTestQuery}
          products(organisationUuid: "${organisationUuid}" templatesOnly:true) {
            id
            name
            description
            internal
            createdAt
            archivedAt
            price
            markupAmount
            turnAroundTimeAverage {
              minimumTime
              maximumTime
            }
            storeLinks {
              storeUuid
              storeProvider
              storeName
            }
            ${baseTestQuery}
          }
        }`,
    }),
  })
  const data = result.data
  // console.log('The data ===> ', data.data.tests)

  const parsedData = TestsWithBiomarkersSchema.parse(data.data.tests)
  const parsedPackages = PackagesWithTestsAndBiomarkersSchema.parse(
    data.data.products
  )

  return [parsedData, parsedPackages]
}

export const getTests = async (): Promise<TestWithBiomarkers[]> => {
  // console.log("we have a profile", profile);
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          ${baseTestQuery}
        }`,
    }),
  })
  const data = result.data
  const parsedData = TestsWithBiomarkersSchema.parse(data.data.tests)
  return parsedData
}

interface CalculatePrice {
  tests: string[]
  markup?: number
  abortController?: AbortController
  productId?: string
}

export const calculatePrice = async ({
  tests,
  markup,
  abortController,
  productId,
}: CalculatePrice): Promise<CalculatedPrice | null> => {
  console.log('lets get the test price', tests)
  if (tests.length === 0) {
    return null
  }
  const markupQuery1 = markup !== undefined ? ' $markup: Int' : ''
  const markupQuery2 = markup !== undefined ? ' markup: $markup' : ''
  const productIdQuery1 = productId ? ' $productId: String' : ''
  const productIdQuery2 = productId ? ' productId: $productId' : ''

  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    signal: abortController ? abortController.signal : undefined,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($tests: [String!]! ${markupQuery1} ${productIdQuery1}) {
            calculatePrice(tests: $tests ${markupQuery2} ${productIdQuery2}) {
              testsTotal
              subTotal
              grandTotal
              grandTotalIncTax
              markup
              discounts {
                amount
                reason
              }
              fees {
                amount
                reason
              }
              tests {
                name
                price
              }
              tax {
                amount
                reason
              }
            }
          }`,
      variables: {
        tests,
        markup: markup !== undefined ? Math.round(markup * 100) : undefined,
        productId: productId,
      },
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
  }
  console.log('we got the pricing data back', data.data.calculatePrice)
  const parsedData = CalculatedPriceSchema.parse(data.data.calculatePrice)
  return parsedData
}

interface CreateOrgBillingProps {
  organisationUuid: string
}

export const generateApiCredentialsForOrganisation = async (
  props: CreateOrgBillingProps
): Promise<boolean> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($data:LinkOrganisationToAuthentationCredsInput!) {
            linkOrganisationToAuthCreds(data: $data)
          }
        `,
      variables: {
        data: {
          organisationUuid: props.organisationUuid,
        },
      },
    }),
  })
  console.log('the data is', result.data.data)
  return result.data.data.linkOrganisationToAuthCreds
}

// async function getBillingAndProductInfoForOrganisation() {}
export const getBillingAndProductInfoForOrganisation = async (
  organisationUuid: string,
  productId: string
): Promise<BillingAndProductInfo> => {
  // console.log("we have a profile", profile);
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          getBillingAndProductInfo(organisationUuid: "${organisationUuid}" productId: "${productId}") {
            price
            hasDefaultPaymentMethod
          }
        }`,
    }),
  })
  const data = result.data
  console.log('got the data', data)
  if (
    data.errors &&
    data.errors.length > 0 &&
    data.errors[0].extensions &&
    data.errors[0].extensions.code
  ) {
    throw new Error(data.errors[0].extensions.code)
  }
  const parsedData = BillingAndProductInfoSchema.parse(
    data.data.getBillingAndProductInfo
  )
  return parsedData
}

export const getStoresForOrganisationUuid = async (
  organisationUuid: string
): Promise<Store[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          getStoresForOrganisation(organisationUuid:"${organisationUuid}") {
            uuid
            id
            name
            organisationUuid
            storeProvider
            storeSigningSecret
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = StoreListSchema.parse(data.data.getStoresForOrganisation)
  return parsedData
}

export const getStoreForOrganisationUuid = async (
  organisationUuid: string,
  storeUuid: string
): Promise<Store> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          getStoreForOrganisation(organisationUuid:"${organisationUuid}" storeUuid:"${storeUuid}") {
            uuid
            id
            name
            organisationUuid
            storeProvider
            storeSigningSecret
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = StoreSchema.parse(data.data.getStoreForOrganisation)
  return parsedData
}

export const createStore = async (
  props: CreateStore
): Promise<Store | undefined> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($data:CreateStoreInput!) {
            createStore(data: $data) {
              uuid
              id
              name
              organisationUuid
              storeProvider
              storeSigningSecret
            }
          }
        `,
      variables: {
        data: {
          name: props.name,
          id: props.id,
          storeProvider: props.storeProvider,
          storeSigningSecret: props.storeSigningSecret,
          organisationUuid: props.organisationUuid,
        } as CreateStore,
      },
    }),
  })
  if (result.data.errors) {
    throw new Error(result.data.errors[0].message)
  }

  const store = StoreSchema.safeParse(result.data.data.createStore)
  if (store.success) {
    return store.data
  }
  throw new Error('Server returned unexpected response.')
}

export const updateStore = async (
  props: UpdateStore
): Promise<Store | undefined> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($data:UpdateStoreInput!) {
            updateStore(data: $data) {
              uuid
              id
              name
              organisationUuid
              storeProvider
              storeSigningSecret
            }
          }
        `,
      variables: {
        data: {
          storeSigningSecret: props.storeSigningSecret,
          organisationUuid: props.organisationUuid,
          uuid: props.uuid,
        } as UpdateStore,
      },
    }),
  })
  if (result.data.errors) {
    throw new Error(result.data.errors[0].message)
  }

  const store = StoreSchema.safeParse(result.data.data.updateStore)
  if (store.success) {
    return store.data
  }
  throw new Error('Server returned unexpected response.')
}

export const getProductsForStore = async (
  organisationUuid: string,
  storeUuid: string
): Promise<Product[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          getProductsForStore(organisationUuid:"${organisationUuid}" storeUuid:"${storeUuid}") {
            id
            name
            description
            createdAt
            archivedAt
            externalIdentifier
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = ProductListSchema.parse(data.data.getProductsForStore)
  return parsedData
}

export const removeProductsFromStore = async (
  organisationUuid: string,
  storeUuid: string,
  productIds: string[]
): Promise<void> => {
  // setTimeout(async () => {
  await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($organisationUuid: UUID!, $storeUuid:UUID! $productIds:[String!]!) {
            removeProductsFromStore(organisationUuid: $organisationUuid storeUuid: $storeUuid productIds: $productIds)
          }
        `,
      variables: {
        organisationUuid,
        storeUuid,
        productIds: productIds,
      },
    }),
  })
  return
}

export const addProductsToStore = async (
  organisationUuid: string,
  storeUuid: string,
  productIds: ActiveProduct[]
): Promise<void> => {
  // setTimeout(async () => {
  await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($organisationUuid: UUID!, $storeUuid:UUID! $productIds:[ProductWithExternalIdInput!]!) {
            addProductsToStore(organisationUuid: $organisationUuid storeUuid: $storeUuid productIds: $productIds)
          }
        `,
      variables: {
        organisationUuid,
        storeUuid,
        productIds: productIds,
      },
    }),
  })
  return
}

export const getPayoutHistory = async (
  organisationUuid: string,
  count: number,
  cursor?: string | null
): Promise<PayoutHistoryResponse> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          getPayoutsForOrg(organisationUuid:"${organisationUuid}" count: ${count} ${
            cursor !== undefined ? `cursor: "${cursor || ''}"` : ''
          }) {
            payouts {
              uuid
              organisationUuid
              purchasedBy {
                uuid
                firstName
                lastName
              }
              productId
              productName
              markupAmount
              payoutAmount
              feeAmount
              feePercentage
              status
              paidOutAt
              estimatedPaidOutAt
              createdAt
              updatedAt
            }
            cursor {
              nextCursor
              requested
              results
              hasMoreResults
            }
            unpaidTotal
            estimatedPaidOutAt
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = PayoutHistoryResponseSchema.parse(
    data.data.getPayoutsForOrg
  )
  return parsedData
}

export const getOrganisations = async (
  orgUuid?: string
): Promise<Organisation[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
        adminGetOrganisations ${orgUuid ? `(organisationUuid: "${orgUuid}")` : ''}{
          uuid
          id
          name
          type
          resultsEmail
          prettyResultsEmail
          billingFailedEmail
          referralsEmail
          collectionCentreUrl
          createdAt
          updatedAt
          apiTermsAccepted
          orgTermsAccepted
          abn
          entityName
          theme {
            logo
            logoOriginal {
              url
              crop {
                x
                y
                height
                width
                unit
              }
            }
            logoSquare
            logoSquareOriginal {
              url
              crop {
                x
                y
                height
                width
                unit
              }
            }
            primaryColor
            secondaryColor
            headerBarBackgroundColor
            buttonColor
            buttonHoverColor
            buttonTextColor
            linkColor
          }
        }
      }`,
    }),
  })
  const data = result.data
  const parsedData = OrganisationListSchema.parse(
    data.data.adminGetOrganisations
  )
  return parsedData
}

export const markUnpaidPayoutsAsPaid = async (
  organisationUuid: string
): Promise<void> => {
  // setTimeout(async () => {
  await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($organisationUuid: UUID!) {
            adminMarkPayoutsAsPaid(organisationUuid: $organisationUuid) {
              rows
              totalAmount
            }
          }
        `,
      variables: {
        organisationUuid,
      },
    }),
  })
  return
}

export const getPricesForTests = async (
  tests: string[]
): Promise<TestPrice[]> => {
  // console.log("we have a profile", profile);
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        query name($tests: [String!]!) {
          testPrices(tests: $tests) {
            id
            price
            eligibleForDiscount
          }
      }`,
      variables: {
        tests: tests,
      },
    }),
  })
  const data = result.data

  const parsedPrices = z.array(TestPriceSchema).parse(data.data.testPrices)

  return parsedPrices
}

export const approveResultForOrganisation = async (
  resultUuid: string,
  organisationUuid: string
): Promise<ApproveResultResponse> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($data: ApproveResultInput!) {
            approveResult(data:$data) {
              approved
            }
          }`,
      variables: {
        data: {
          organisationUuid: organisationUuid,
          resultUuid: resultUuid,
        },
      },
    }),
  })
  const data = result.data
  if (data.errors) {
    throw new Error(data.errors.pop()?.message)
  }
  const parsedData = ApproveResultResponseSchema.parse(data.data.approveResult)
  return parsedData
}
