import { EmptyState } from '../layout/EmptyState'
import Clipboard from '../../../public/img/clipboard.svg'
import { Button } from '../form/button/Button'

export function PageError() {
  return (
    <EmptyState
      image={Clipboard}
      title="Blimey! Looks like we hit a major clot in the system."
      alt="Clipboard Image"
    >
      Something is amiss, please try to reload the page.
      <br />
      If this fails to work,{' '}
      <span
        className="text-blood hover:underline cursor-pointer"
        onClick={() => window.Intercom('showNewMessage')}
      >
        please let us know
      </span>{' '}
      and we'll get right on it.
      <div className="mt-8">
        <Button onClick={() => window.location.reload()} color="blood">
          Click here to force-reload the page
        </Button>
      </div>
    </EmptyState>
  )
}
