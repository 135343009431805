import { PropsWithChildren } from 'react'

export const roundedSectionSize = [
  '3xl',
  '2xl',
  'xl',
  'lg',
  'md',
  'sm',
] as const
export type RoundedSectionSize = (typeof roundedSectionSize)[number]

interface RoundedSection extends PropsWithChildren {
  size: RoundedSectionSize
  reducedPadding?: boolean
  className?: string
  id?: string
}

export default function RoundedSection({
  children,
  size,
  className,
  id,
  reducedPadding,
}: RoundedSection) {
  let sizeClassName = 'rounded-xl'
  if (size === '3xl') {
    sizeClassName = 'rounded-3xl'
  } else if (size === '2xl') {
    sizeClassName = 'rounded-2xl'
  } else if (size === 'xl') {
    sizeClassName = 'rounded-xl'
  } else if (size === 'lg') {
    sizeClassName = 'rounded-lg'
  } else if (size === 'md') {
    sizeClassName = 'rounded-md'
  } else if (size === 'sm') {
    sizeClassName = 'rounded-sm'
  }
  let paddingSize = 'px-4 py-5'
  if (reducedPadding) {
    paddingSize = 'px-3 py-3'
  }
  return (
    <div
      id={id}
      className={`bg-white print:border-none print:shadow-none print:p-0 ${paddingSize} shadow sm:p-6 border border-gray-light ${sizeClassName} ${className} dark:!bg-dark-gray-lighter dark:border-0 dark:shadow-xl dark:text-white`}
    >
      {children}
    </div>
  )
}
