import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import ReactGA from 'react-ga4'
import { datadogLogs } from '@datadog/browser-logs'

if (
  import.meta.env.VITE_ENV === 'production' ||
  import.meta.env.VITE_ENV === 'staging'
) {
  datadogLogs.init({
    clientToken: 'pub1353851bb8d9719bc964accefdb4db11',
    site: 'ap1.datadoghq.com',
    service: 'bgt-react-web-app',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error'],
    env: import.meta.env.VITE_ENV,
    sessionSampleRate: 100,
    version: import.meta.env.VITE_RELEASE,
  })
  datadogLogs.onReady(() => {
    datadogLogs.setGlobalContext({
      release: import.meta.env.GIT_SHA,
      env: import.meta.env.VITE_ENV,
    })
  })
}

if (import.meta.env.VITE_GA_CODE) {
  ReactGA.initialize(import.meta.env.VITE_GA_CODE)
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
