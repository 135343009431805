import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'

import AppWrapper from './components/AppWrapper'
import { OrganisationProvider } from './components/context/OrganisationContext'

import { authExcludedRoutes, routes } from './lib/routes'
import { NotFound } from './components/pages/NotFound'

const INTERCOM_APP_ID =
  import.meta.env.VITE_ENV === 'production' ? 'ndrkocl7' : 'nbj0b05u'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useOnceCall } from './lib/hooks'

const posthogApiKey =
  import.meta.env.VITE_ENV === 'production'
    ? 'phc_e3J0egpSSF7ZWWnWjK5kxCL47kHV1cfzK5oolPXSSIS'
    : 'phc_5cPkCayYMCdeIiFsr472rteBSKw7x9GOMTqY6PkV7J2'

export default function App() {
  useOnceCall(() => {
    console.log('init posthog')
    posthog.init(posthogApiKey, {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
      session_recording: {
        maskAllInputs: true,
        maskTextSelector: '*',
      },
      enable_heatmaps: true,
      advanced_disable_feature_flags_on_first_load: true,
    })
  })

  return (
    <BrowserRouter>
      <PostHogProvider client={posthog}>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
          <OrganisationProvider>
            <AppWrapper excludedAuthUrls={authExcludedRoutes}>
              <Routes>
                {Object.entries(routes).map(([key, value]) => (
                  <Route path={key} key={key} element={<value.handler />} />
                ))}
                <Route path="*" key="not-found" element={<NotFound />} />
              </Routes>
            </AppWrapper>
          </OrganisationProvider>
        </IntercomProvider>
      </PostHogProvider>
    </BrowserRouter>
  )
}
