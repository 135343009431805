import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
// import { Link } from 'react-router-dom'
import { useOnceCall } from '../../lib/hooks'
import { ThreeDots } from 'react-loading-icons'
import { FaCircleCheck } from 'react-icons/fa6'
import BgtBloodDrop from '../../../public/img/bgt.svg?url'
import PasswordStength, { OnChangeProps } from '../form/PasswordStrength'
import TextInputField, { onChangeInterface } from '../form/TextInputField'
import SimpleSelectField from '../form/SimpleSelectField'
import Checkbox from '../form/Checkbox'
import FullPageLoader from '../layout/FullPageLoader'
import RoundedSection from '../layout/RoundedSection'

import {
  actions,
  useIsProfileComplete,
  useMissingProfileData,
  useUser,
} from '../../lib/store'
import { authFetch, unauthedFetch } from '../../providers/AuthProvider'
import { ProfileAddress, User } from '../../lib/validators'
import AuthStore from '../../lib/AuthStore'
import { currentDate, dateInThePast } from '../../lib/utils'
import { Errors } from '../../lib/verifyErrors'
import PhoneNumberInput from '../form/PhoneNumberInput'

interface FormElements extends HTMLFormControlsCollection {
  firstName: HTMLInputElement
  lastName: HTMLInputElement
  gender: HTMLInputElement
  dob: HTMLInputElement
  terms: HTMLInputElement
  edm: HTMLInputElement
  password: HTMLInputElement
  confirmPassword: HTMLInputElement
}
interface VerifyFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

interface VerificationPayload {
  firstName?: string
  lastName?: string
  dob?: string
  gender?: 'male' | 'female'
  terms?: boolean
  password?: string
  confirmPassword?: string
  passwordValid?: boolean
  edm?: boolean
  phone?: string
  address?: ProfileAddress
  isPractitioner?: string
}

export default function VerifyPage() {
  const navigate = useNavigate()

  const [needsToSetPassword, setNeedsToSetPassword] = useState(false)
  const [isFormReady, setFormReady] = useState(false)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [isNewProfile, setIsNewProfile] = useState(false)
  const [usersName, setUsersName] = useState('')
  const [emailVerified, setEmailVerified] = useState(false)
  const [usersEmail, setUsersEmail] = useState('')
  const [hasAcceptedLatestTerms, setHasAcceptedLatestTerms] = useState(false)
  const [formErrorState, setFormErrorState] = useState('')
  const [verified, setVerified] = useState(false)
  const [formData, setFormData] = useState<VerificationPayload>({})
  const [urlHash, setUrlHash] = useState('')
  const initComplete = useRef(false)
  const [requiredFields, setRequiredFields] = useState<Record<string, boolean>>(
    {}
  )
  const user = useUser()
  const missingProfileData = useMissingProfileData()
  const isProfileComplete = useIsProfileComplete()

  // const showPractitionerField = useFeatureFlagEnabled(
  //   'can-select-practitioner-status'
  // )
  // just turn this on for everyone now
  const showPractitionerField = true

  // this page is just a placeholder so we have a route for /callback,
  // all of the logic is actually happening in the AppWrapper
  useEffect(() => {
    // just start a timer because dont know if we can load this page if there is no hash or logged in user
    const to = setTimeout(() => {
      if (!initComplete.current) {
        AuthStore.sendUserToLoginPage(true)
        // window.location.href = "/";
      }
    }, 5000)
    return () => clearInterval(to)
  }, [])

  useOnceCall(async () => {
    const url = new URL(window.location.href)
    const hash = url.searchParams.get('hash')
    if (hash) {
      setUrlHash(hash)
      const response = await fetch(`${import.meta.env.VITE_API_URL}/verify`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ hash }),
      })
      const payload = await response.json()
      if (payload.error === false) {
        // lets check if we have enough data to bypass the step entirely.
        // if we enter this block it means there is enough data for the user.
        if (
          payload.data.missingProfileData.length === 0 &&
          payload.data.userSetPassword === true &&
          payload.data.acceptedLatestTerms
        ) {
          try {
            const [success, user] = await submitForm(hash)
            if (success) {
              actions.setEmailVerified(
                (user?.emailVerified as boolean) || false
              )
              setVerified(true)
              setLoading(false)
              return
            }
          } catch (e) {
            setFormErrorState(Errors.VerificationFailed)
            setInitialState(
              payload.data,
              payload.data.missingProfileData,
              payload.data.profileInitComplete
            )
          }
        } else {
          setInitialState(
            payload.data,
            payload.data.missingProfileData,
            payload.data.profileInitComplete
          )
        }
      } else {
        navigate('/')
        return
      }
    } else {
      console.log('we dont have a hash, lets try to get our profile.')
    }
  })

  // we are here if we are logged in, and dont have a url hash (this is if we have pushed the user
  // here after logging in) ,.
  useEffect(() => {
    if (
      user &&
      !urlHash &&
      missingProfileData !== null &&
      isProfileComplete !== null
    ) {
      if (
        missingProfileData.length === 0 &&
        user.userSetPassword === true &&
        user.acceptedLatestTerms
      ) {
        navigate('/')
      } else {
        setInitialState(user, missingProfileData, isProfileComplete)
      }
    }
  }, [user, urlHash, missingProfileData, isProfileComplete, navigate])

  const setInitialState = (
    user: User,
    missingProfileData: readonly string[] | string[],
    profileInitComplete: boolean
  ) => {
    initComplete.current = true
    setIsNewProfile(!profileInitComplete)
    setUsersName(user.firstName)
    setUsersEmail(user.email)
    setNeedsToSetPassword(user.userSetPassword === false)
    setHasAcceptedLatestTerms(user.acceptedLatestTerms)
    setEmailVerified(user.emailVerified)
    setFormData((prev: VerificationPayload) => ({
      ...prev,
      hasAcceptedLatestTerms: user.acceptedLatestTerms,
      firstName: user.firstName,
      lastName: user.lastName,
    }))

    missingProfileData.map((item) => {
      setRequiredFields((prev) => ({
        ...prev,
        [item]: true,
      }))
    })
    setLoading(false)
  }

  // these useEffects are used to determine which fields to hide/show
  useEffect(() => {
    if (needsToSetPassword === true) {
      setRequiredFields((prev) => ({
        ...prev,
        password: true,
        confirmPassword: true,
      }))
    }
  }, [needsToSetPassword])

  useEffect(() => {
    setRequiredFields((prev) => ({
      ...prev,
      terms: !hasAcceptedLatestTerms,
    }))
  }, [hasAcceptedLatestTerms])

  useEffect(() => {
    if (
      !loading &&
      emailVerified &&
      requiredFields !== null &&
      Object.values(requiredFields).length >= 0 &&
      !Object.values(requiredFields).includes(true)
    ) {
      console.log(
        'looks like we dont have any required fields, lets redirect the user to the homepage.'
      )
      navigate('/')
    }
  }, [requiredFields, emailVerified, loading, navigate])

  const submitForm = async (
    hash?: string
  ): Promise<[boolean, Record<string, string | boolean | null> | null]> => {
    setFormErrorState('')
    if (
      requiredFields.gender &&
      formData.gender &&
      ['male', 'female'].indexOf(formData.gender) === -1
    ) {
      // its an error here
      setFormErrorState(Errors.InvalidGender)
      return [false, null]
    } else if (
      requiredFields.dob &&
      formData.dob &&
      !isSelectedDateValid(formData.dob)
    ) {
      // its an error here
      setFormErrorState(Errors.InvalidDoB)
      return [false, null]
    }
    // create a new user
    setSaving(true)

    const verifyPayload: Record<string, string> = {
      hash: hash || urlHash || '',
    }

    // this is clunky AF, but its fine for now, in future we should loop over requiredFields and puck the vals from the form
    if (requiredFields.firstName && formData.firstName) {
      verifyPayload.firstName = formData.firstName
    }
    if (formData.lastName) {
      verifyPayload.lastName = formData.lastName
    }
    if (requiredFields.dob && formData.dob) {
      verifyPayload.dob = formData.dob
    }
    if (requiredFields.gender && formData.gender) {
      verifyPayload.gender = formData.gender
    }
    if (requiredFields.edm && formData.edm) {
      verifyPayload.edm = formData.edm.toString()
    }
    if (requiredFields.phone && formData.phone) {
      verifyPayload.phone = formData.phone.replace(/ /gi, '')
    }

    if (
      requiredFields.password &&
      requiredFields.confirmPassword &&
      formData.password &&
      formData.confirmPassword
    ) {
      verifyPayload.password = formData.password
      verifyPayload.confirmPassword = formData.confirmPassword
    }
    if (requiredFields.terms && formData.terms) {
      verifyPayload.terms = formData.terms?.toString()
    }

    if (requiredFields.address && formData.address) {
      verifyPayload.address = JSON.stringify(formData.address)
    }

    verifyPayload.isPractitioner = formData.isPractitioner || 'no'

    const fetchVersion = hash || urlHash ? unauthedFetch : authFetch

    const response = await fetchVersion(
      `${import.meta.env.VITE_API_URL}/create/verify`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
          accept: 'application/json',
        },
        data: new URLSearchParams(verifyPayload),
      }
    )
    setSaving(false)
    const payload = response.data
    if (response.status === 200 && !payload.error) {
      setFormErrorState('')
      return [true, payload.data]
    } else if (payload.error && payload.code === 'USER_VERIFY_EMAIL_FAILED') {
      throw new Error('Unable to verify account, please contact support')
    }
    throw new Error('Unable to verify account, please contact support')
  }

  const handleSubmit = async (event: React.FormEvent<VerifyFormElement>) => {
    event.preventDefault()

    try {
      const [suceeded] = await submitForm()
      if (suceeded) {
        if (!urlHash) {
          toast.success(
            isNewProfile ? 'Profile created.' : 'Profile updated.',
            {
              duration: 3000,
              icon: '✅',
            }
          )
          // await refreshCurrentUser();
          if (formData.isPractitioner && formData.isPractitioner !== 'no') {
            window.location.href = '/organisations'
          } else {
            window.location.href = '/'
          }
        } else {
          setVerified(true)
        }
      }
    } catch (e) {
      setFormErrorState(Errors.VerificationFailed)
    }
  }

  const isSelectedDateValid = (selectedDate: string): boolean => {
    const selectedDateObject = new Date(selectedDate)
    const cutoffDateObject = new Date(currentDate())

    return selectedDateObject.getTime() < cutoffDateObject.getTime()
  }

  const handleElementChange = (e: onChangeInterface) => {
    setFormData((prev: VerificationPayload) => ({
      ...prev,
      [e.name]: e.value,
    }))
  }

  // const handlePhoneElementChange = (e: React.FormEvent<HTMLInputElement>) => {
  //   const element = e.target as HTMLInputElement;
  //   const val = element.value.replace(/ /gi, "");
  //   if (val.startsWith("04")) {
  //     if (val.length >= 8) {
  //       element.value = `${val.substring(0, 4)} ${val.substring(
  //         4,
  //         7
  //       )} ${val.substring(7, 20)}`;
  //     } else if (val.length > 4) {
  //       element.value = `${val.substring(0, 4)} ${val.substring(4, 20)}`;
  //     } else {
  //       element.value = `${val.substring(0, 4)}`;
  //     }
  //   }
  //   handleElementChange(e);
  // };

  const handlePasswordChange = (props: OnChangeProps) => {
    setFormData((prev: VerificationPayload) => ({
      ...prev,
      [props.name]: props.value,
      passwordValid: props.isValid,
    }))
  }

  const isPasswordValid = (): boolean => {
    if (needsToSetPassword) {
      return (
        formData.passwordValid === true &&
        formData.password !== undefined &&
        formData.confirmPassword !== undefined &&
        formData.password !== '' &&
        formData.confirmPassword !== '' &&
        formData.password === formData.confirmPassword
      )
    }
    return true
  }

  const updatePasswordStatusMessage = (): boolean => {
    if (needsToSetPassword) {
      if (
        formData.password !== formData.confirmPassword &&
        formData.password !== '' &&
        formData.confirmPassword !== '' &&
        formData.password !== undefined &&
        formData.confirmPassword !== undefined
      ) {
        // show banner if passwords do not match
        setFormErrorState(Errors.PasswordMismatch)
      } else {
        setFormErrorState('')
      }
    } else {
      return true
    }
    return false
  }

  const termsValid = (): boolean => {
    if (requiredFields.terms === true && formData.terms === true) {
      return true
    }
    if (requiredFields.terms === false) {
      return true
    }
    if (requiredFields.terms === undefined) {
      return true
    }
    return false
  }
  const dobValid = (): boolean => {
    if (requiredFields.dob === true && formData.dob) {
      return true
    }
    if (requiredFields.dob === undefined) {
      return true
    }
    return false
  }
  const genderValid = (): boolean => {
    if (requiredFields.gender === true && formData.gender) {
      return true
    }
    if (requiredFields.gender === undefined) {
      return true
    }
    return false
  }
  const phoneNumberValid = (): boolean => {
    if (requiredFields.phone === true && formData.phone) {
      return true
    }
    if (requiredFields.phone === undefined) {
      return true
    }
    return false
  }
  const addressValid = (): boolean => {
    if (requiredFields.address === true && formData.address) {
      return true
    }
    if (requiredFields.address === undefined) {
      return true
    }
    return false
  }

  useEffect(() => {
    let isValid = false
    updatePasswordStatusMessage()

    if (
      termsValid() &&
      dobValid() &&
      genderValid() &&
      isPasswordValid() &&
      phoneNumberValid() &&
      addressValid()
    ) {
      isValid = true
    }

    setFormReady(isValid)
  }, [formData, isPasswordValid, termsValid, dobValid, genderValid])
  // we should do a call to the api to get some info about our verify hash.

  const getAddress = useCallback((): ProfileAddress | undefined => {
    if (formData.address) {
      return formData.address
    }
    return
  }, [formData.address])

  const getPrettyAddress = useCallback(
    (add?: ProfileAddress): string => {
      const address = add ? add : getAddress()
      if (!address) {
        return ''
      }
      const line2 = address.addressLine2 || ''
      return `${address.addressLine1} ${line2}, ${address.city} ${
        address.state
      } ${address.postCode || ''}`
    },
    [getAddress]
  )

  return (
    <Fragment>
      {loading && <FullPageLoader></FullPageLoader>}
      {!loading && (
        <div className="min-h-full flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div className="flex justify-center">
              <img alt="" className="rounded-md" src={BgtBloodDrop} />
            </div>
            {!verified && (
              <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                G'day {usersName.split(' ')[0]}, let's{' '}
                {isNewProfile ? 'create' : 'complete'} your profile
              </h2>
            )}
            <RoundedSection size="lg">
              <div>
                {verified && (
                  <div className="p-10 text-center flex flex-col">
                    <div className="justify-center mr-auto ml-auto pb-5">
                      <FaCircleCheck size={70} color="#00edc0" />
                    </div>
                    <h2 className="text-2xl font-bold">Profile updated</h2>
                    <div>
                      <a href="/">Show me my bloods!</a>
                    </div>
                  </div>
                )}
                {!verified && (
                  <form
                    className="space-y-6"
                    method="POST"
                    onSubmit={handleSubmit}
                  >
                    {formErrorState && (
                      <div
                        className="bg-bgt-primary/10 border-t-4 border-bgt-primary rounded-b text-teal-900 px-4 py-3 shadow-md"
                        role="alert"
                      >
                        <div className="flex">
                          <div className="py-1">
                            <svg
                              className="fill-current h-6 w-6 text-bgt-primary mr-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                          </div>
                          <div>
                            <p className="font-bold">Error</p>
                            {formErrorState === Errors.UnknownError && (
                              <p className="text-sm">
                                An unknown error occured.
                              </p>
                            )}
                            {formErrorState === Errors.ServerError && (
                              <p className="text-sm">
                                Server returned an error, please try again later
                              </p>
                            )}
                            {formErrorState === Errors.InvalidGender && (
                              <p className="text-sm">Invalid gender selected</p>
                            )}
                            {formErrorState === Errors.InvalidDoB && (
                              <p className="text-sm">Invalid date of birth</p>
                            )}
                            {formErrorState === Errors.PasswordMismatch && (
                              <p className="text-sm">Passwords do not match</p>
                            )}
                            {formErrorState === Errors.VerificationFailed && (
                              <p className="text-sm">
                                Unable to verify account, perhaps you have
                                already verified.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email
                      </label>
                      <span className="text-gray-dark">{usersEmail}</span>
                    </div>
                    <div>
                      <TextInputField
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        type="text"
                        value={formData.firstName}
                        required={true}
                        disabled={saving || loading}
                        handleChange={handleElementChange}
                      ></TextInputField>
                    </div>
                    <div>
                      <TextInputField
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        value={formData.lastName}
                        type="text"
                        disabled={saving || loading}
                        handleChange={handleElementChange}
                      ></TextInputField>
                    </div>
                    {requiredFields.password &&
                      requiredFields.confirmPassword && (
                        <Fragment>
                          <div>
                            <PasswordStength
                              id="password"
                              name="password"
                              label="Password"
                              onChange={handlePasswordChange}
                              segments={5}
                              disabled={saving || loading}
                              segmentColours={[
                                'bg-bgt-primary',
                                'bg-bgt-primary/80',
                                'bg-bgt-primary/60',
                                'bg-jade/60',
                                'bg-jade',
                              ]}
                            ></PasswordStength>
                          </div>
                          <div>
                            <PasswordStength
                              id="confirmpassword"
                              name="confirmPassword"
                              label="Confirm Password"
                              onChange={handlePasswordChange}
                              segments={5}
                              disabled={saving || loading}
                              segmentColours={[
                                'bg-bgt-primary',
                                'bg-bgt-primary/80',
                                'bg-bgt-primary/60',
                                'bg-jade/60',
                                'bg-jade',
                              ]}
                            ></PasswordStength>
                          </div>
                        </Fragment>
                      )}
                    {requiredFields.gender && (
                      <div>
                        <SimpleSelectField
                          handleChange={handleElementChange}
                          label="Sex assigned at birth"
                          name="gender"
                          value={formData['gender']}
                          firstItemText="-- Please select an option --"
                          disabled={saving || loading}
                          options={[
                            {
                              label: 'Male',
                              value: 'male',
                            },
                            {
                              label: 'Female',
                              value: 'female',
                            },
                          ]}
                        ></SimpleSelectField>
                      </div>
                    )}
                    {requiredFields.dob && (
                      <div>
                        <TextInputField
                          id="date"
                          label="Date of Birth"
                          name="dob"
                          type="date"
                          min={dateInThePast(130)}
                          max={dateInThePast(18)}
                          placeholder="Select your date of birth, you must be over 18 years of age."
                          disabled={saving || loading}
                          handleChange={handleElementChange}
                        ></TextInputField>
                      </div>
                    )}
                    {requiredFields.phone && (
                      <div className="mt-2">
                        <PhoneNumberInput
                          id="phone"
                          label="Phone number"
                          name="phone"
                          type="tel"
                          disabled={saving || loading}
                          handleChange={handleElementChange}
                        ></PhoneNumberInput>
                      </div>
                    )}
                    {requiredFields.address && (
                      <div className="mt-2">
                        <TextInputField
                          id="address"
                          label="Street Address"
                          name="address"
                          type="address"
                          disabled={saving || loading}
                          handleChange={handleElementChange}
                          value={getPrettyAddress() || ''}
                        ></TextInputField>
                      </div>
                    )}

                    {showPractitionerField && (
                      <div className="mt-2">
                        <SimpleSelectField
                          handleChange={handleElementChange}
                          label="Are you a practitioner? If unsure, choose No."
                          name="isPractitioner"
                          value={formData['isPractitioner']}
                          firstItemText="No"
                          disabled={saving || loading}
                          options={[
                            {
                              label: 'Naturopath',
                              value: 'naturopath',
                            },
                            {
                              label: 'Physiotherapist',
                              value: 'physiotherapist',
                            },
                            {
                              label: 'Personal Trainer',
                              value: 'pt',
                            },
                            {
                              label: 'Exercise Scientist',
                              value: 'exercise-scientist',
                            },
                            {
                              label: 'Other',
                              value: 'other',
                            },
                          ]}
                        ></SimpleSelectField>
                      </div>
                    )}

                    <div className="mt-2">
                      {requiredFields.terms && (
                        <Fragment>
                          <Checkbox
                            id="terms"
                            name="terms"
                            type="checkbox"
                            disabled={saving || loading}
                            onChange={handleElementChange}
                            checked={formData.terms}
                            checkboxLabel={
                              <Fragment>
                                I consent to Bloody Good Tests (BGT) handling
                                lab work. I understand that BGT does not provide
                                medical advice. I have read and agree to the{' '}
                                <a
                                  className="text-bgt-primary/80 hover:underline hover:text-bgt-primary"
                                  href="https://www.bloodygoodtests.com.au/terms-of-use"
                                  target="_terms"
                                >
                                  Terms of Use
                                </a>
                              </Fragment>
                            }
                          ></Checkbox>
                          <br />
                        </Fragment>
                      )}
                      {requiredFields.edm && (
                        <Checkbox
                          id="edm"
                          name="edm"
                          type="checkbox"
                          disabled={saving || loading}
                          checked={formData.edm}
                          onChange={handleElementChange}
                          checkboxLabel={
                            <Fragment>
                              I wish to subscribe to marketing material about
                              Bloody Good Tests
                            </Fragment>
                          }
                        ></Checkbox>
                      )}
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-bgt-primary/90 px-3 py-1.5 text-sm font-semibold leading-6 text-off-white shadow-sm hover:bg-bgt-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bgt-primary disabled:bg-gray-dark disabled:hover:bg-gray-dark"
                        disabled={!isFormReady || saving}
                      >
                        {saving && <ThreeDots height={20} />}
                        {!saving && <span>Complete</span>}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </RoundedSection>
          </div>
        </div>
      )}
    </Fragment>
  )
}
