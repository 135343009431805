import { BiomarkerRange, BiomarkerResult, NumericBiomarkerResult } from "../../lib/validators";

// In future will support notes and ranges with labels
export interface BiomarkerResultOptimalRange {
  name: string
  icon: JSX.Element | undefined
  ranges: BiomarkerRange[]
  unit?: string | null
  isSourceProvider?: boolean
}

enum RefInterval {
  Low,
  Mid,
  High,
}

const getBiomarkerReferencePosition = (biomarker: NumericBiomarkerResult): RefInterval => {
  if (biomarker.refIntervalLow === null && biomarker.refIntervalHigh !== null) {
    if (biomarker.numericValue < biomarker.refIntervalHigh) {
      return RefInterval.Mid;
    } else {
      return RefInterval.High;
    }
  } else if (
    biomarker.refIntervalLow !== null &&
    biomarker.refIntervalHigh === null
  ) {
    if (biomarker.numericValue > biomarker.refIntervalLow) {
      return RefInterval.Mid;
    } else {
      return RefInterval.Low;
    }
  } else if (
    biomarker.refIntervalLow !== null &&
    biomarker.refIntervalHigh !== null
  ) {
    if (
      biomarker.numericValue >= biomarker.refIntervalLow &&
      biomarker.numericValue <= biomarker.refIntervalLow
    ) {
      return RefInterval.Mid;
    } else if (biomarker.numericValue < biomarker.refIntervalLow) {
      return RefInterval.Low;
    } else if (biomarker.numericValue > biomarker.refIntervalHigh) {
      return RefInterval.High;
    }
  }
  return RefInterval.Mid;
};

export const getTextForUsersReference = (biomarker: BiomarkerResult): string => {
  if (biomarker.valueType === 'string') {
    return '';
  }

  const ref = getBiomarkerReferencePosition(biomarker);

  if (ref === RefInterval.Mid) {
    return biomarker.refIntervalMidNotes || "";
  } else if (ref === RefInterval.Low) {
    return biomarker.refIntervalLowNotes || "";
  }
  return biomarker.refIntervalHighNotes || "";
};
