import { ReactNode, useState } from 'react'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { Modal } from '../../../../layout/Modal'

interface ArchiveModalProps<T> {
  show: boolean
  theme?: ThemeInterface
  setShow: (openState: boolean) => void
  message: string | ReactNode
  disableConfirm?: boolean
  confirmLabel?: string
  title?: string
  dismissOnArchive?: boolean
  onArchive?: (value: T) => Promise<void>
  onDismiss?: (value: T) => void
  value: T
}

export function ArchiveBundleModal<T>({
  show,
  setShow,
  theme,
  onArchive,
  onDismiss,
  value,
  message,
  disableConfirm = false,
  confirmLabel = 'Archive Test Bundle',
  title = 'Archive Test Bundle?',
  dismissOnArchive = false,
}: ArchiveModalProps<T>) {
  const [loading, setLoading] = useState(false)
  const [canDismiss, setCanDismiss] = useState(true)

  return (
    <Modal
      show={show}
      loading={loading}
      disableConfirm={disableConfirm}
      canDismiss={canDismiss}
      options={{
        secondaryAction: {
          label: 'Dismiss',
          handler: () => {
            if (!loading) {
              setShow(false)
              if (onDismiss) {
                onDismiss(value)
              }
            }
          },
        },
        mainActions: [
          {
            label: confirmLabel,
            handler: async () => {
              try {
                setLoading(true)
                setCanDismiss(false)
                if (onArchive) {
                  await onArchive(value)
                }
                if (dismissOnArchive) {
                  setShow(false)
                }
              } catch (error) {
                console.error('Archive action failed:', error)
              } finally {
                setLoading(false)
                setCanDismiss(true)
              }
            },
          },
        ],
        title,
        content: message,
      }}
      onClose={() => {
        if (canDismiss) {
          setShow(false)
        }
      }}
      theme={theme}
      size="lg"
    />
  )
}
