import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { TestWithBiomarkersSelected } from '../../lib/validators'
import Checkbox from '../form/Checkbox'
import { onChangeInterface } from '../form/TextInputField'
import RoundedSection from '../layout/RoundedSection'
import { Fragment, useEffect, useState } from 'react'
import { ThemeInterface } from '../../lib/interfaces'
import { formatMoney } from '../../lib/utils'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { TestTurnaroundTime } from '../ui/TestTurnaroundTime'
import { Highlight } from '../ui/Highlight'
import { BiomarkerCount } from '../ui/BiomarkerCount'

interface TestCustomisationItemProps {
  test: TestWithBiomarkersSelected
  checked?: boolean
  onChange?: (data: onChangeInterface) => void
  onInfoClick?: (biomarker: TestWithBiomarkersSelected) => void
  highlight?: string
  theme?: ThemeInterface
}

export function TestCustomisationItem({
  test,
  checked = false,
  onChange,
  onInfoClick,
  highlight,
}: TestCustomisationItemProps) {
  const [localTest, setLocalTest] = useState<TestWithBiomarkersSelected>()

  useEffect(() => {
    setLocalTest(test)
  }, [test])

  return (
    <Fragment>
      {localTest &&
        (localTest.standalone === null || localTest.standalone === true) && (
          <div className={`relative group`}>
            <Checkbox
              id={localTest.id}
              name={localTest.id}
              type="checkbox"
              onChange={onChange}
              checked={checked}
              className={`w-6 h-6 !absolute top-8 right-2 z-50 hidden`}
              checkboxLabel={
                <RoundedSection
                  size="lg"
                  className="relative !p-0 -mt-6 dark:!bg-dark-gray-lighterer"
                >
                  <div className="absolute bottom-4 right-4"></div>
                  <div className="group-has-[:checked]:bg-jade/30 rounded-lg">
                    <div className="block xl:hidden p-2 min-h-24">
                      <div className="flex">
                        <div className="flex-grow">
                          <Highlight
                            term={highlight}
                            text={localTest.name}
                          ></Highlight>
                          <small className="pb-2 block text-gray-dark">
                            <Highlight
                              term={highlight}
                              text={localTest.shortName}
                            ></Highlight>
                          </small>
                          <TestTurnaroundTime
                            turnaroundTimes={test.turnAroundTimeAverage}
                            hideHeading={true}
                            showMissingText={false}
                            className="mr-1"
                          ></TestTurnaroundTime>
                          <InformationCircleIcon
                            width={24}
                            height={24}
                            className="text-white bg-jade/80 hover:bg-jade rounded-full font-bold inline-block"
                            onClick={(e) => {
                              e.preventDefault()
                              onInfoClick && onInfoClick(localTest)
                            }}
                          ></InformationCircleIcon>
                        </div>
                        <div className="min-w-28 items-end text-right">
                          <div>
                            {localTest.priceCategory && (
                              <Fragment>
                                <b data-tooltip-id="discount-tooltip">
                                  {formatMoney(localTest.price / 100, 'aud')} *
                                </b>
                                <Tooltip
                                  id="discount-tooltip"
                                  place="bottom"
                                  content="This item is eligable for a bulk discount."
                                  className="rounded-lg z-50"
                                />
                              </Fragment>
                            )}
                            {!localTest.priceCategory && (
                              <Fragment>
                                <b>
                                  {formatMoney(localTest.price / 100, 'aud')}
                                </b>
                              </Fragment>
                            )}
                            <div>
                              <BiomarkerCount>
                                {localTest.biomarkers?.length}
                              </BiomarkerCount>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-4 hidden xl:block">
                      <div>
                        <Highlight
                          term={highlight}
                          text={localTest.name}
                        ></Highlight>
                      </div>
                      <small className="pb-2 block text-gray-dark">
                        <Highlight
                          term={highlight}
                          text={localTest.shortName}
                        ></Highlight>
                      </small>
                      {localTest.priceCategory && (
                        <Fragment>
                          <b data-tooltip-id="discount-tooltip">
                            {formatMoney(localTest.price / 100, 'aud')} *
                          </b>
                          <Tooltip
                            id="discount-tooltip"
                            place="bottom"
                            content="This item is eligable for a bulk discount."
                            className="rounded-lg"
                          />
                        </Fragment>
                      )}
                      {!localTest.priceCategory && (
                        <Fragment>
                          <b>{formatMoney(localTest.price / 100, 'aud')}</b>
                        </Fragment>
                      )}

                      <div className="flex items-center">
                        <div className="flex-grow">
                          <TestTurnaroundTime
                            turnaroundTimes={test.turnAroundTimeAverage}
                            hideHeading={true}
                            showMissingText={false}
                            className="mr-2"
                          ></TestTurnaroundTime>
                          <div className="inline-block mr-2">
                            <BiomarkerCount>
                              {localTest.biomarkers?.length}
                            </BiomarkerCount>
                          </div>
                        </div>
                        <InformationCircleIcon
                          width={24}
                          height={24}
                          className="text-white bg-jade/80 hover:bg-jade rounded-full font-bold inline-block"
                          onClick={(e) => {
                            e.preventDefault()
                            onInfoClick && onInfoClick(localTest)
                          }}
                        ></InformationCircleIcon>
                      </div>
                      <ul className="hidden">
                        <li>Included Biomarkers:</li>
                        {localTest.biomarkers?.map((biomarker) => (
                          <li
                            key={`test-${localTest.id}-biomarker-${biomarker.id}`}
                            className="text-gray-dark text-sm"
                          >
                            <Highlight
                              term={highlight}
                              text={localTest.name}
                            ></Highlight>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </RoundedSection>
              }
            ></Checkbox>
          </div>
        )}
    </Fragment>
  )
}
