import '../../App.css'
import FullPageLoader from '../layout/FullPageLoader'

export default function CallbackPage() {
  // this page is just a placeholder so we have a route for /callback,
  // all of the logic is actually happening in the AppWrapper

  return (
    <>
      <FullPageLoader></FullPageLoader>
    </>
  )
}
