import ShopifyLogo from '../../public/img/providers/shopify.png?url'
import WebflowLogo from '../../public/img/providers/webflow.png?url'

export const getImageForStoreProvider = (provider: string): string | undefined => {
  if (provider === 'shopify') {
    return ShopifyLogo
  }
  if (provider === 'webflow') {
    return WebflowLogo
  }
  return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
}
