import toast from 'react-hot-toast'
import { ItemType, MenuItem } from '../../../../header/ProfileHeader'
import DropdownMenu from '../../../../menu/DropdownMenu'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import {
  ArchiveBoxIcon,
  ClipboardDocumentListIcon,
  CreditCardIcon,
  PaperAirplaneIcon,
  ExclamationTriangleIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'
import { SendPackageModal } from '../modals/SendPackageModal'
import { OrganisationWithBranding } from '../../../../context/OrganisationContext'
import { stripeOptions, stripePromise } from '../../../../../lib/billing'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { Elements } from '@stripe/react-stripe-js'
import { BuyPackageModal } from '../modals/BuyPackageModal'
import { getBundleUrl, getStoreUrl } from '../../../../../lib/routes'
import { ArchiveBundleModal } from '../modals/ArchiveBundleModal'
import { Link } from 'react-router-dom'
import { getImageForStoreProvider } from '../../../../../lib/commerceStore'
import { Button } from '../../../../form/button/Button'

interface BundleActionsDropdownProps {
  bundleName: string
  bundleId: string
  disabled?: boolean
  org: OrganisationWithBranding
  theme?: ThemeInterface
  buttonLabel?: string
  editControls?: boolean
  isArchived?: boolean
  storeLinks?: {
    storeUuid: string
    storeProvider: string
    storeName: string
  }[]
  handleArchive: (bundleId: string) => Promise<void>
}

export function BundleActionsDropdown({
  bundleId,
  bundleName,
  disabled,
  org,
  theme,
  buttonLabel = 'Actions',
  editControls = false,
  isArchived,
  storeLinks,
  handleArchive,
}: BundleActionsDropdownProps) {
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [openBuyModal, setOpenBuyModal] = useState(false)
  const [openArchiveModal, setOpenArchiveModal] = useState(false)

  const getActionMenuItems = (): MenuItem[] => {
    const items: MenuItem[] = []

    // Add edit controls if enabled.
    if (editControls) {
      items.push(
        {
          type: ItemType.GroupTitle,
          name: 'Edit',
          current: false,
        },
        {
          current: false,
          name: 'Edit Bundle',
          type: ItemType.Item,
          icon: PencilSquareIcon,
          className: '',
          href: getBundleUrl(org!.id, bundleId),
        },
        {
          type: ItemType.Divider,
          name: 'Divider',
          current: false,
        }
      )
    }

    // Only show share section if the bundle is not archived.
    if (!isArchived) {
      items.push(
        {
          type: ItemType.GroupTitle,
          name: 'Share',
          current: false,
        },
        {
          current: false,
          name: 'Send to...',
          type: ItemType.Item,
          icon: PaperAirplaneIcon,
          className: '',
          onClick: () => {
            setOpenInviteModal(true)
          },
        },
        {
          current: false,
          name: 'Buy for...',
          type: ItemType.Item,
          icon: CreditCardIcon,
          className: '',
          onClick: () => {
            setOpenBuyModal(true)
          },
        },
        {
          type: ItemType.Divider,
          name: 'Divider',
          current: false,
        },
        {
          current: false,
          name: 'Copy Bundle URL',
          type: ItemType.Item,
          icon: ClipboardDocumentListIcon,
          className: '',
          onClick: () => {
            navigator.clipboard.writeText(
              `${import.meta.env.VITE_APP_URL}/buy/${bundleId}`
            )
            toast.success(`${bundleName} URL copied`, { duration: 3000 })
          },
        },
        {
          type: ItemType.Divider,
          name: 'Divider',
          current: false,
        }
      )
    }

    // Always push Archive/Unarchive item.
    items.push(
      {
        type: ItemType.Item,
        current: false,
        name: isArchived ? 'Unarchive Bundle' : 'Archive Bundle',
        icon: ArchiveBoxIcon,
        className: 'text-blood hover:text-blood/80',
        onClick: () => {
          setOpenArchiveModal(true)
        },
      }
    )

    return items
  }

  // Construct the message for the Archive modal.
  // If the bundle has store links, show a warning and list each store with a link.
  const archiveModalMessage =
    storeLinks && storeLinks.length > 0 ? (
      <div>
        <p>
          You cannot archive this bundle until it is removed from the following
          stores:
        </p>
        <div className="flex flex-col gap-2 ml-2 mt-2">
          {storeLinks.map((link) => (
            <Link
              key={link.storeUuid}
              to={getStoreUrl(org.id, link.storeUuid)}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-4 cursor-pointer text-blood hover:underline p-2 rounded-md hover:bg-gray-100 transition"
            >
              <div className="flex items-center gap-4">
                <span>{link.storeName}</span>
                <img
                  src={getImageForStoreProvider(link.storeProvider)}
                  className="h-4 w-16 object-contain"
                />
              </div>
              <Button color="plain" label="Go to Store" type="button" />
            </Link>
          ))}
        </div>
      </div>
    ) : isArchived ? (
      'Unarchiving this bundle will make it visible and available for purchase again.'
    ) : (
      'Archiving this bundle will hide it from users and prevent purchases, but you can still edit it.'
    )

  return (
    <div>
      <DropdownMenu
        menuItems={getActionMenuItems()}
        disabled={disabled}
        disabledInfo={
          <span className="absolute right-0 w-64 text-sm mt-2 text-right text-blood">
            <ExclamationTriangleIcon width={16} className="inline-block" />
            Please save your changes first
          </span>
        }
      >
        {buttonLabel}
        <ChevronDownIcon className="size-4 fill-white/60 inline" />
      </DropdownMenu>

      <SendPackageModal
        org={org}
        productId={bundleId}
        productName={bundleName}
        theme={theme}
        show={openInviteModal}
        setShow={setOpenInviteModal}
        dismissOnSkip={true}
        dismissOnSend={true}
      />

      <Elements stripe={stripePromise} options={stripeOptions}>
        <BuyPackageModal
          org={org}
          productId={bundleId}
          productName={bundleName}
          theme={theme}
          show={openBuyModal}
          setShow={setOpenBuyModal}
          dismissOnSkip={true}
          dismissOnSend={true}
        />
      </Elements>

      <ArchiveBundleModal
        show={openArchiveModal}
        setShow={setOpenArchiveModal}
        theme={theme}
        value={bundleId}
        message={archiveModalMessage}
        title={
          isArchived ? `Unarchive ${bundleName}?` : `Archive ${bundleName}?`
        }
        confirmLabel={isArchived ? 'Unarchive Bundle' : 'Archive Bundle'}
        dismissOnArchive={true}
        onArchive={handleArchive}
        // Disable the confirm action if there are store links
        disableConfirm={storeLinks && storeLinks.length > 0}
      />
    </div>
  )
}
