import {
  Fragment,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  BiomarkerPreviewItemWithTestTurnaroundTimes,
  BiomarkerResult,
  MixedPreviewResultGroupedBiomarker,
  ResultReferral,
} from '../../../lib/validators'

import { PrinterIcon } from '@heroicons/react/24/outline'
import { useSearchParams } from 'react-router-dom'
import { ThemeInterface } from '../../../lib/interfaces'
import { useFeatureFlagEnabled } from '../../../lib/posthog'
import {
  defaultConclusion,
  defaultDisclaimer,
  HistoricalBiomarkerResultMap,
} from '../../../lib/results'
import { useUser } from '../../../lib/store'
import { simpleMdTransform } from '../../../lib/utils'
import BiomarkerResultRow from '../../biomarker/BiomarkerResultRow'
import { Button } from '../../form/button/Button'
import {
  useOptimalRangeOptions,
  usePathologyProviderOptions,
  useSelectedRanges,
} from '../../hooks/resultViewerFilter'
import RoundedSection from '../../layout/RoundedSection'
import ResultAISummarySection from '../../result/ResultAISummarySection'
import { ResultTabMenuBar, ViewFilterStates } from './ResultTabMenuBar'
import { SimpleBiomarkerView } from './lib/SimpleBiomarkerView'

import BiomarkerRow from '../../biomarker/BiomarkerRow'
import { ResultStatus } from '../../../lib/types'

const baseDisclaimerKey = 'bgt_disclaimer'

export interface ResultsBiomarkerProps extends PropsWithChildren {
  isResultOwner?: boolean
  groupedBiomarkers?: MixedPreviewResultGroupedBiomarker
  result: ResultReferral
  historicalData?: HistoricalBiomarkerResultMap
  theme?: ThemeInterface
}

interface FilterState {
  view?: string
  search?: string
  ranges: string[]
}

export const ResultTabBiomarkers: React.FC<ResultsBiomarkerProps> = ({
  isResultOwner = false,
  groupedBiomarkers,
  result,
  historicalData,
  theme,
}) => {
  // State hooks
  const [groupsOfBiomarkers, setGroupsOfBiomarkers] =
    useState<MixedPreviewResultGroupedBiomarker>()
  const [filteredGroupsOfBiomarkers, setFilteredGroupsOfBiomarkers] =
    useState<MixedPreviewResultGroupedBiomarker>()
  const [disclaimerDismissed, setDisclaimerDismissed] = useState(false)
  const [historicalBiomarkerData, setHistoricalBiomarkerData] = useState<
    HistoricalBiomarkerResultMap | undefined
  >(historicalData)
  const [disclaimerKey, setDisclaimerKey] = useState<string | undefined>()
  const [searchParams, setSearchParams] = useSearchParams()
  const canUseAiResultsSummary = useFeatureFlagEnabled(
    'can-use-ai-results-summary'
  )

  // Context and custom hooks
  const user = useUser()
  const {
    options: pathologyProviderOptions,
    loading: pathologyRangeOptionsLoading,
  } = usePathologyProviderOptions(result)
  const { options: optimalRangeOptions, loading: optimalRangeOptionLoading } =
    useOptimalRangeOptions(result)

  // Derived filter from searchParams
  const filter: FilterState = {
    search: searchParams.get('search') || undefined,
    view: searchParams.get('view') || ViewFilterStates.BGT,
    ranges: searchParams.get('ranges')?.split(',') || [],
  }

  // Combined range options
  const combinedRangeOptions = useMemo(
    () => [...pathologyProviderOptions, ...optimalRangeOptions],
    [pathologyProviderOptions, optimalRangeOptions]
  )
  const { optimalRangesById, allRangeOptions } = useSelectedRanges({
    optimalRangeOptions: combinedRangeOptions,
    selectedRangeKeys: filter.ranges,
    result,
  })

  // Loading state
  const loading =
    pathologyRangeOptionsLoading ||
    optimalRangeOptionLoading ||
    !groupedBiomarkers ||
    groupedBiomarkers.length === 0

  // Set default ranges if no query param is provided and options have loaded
  useEffect(() => {
    if (pathologyProviderOptions.length > 0 && !searchParams.get('ranges')) {
      setSearchParams(
        (prev) => {
          const newParams = new URLSearchParams(prev)
          newParams.set(
            'ranges',
            pathologyProviderOptions.map((provider) => provider.key).join(',')
          )
          return newParams
        },
        { replace: true }
      )
    }
  }, [pathologyProviderOptions, setSearchParams, searchParams])

  useEffect(() => {
    if (loading) return
    setHistoricalBiomarkerData(historicalData)
  }, [loading, historicalData])

  useEffect(() => {
    if (loading) return

    if (user && disclaimerKey === undefined) {
      setDisclaimerKey(`${baseDisclaimerKey}_${user.uuid}`)
    }

    if (disclaimerKey === undefined) return

    const disclaimerState = localStorage.getItem(disclaimerKey)
    if (disclaimerState) {
      try {
        const disclaimerJson = JSON.parse(disclaimerState)
        if (
          disclaimerJson.expiresAt &&
          disclaimerJson.expiresAt > Math.floor(Date.now() / 1000)
        ) {
          setDisclaimerDismissed(true)
        }
      } catch (e) {
        console.log('do nothing')
      }
    }
  }, [loading, disclaimerKey, user])

  useEffect(() => {
    if (loading) return
    setGroupsOfBiomarkers(groupedBiomarkers)
    setHistoricalBiomarkerData(historicalData)
  }, [loading, groupedBiomarkers, historicalData])

  useEffect(() => {
    if (loading) return
    if (!filter.search) {
      setFilteredGroupsOfBiomarkers(groupsOfBiomarkers)
      return
    }

    if (groupsOfBiomarkers) {
      // we need to create a filtered list now
      const matches: MixedPreviewResultGroupedBiomarker = []
      if (filter.search) {
        const searchTerm = filter.search.toLowerCase()

        groupsOfBiomarkers.forEach((group) => {
          const groupBiomarkers: BiomarkerResult[] = []
          const pendingBiomarkers: BiomarkerPreviewItemWithTestTurnaroundTimes[] =
            []
          group.biomarkers.forEach((biomarker) => {
            if (
              biomarker.name.toLowerCase().includes(searchTerm) ||
              biomarker.commonName?.toLowerCase().includes(searchTerm)
            ) {
              groupBiomarkers.push(biomarker)
            }
          })
          group.pendingBiomarkers.forEach((biomarker) => {
            if (
              biomarker.name.toLowerCase().includes(searchTerm) ||
              biomarker.commonName?.toLowerCase().includes(searchTerm)
            ) {
              pendingBiomarkers.push(biomarker)
            }
          })
          // if we matched anything, lets push them into a new group
          if (groupBiomarkers.length > 0 || pendingBiomarkers.length > 0) {
            matches.push({
              category: group.category,
              biomarkers: groupBiomarkers,
              pendingBiomarkers: pendingBiomarkers,
            })
          }
        })
      }

      // console.log(results)
      if (matches.length > 0) {
        setFilteredGroupsOfBiomarkers(matches)
        return
      } else {
        setFilteredGroupsOfBiomarkers(undefined)
      }
    }
  }, [loading, filter.search, groupsOfBiomarkers])

  // Utility function to safely update search params
  const updateSearchParams = (key: string, value?: string | string[]) => {
    setSearchParams(
      (prevParams) => {
        const newParams = new URLSearchParams(prevParams)

        // Remove the key if the value is empty or undefined
        if (!value || (Array.isArray(value) && value.length === 0)) {
          newParams.delete(key)
        } else {
          newParams.set(key, Array.isArray(value) ? value.join(',') : value)
        }

        return newParams
      },
      { replace: true }
    )
  }

  const handleOnRangesChange = (selectedKeys: string[]) => {
    if (selectedKeys.length === 0) {
      // 'none' is not a valid key so it should remove all range bars
      updateSearchParams('ranges', ['none'])
      return
    }
    updateSearchParams('ranges', selectedKeys)
  }

  const handleOnSearchChange = (term: string) => {
    updateSearchParams('search', term || undefined)
  }

  const handleOnViewChange = (view: string) => {
    if (view === ViewFilterStates.BGT) {
      updateSearchParams('view', undefined)
      return
    }
    updateSearchParams('view', view || undefined)
  }

  // Utility function to hide disclaimer
  const hideDisclaimer = () => {
    if (!disclaimerKey) return

    const d = new Date()
    d.setDate(d.getDate() + 30)

    localStorage.setItem(
      disclaimerKey,
      JSON.stringify({
        expiresAt: Date.parse(d.toString()) / 1000,
        hidden: true,
      })
    )

    setDisclaimerDismissed(true)
  }

  return (
    <Fragment>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Fragment>
          {true && !disclaimerDismissed && (
            <RoundedSection size="lg" className="mb-6 print:hidden ">
              <div
                className="select-none"
                dangerouslySetInnerHTML={{
                  __html: simpleMdTransform('## Disclaimer') || '',
                }}
              ></div>
              <p
                className=" dark:text-gray-dark text-black/70 text-sm select-none italic"
                dangerouslySetInnerHTML={{
                  __html: defaultDisclaimer || '',
                }}
              ></p>
              <Button
                color={'plain'}
                classNames={`!m-0 !p-0 !mt-6 !text-md ${!disclaimerKey ? 'hidden' : ''}`}
                label="Click here to hide the disclaimer for 30 days"
                onClick={hideDisclaimer}
              ></Button>
            </RoundedSection>
          )}
          <ResultTabMenuBar
            rangeOptions={allRangeOptions}
            selectedRangeKeys={filter.ranges}
            onRangesChange={handleOnRangesChange}
            searchTerm={filter.search || ''}
            onSearchChange={handleOnSearchChange}
            view={filter.view}
            onViewChange={handleOnViewChange}
            theme={theme}
          />
          <div className="text-right mb-4 print:hidden">
            <Button onClick={() => print()}>
              <PrinterIcon
                width={20}
                title="Print"
                className="inline cursor-pointer ml-2  !text-[--button-text-color]"
                style={
                  theme
                    ? ({
                        '--button-text-color': theme.buttonTextColor,
                      } as React.CSSProperties)
                    : {}
                }
              ></PrinterIcon>{' '}
              Print
            </Button>
          </div>
          {filter.view === ViewFilterStates.SIMPLE && (
            <RoundedSection size="lg" className="mb-8" key={`simple-section`}>
              <SimpleBiomarkerView
                groupedBiomarkers={filteredGroupsOfBiomarkers || []}
                theme={theme}
                result={result}
                highlight={filter.search}
              ></SimpleBiomarkerView>
            </RoundedSection>
          )}
          {filter.view === ViewFilterStates.BGT && (
            <Fragment>
              {canUseAiResultsSummary && (
                <ResultAISummarySection
                  enableGenerateButton={isResultOwner}
                  resultUuid={result.uuid}
                  resultAiSummaryConsentUuid={
                    result.aiSummaryConsentUuid || undefined
                  }
                  resultAiSummary={result.aiSummary || undefined}
                  theme={theme}
                />
              )}
              {filteredGroupsOfBiomarkers &&
                filteredGroupsOfBiomarkers.map((group) => (
                  <RoundedSection
                    size="lg"
                    className="mb-8 print:break-after-page"
                    key={`${group.category.name}-section`}
                  >
                    <h2 className="text-lg font-semibold text-black/70 dark:text-white mb-2 select-none">
                      {group.category.name}
                    </h2>
                    {group.category.description && (
                      <div className="text-sm text-black/70 dark:text-gray-light mb-6 mt-4 select-none">
                        {group.category.description}
                      </div>
                    )}
                    {group.biomarkers.length > 0 && (
                      <div className="divide-y divide-border divide-border-gray dark:divide-border-dark-gray-light">
                        {group.biomarkers.map((biomarker) => (
                          <div
                            className="pt-6 mt-8"
                            key={biomarker.id}
                            id={biomarker.id}
                          >
                            <BiomarkerResultRow
                              key={`${biomarker.id}-biomarker-row`}
                              // className="mt-4"
                              biomarker={biomarker}
                              historicalData={
                                historicalBiomarkerData &&
                                historicalBiomarkerData[biomarker.id] &&
                                historicalBiomarkerData[biomarker.id].length > 0
                                  ? historicalBiomarkerData[biomarker.id]
                                  : undefined
                              }
                              theme={theme}
                              optimalRanges={
                                optimalRangesById[biomarker.id] || undefined
                              }
                              highlight={filter.search}
                            ></BiomarkerResultRow>
                          </div>
                        ))}
                      </div>
                    )}
                    {result.status !== ResultStatus.Complete &&
                      group.biomarkers.length > 0 &&
                      group.pendingBiomarkers.length > 0 && (
                        <div className="border border-gray dark:border-dark-gray-light mt-10"></div>
                      )}
                    {result.status !== ResultStatus.Complete &&
                      group.pendingBiomarkers.length > 0 && (
                        <div className="divide-y divide-border divide-border-gray dark:divide-border-dark-gray-light">
                          {group.pendingBiomarkers.map((biomarker) => (
                            <div
                              className="rounded-md pt-8 mb-4"
                              key={biomarker.id}
                            >
                              <BiomarkerRow
                                biomarker={biomarker}
                                result={result}
                                theme={theme}
                                highlight={filter.search}
                              ></BiomarkerRow>
                            </div>
                          ))}
                        </div>
                      )}
                    <div className="mb-4"></div>
                  </RoundedSection>
                ))}
            </Fragment>
          )}
          {true && (
            <RoundedSection size="lg" className="mb-6 ">
              <div
                className="select-none"
                dangerouslySetInnerHTML={{
                  __html: simpleMdTransform('## Conclusion') || '',
                }}
              ></div>
              <p
                className=" text-sm select-none"
                dangerouslySetInnerHTML={{
                  __html: defaultConclusion || '',
                }}
              ></p>
            </RoundedSection>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
