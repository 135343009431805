import { TrashIcon } from '@heroicons/react/24/solid'
import { BiomarkerPreviewItem } from '../../lib/validators'
import { PropsWithChildren } from 'react'

interface BiomarkerPillProps {
  biomarker: Pick<BiomarkerPreviewItem, 'name'>
}
interface PillProps extends PropsWithChildren {
  editable?: boolean
  onDelete?: () => void
  rounded?: boolean
  className?: string
}

export function BiomarkerPill({ biomarker }: BiomarkerPillProps) {
  return <Pill>{biomarker.name}</Pill>
}

export function Pill({
  children,
  onDelete,
  editable = true,
  rounded = false,
  className,
}: PillProps) {
  return (
    <div
      className={`px-2 py-1 my-1 mr-1 align-middle items-center text-center inline-flex border-2 dark:!border-1 border-dark-gray-lighterer/40 dark:border-white cursor-default text-md ${rounded ? 'rounded-full' : 'rounded-md'} text-dark-gray-light dark:text-white shadow-lg ${className ? className : ''}`}
    >
      {children}
      {editable && onDelete && (
        <span
          onClick={onDelete}
          className="cursor-pointer ml-2 rounded-full inline-block bg-gray-semi-dark/20 hover:bg-gray-semi-dark/30 text-black/70 hover:text-black dark:text-white dark:hover:text-white/80 font-bold p-1"
        >
          <TrashIcon width={12}></TrashIcon>
        </span>
      )}
    </div>
  )
}
