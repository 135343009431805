import {
  Fragment,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { orgHomePrefix } from '../lib/routes'
import {
  useBranding,
  useEmailVerified,
  usePageRequiresAuth,
  useUser,
} from '../lib/store'
import { resendVerificationEmail } from '../lib/user'
import { useOrganisationContext } from './context/OrganisationContext'
import Header from './Header'
import Sidebar from './sidebar/Sidebar'

export interface PageWithProps extends PropsWithChildren {
  backgroundColor?: string
  fullViewport?: boolean
  removePagePadding?: boolean
  className?: string
}

function Page(props: PageWithProps) {
  const emailVerified = useEmailVerified()
  const user = useUser()
  const location = useLocation()
  const { org, theme } = useOrganisationContext()
  const branding = useBranding()
  const [isEmailVerified, setIsEmailVerified] = useState<boolean | null>(null)
  const [isChromeless, setChromeless] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const pageRequiresAuth = usePageRequiresAuth()

  useEffect(() => {
    // set the profile to be the first one
    // console.log("waiting for a user");
    setIsEmailVerified(emailVerified)

    // fetch the tests here
  }, [emailVerified])

  useEffect(() => {
    const chromeless = searchParams.get('chromeless')
    setChromeless(
      chromeless === null || chromeless === undefined ? false : true
    )
  }, [searchParams])

  const shouldBeChromeless = useMemo(
    () => (!user && !isChromeless ? true : isChromeless),
    [user, isChromeless]
  )

  const hasUserVerifiedEmail = (): boolean | null => {
    if (isEmailVerified === null) {
      // default to true so we don't flash the banner
      return true
    }
    return isEmailVerified
  }

  const getPageBackgroundColor = (): string => {
    return props.backgroundColor || ''
  }

  useEffect(() => setSidebarOpen(false), [location])

  return (
    <Fragment>
      {!shouldBeChromeless && (
        <div
          className={`h-full foobar ${user || pageRequiresAuth === false ? 'block' : 'hidden'}`}
        >
          <Header
            setSidebarOpen={setSidebarOpen}
            theme={theme || branding?.theme || undefined}
          ></Header>
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            theme={theme || branding?.theme || undefined}
            urlPrefix={org ? `${orgHomePrefix}/${org?.id}` : '/'}
          ></Sidebar>

          <div
            className={`lg:pl-72 z-0 pt-16 relative ${getPageBackgroundColor()}`}
          >
            {!hasUserVerifiedEmail() && (
              <div className="fixed z-40 backdrop-blur-md lg:pl-72 left-0 top-16 w-full shadow-md leading-3 border-0">
                <div className="p-2 text-sm pl-4 bg-jade/80">
                  Your email has not been verified, please check your email for
                  a verification email or{' '}
                  <button
                    className="cursor-pointer underline"
                    onClick={() =>
                      resendVerificationEmail({
                        profileUuid: user!.profileUuid, // We should have loaded user if we know they haven't verified
                      })
                    }
                  >
                    resend
                  </button>
                </div>
              </div>
            )}
            <main
              className={`${
                props.removePagePadding === true ? '' : 'px-4 sm:px-6 lg:px-4'
              } ${
                props.removePagePadding === true
                  ? ''
                  : hasUserVerifiedEmail()
                    ? `pt-4 pb-5`
                    : 'pt-20 pb-5'
              } `}
            >
              <div
                className={`${props.fullViewport ? '' : 'max-w-screen-lg'} ${props.className || ''}`}
              >
                {props.children}
              </div>
            </main>
          </div>
        </div>
      )}
      {shouldBeChromeless && <div>{props.children}</div>}
    </Fragment>
  )
}

export default Page
