import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import TestTube from '../../../../../public/img/test-tube.svg'

import OrgPage from '../../../OrgPage'
import PageSection from '../../../layout/PageSection'
import { PageHeading } from '../../../layout/PageHeading'

import { useUser } from '../../../../lib/store'
import { getResultsData } from '../../../../lib/results'
import { Result, TestTurnaroundTimeBase } from '../../../../lib/validators'
import {
  BasicTableGroupProps,
  BasicTableProps,
} from '../../../layout/BasicTable'
import { getNiceDate, months, setDocumentTitle } from '../../../../lib/utils'
import { StatusTagColors } from '../../../../lib/interfaces'
import { Link } from 'react-router-dom'
import { getOrgResultUrl } from '../../../../lib/routes'
import { Button } from '../../../form/button/Button'
import { EmptyState } from '../../../layout/EmptyState'

import PageLoader from '../../../layout/PageLoader'
import { BiomarkerSummaryIndicator } from '../../../ui/BiomarkerSummaryIndicator'

import { ExpectedResultsDate } from '../../../ui/ExpectedResultsDate'
import { PendingTestsModal } from './modals/PendingTestsModal'
import { ResultStatus } from '../../../../lib/types'
import { StatusTag } from '../../../layout/StatusTag'
import { LoadMore } from '../../../ui/LoadMore'
import { PageError } from '../../../ui/PageError'

const DEFAULT_FETCH_COUNT = 50

export function OrgResults() {
  const [results, setResults] = useState<Result[]>([])
  const { org, theme } = useOrganisationContext()
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState('')
  const [tableData, setTableData] = useState<BasicTableProps | undefined>()
  const [showPendingTestModal, setShowPendingTestModal] = useState(false)
  const [selectedResult, setSelectedResult] = useState<Result>()
  const [cursor, setCursor] = useState<string | null | undefined>(undefined)
  const [pageDidError, setPageDidError] = useState(false)
  const user = useUser()

  const onViewPendingInfoClick = (result: Result) => {
    setSelectedResult(result)
    setShowPendingTestModal(true)
  }

  useEffect(() => {
    setDocumentTitle(`Client Results`, 'BRANDED')
  }, [])

  const testTurnaroundTimes = useMemo((): Record<
    string,
    TestTurnaroundTimeBase
  > => {
    const resultAverageTurnaroundTime: Record<string, TestTurnaroundTimeBase> =
      {}
    results.map((r) => {
      let max: number | null = null
      let min: number | null = null
      r.pendingTests?.map((tat) => {
        if (
          tat.turnAroundTimeAverage &&
          tat.turnAroundTimeAverage.minimumTime &&
          min === null
        ) {
          min = tat.turnAroundTimeAverage.minimumTime
        }
        if (
          tat.turnAroundTimeAverage &&
          tat.turnAroundTimeAverage.maximumTime &&
          max === null
        ) {
          max = tat.turnAroundTimeAverage.maximumTime
        }
        if (
          tat.turnAroundTimeAverage &&
          tat.turnAroundTimeAverage.minimumTime
        ) {
          min = Math.max(tat.turnAroundTimeAverage.minimumTime, min!)
        }
        if (
          tat.turnAroundTimeAverage &&
          tat.turnAroundTimeAverage.maximumTime
        ) {
          max = Math.max(tat.turnAroundTimeAverage.maximumTime, max!)
        }
      })
      if (max !== 0 && min !== Infinity) {
        resultAverageTurnaroundTime[r.uuid] = {
          maximumTime: max,
          minimumTime: min,
        }
      }
    })
    return resultAverageTurnaroundTime
  }, [results])

  const getResultStatusColor = (
    status: string | undefined
  ): StatusTagColors => {
    switch (status) {
      case ResultStatus.Complete:
        return StatusTagColors.GREEN
      case ResultStatus.InReview:
        return StatusTagColors.YELLOW
      case ResultStatus.PartialResults:
        return StatusTagColors.MANDARIN
      case ResultStatus.Created:
      default:
        return StatusTagColors.GRAY
    }
  }

  const massageResultsDataIntoTable = useCallback(
    (results: Result[]) => {
      const rows = []
      const resultsByYear: Record<string, BasicTableGroupProps> = {}
      // console.log("referrals", results);
      for (const result of results) {
        const d = new Date(result.resultDate)
        const year = d.getFullYear()

        // if (year !== currentYear) {
        if (!resultsByYear[year]) {
          resultsByYear[year] = {
            groupLabel: year.toString(),
            rows: [],
          }
        }

        resultsByYear[year].rows.push([
          <Fragment>
            <Link
              className="hover:text-bgt-primary text-gray-500 text-sm dark:text-white"
              to={`${getOrgResultUrl(org!.id, result.uuid)}`}
            >
              <div className="text-lg text-inherit">{result.patientName}</div>
              {result.name}
            </Link>
          </Fragment>,
          `${getNiceDate(d.getDate())} ${
            months[d.getMonth()]
          } ${d.getFullYear()}`,
          result.status !== ResultStatus.Complete ? (
            <ExpectedResultsDate
              hideTitle={true}
              unknownText={'Unknown'}
              tooltipOverride="Estimated time as we may not have data for turnaround times for all tests."
              identifier={`${result.uuid}-expected`}
              startDate={result.createdAt}
              minimumTime={
                testTurnaroundTimes[result.uuid]
                  ? testTurnaroundTimes[result.uuid].minimumTime
                  : undefined
              }
              maximumTime={
                testTurnaroundTimes[result.uuid]
                  ? testTurnaroundTimes[result.uuid].maximumTime
                  : undefined
              }
            ></ExpectedResultsDate>
          ) : (
            '-'
          ),
          <StatusTag
            color={getResultStatusColor(result.status)}
            label={
              result.status === ResultStatus.Created
                ? 'PARTIAL'
                : result.status?.replace(/_/gi, ' ')
            }
          ></StatusTag>,
          <Fragment>
            {/* TSC being dumb, for some reason we have to bang the summary */}
            <BiomarkerSummaryIndicator
              color={StatusTagColors.JADE}
              text="normal"
              value={result.biomarkerSummary!.normal}
              roundedStatusMode={true}
            ></BiomarkerSummaryIndicator>
            <BiomarkerSummaryIndicator
              color={StatusTagColors.MANDARIN}
              text="abnormal"
              value={result.biomarkerSummary!.abnormal}
              roundedStatusMode={true}
            ></BiomarkerSummaryIndicator>
            {(result.status === ResultStatus.PartialResults ||
              result.status === ResultStatus.Created) &&
              result.biomarkerSummary!.pending !== undefined &&
              result.biomarkerSummary!.pending > 0 && (
                <Fragment>
                  <BiomarkerSummaryIndicator
                    color={StatusTagColors.BLUE}
                    text="pending"
                    value={result.biomarkerSummary!.pending}
                    roundedStatusMode={true}
                  ></BiomarkerSummaryIndicator>
                  <Button
                    color="plain"
                    classNames="!text-base"
                    onClick={() => onViewPendingInfoClick(result)}
                  >
                    View more
                  </Button>
                </Fragment>
              )}
          </Fragment>,
          <div>
            <div className="relative inline-block">
              {result.status === ResultStatus.PartialResults ||
                (result.status === ResultStatus.Created &&
                  result.pendingTests &&
                  result.pendingTests.length > 0 && (
                    <div
                      className="absolute -top-2 -right-2 rounded-md  bg-blue-400 shadow-md text-xs text-white px-2 cursor-pointer transition-all hover:scale-125"
                      onClick={() => onViewPendingInfoClick(result)}
                    >
                      {result.pendingTests.length} test
                      {result.pendingTests.length === 1 ? '' : 's'} pending
                    </div>
                  ))}
              <Link
                className="hover:text-bgt-primary"
                to={`${getOrgResultUrl(org!.id, result.uuid)}`}
              >
                <Button
                  color={
                    result.status === ResultStatus.InReview
                      ? 'mandarin'
                      : 'jade'
                  }
                  type="button"
                  label={
                    result.status === ResultStatus.InReview
                      ? 'Review Results'
                      : 'View Results'
                  }
                ></Button>
              </Link>
            </div>
          </div>,
        ])
      }

      const keys = Object.keys(resultsByYear)
      keys.sort().reverse()
      for (const y in keys) {
        rows.push(resultsByYear[keys[y]])
      }
      if (rows.length > 0) {
        setTableData({
          columns: [
            'Name',
            'Results Date',
            'ETA',
            'Status',
            'Biomarker Overview',
            '',
          ],
          data: rows,
        })
      }
    },
    [org, testTurnaroundTimes]
  )

  const fetchResults = useCallback(() => {
    loadMore()
  }, [cursor, org, profile])

  const loadMore = () => {
    getResultsData(profile, org!.uuid, DEFAULT_FETCH_COUNT, cursor)
      .then((results) => {
        setCursor(results.cursor.nextCursor)
        setResults((prev) => [...prev, ...results.results])
        setLoading(false)
      })
      .catch(() => {
        setPageDidError(true)
        setLoading(false)
      })
  }

  useEffect(() => {
    // set the profile to be the first one
    if (user) {
      setProfile(user?.profileUuid)
      // return
    }
    if (profile && org?.uuid) {
      fetchResults()
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [user, profile, org?.uuid])

  useEffect(() => {
    // set the profile to be the first one
    // console.log("we are here, the tests should have updated", results);
    if (results) {
      massageResultsDataIntoTable(results)
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [results, massageResultsDataIntoTable])

  const colClasses = [
    'text-left col-span-3 sm:col-span-2 border-b-1 border-gray-dark content-center pt-4  rounded-tl-lg rounded-tr-lg sm:rounded-tr-none rounded-tl-lg rounded-tr-lg rounded-bl-lg sm:rounded-br-none border-0', //name
    'text-left col-span-3 sm:col-span-2 text-gray-semi-dark  content-center', // results date
    'text-left col-span-3 sm:col-span-2 text-gray-semi-dark  content-center', // eta
    'text-left col-span-3 sm:col-span-2 text-gray-semi-dark  content-center', // status
    'text-left col-span-3 sm:col-span-2 text-left sm:text-left text-gray-semi-dark  content-center', // summary
    'text-left col-span-3 sm:col-span-2 sm:text-right text-gray-semi-dark  content-center pb-4 rounded-tr-lg rounded-br-lg', // button
    // "sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3", // status
  ]

  return (
    <Fragment>
      {org && (
        <OrgPage
          theme={theme || undefined}
          org={org}
          tabs={[{ name: 'Results' }]}
        >
          {pageDidError && <PageError></PageError>}
          {!loading && !pageDidError && tableData && tableData.data && (
            <PageSection>
              <Fragment>
                <PendingTestsModal
                  result={selectedResult || undefined}
                  theme={theme || undefined}
                  show={showPendingTestModal}
                  setShow={setShowPendingTestModal}
                  turnaroundTime={
                    selectedResult
                      ? testTurnaroundTimes[selectedResult?.uuid]
                      : undefined
                  }
                ></PendingTestsModal>
                <PageHeading title="Results"></PageHeading>

                <div
                  key={`test-table`}
                  className="grid grid-cols-3 sm:grid-cols-12 mt-2 sm:mt-10"
                >
                  {/* <GridTableHeader className="col-span-3 sm:col-span-8">
                Pathology Report
              </GridTableHeader> */}
                  <div
                    className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm px-2 py-1 sm:p-3 sm:px-4 hidden sm:block ${colClasses[0]}`}
                  >
                    {tableData.columns[0]}
                  </div>
                  <div
                    className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[1]}`}
                  >
                    {tableData.columns[1]}
                  </div>
                  <div
                    className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[2]}`}
                  >
                    {tableData.columns[2]}
                  </div>
                  <div
                    className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[2]}`}
                  >
                    {tableData.columns[3]}
                  </div>
                  <div
                    className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm px-2 py-1 sm:p-3 hidden sm:block ${colClasses[3]}`}
                  >
                    {tableData.columns[4]}
                  </div>
                  <div
                    className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[4]}`}
                  >
                    {tableData.columns[5]}
                  </div>
                  {tableData.data.map((group, g) => (
                    <Fragment key={`group-fragment-${g}`}>
                      {tableData.data.length > 1 && (
                        <div
                          key={`group-label-${g}`}
                          className={`py-1 mt-2 sm:p-3 col-span-3 sm:col-span-12 text-sm text-gray-semi-dark bg-gray-light dark:text-white dark:bg-dark-gray-light font-bold rounded-md sm:rounded-none px-2 sm:px-4 sm:mb-0 mb-2 sm:rounded-tl-lg sm:rounded-tr-lg`}
                        >
                          {group.groupLabel}
                        </div>
                      )}
                      {group.rows.map((row, k) =>
                        row.map((item, i) => (
                          <div
                            key={`item-row-${k}-${i}`}
                            className={`py-1 px-2 sm:p-3 sm:px-4 col-span-1 sm:col-span-2 text-md text-black dark:text-white dark:bg-dark-gray-lighterer rounded-bl-none sm:rounded-tl-none  ${
                              k % 2 === 1
                                ? 'bg-gray-light dark:!bg-dark-gray-light dark:border-0'
                                : ''
                            }  ${colClasses[i]} ${
                              i !== row.length - 1
                                ? ''
                                : 'last-row sm:border-none border-b border-gray dark:border-0 rounded-tl-none rounded-tr-none  rounded-br-lg sm:mb-0 mb-2 !rounded-bl-lg sm:!rounded-bl-none sm:!rounded-br-none'
                            } ${k === group.rows.length - 1 ? 'border-none' : ''}`}
                          >
                            {item}
                          </div>
                        ))
                      )}
                    </Fragment>
                  ))}
                </div>
                {cursor && <LoadMore onClick={loadMore}></LoadMore>}
              </Fragment>
            </PageSection>
          )}
          {!loading && !pageDidError && !tableData && (
            <EmptyState
              image={TestTube}
              title="No client results found"
              alt="Test Tube Image"
            >
              Any test results associated with your organisation will appear
              here
            </EmptyState>
          )}
          {loading && <PageLoader></PageLoader>}
        </OrgPage>
      )}
    </Fragment>
  )
}
