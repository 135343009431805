import { Navigate, useParams } from "react-router-dom";

const RedirectToStandaloneBuy = () => {
  const { id } = useParams();
  return <Navigate to={`/buy/${id}`} replace />;
};

export function AdvancedStandalonePurchasePage() {
  return <RedirectToStandaloneBuy />;
}
