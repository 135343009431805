import '../../../App.css'
import Page from '../../Page'
import { EmptyState } from '../../layout/EmptyState'
// import { PageHeading } from "../../layout/PageHeading";
// import PageSection from "../../layout/PageSection";
import Clipboard from '../../../../public/img/clipboard.svg'
import { CreateOrgButton } from '../../form/button/CreateOrgButton'

export default function OrganisationHomePage() {
  return (
    <Page>
      {/* <PageSection>
        <PageHeading title="Organisation"></PageHeading>
      </PageSection> */}
      <EmptyState
        image={Clipboard}
        title="Create your Organisation now"
        alt="Clipboard Image"
      >
        <p className="">
          Creating an organisation is a great way to begin sharing Bloody Good
          Tests with others. When you create an organisation, you unlock the
          ability to:
        </p>
        <br />
        <ul className="list-disc m-auto inline-block text-left">
          <li>Access our API for generating referrals programatically</li>
          <li>Grey-label Bloody Good Tests with your own branding</li>
          <li>Review your clients blood work before releasing to customers</li>
          <li>
            Invite others into your organisation and begin sharing blood work
            (Coming soon)
          </li>
        </ul>
        <div className="mt-8">
          <CreateOrgButton></CreateOrgButton>
        </div>
      </EmptyState>
    </Page>
  )
}
