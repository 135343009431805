import {
  Fragment,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { unauthedFetch } from '../../providers/AuthProvider'
import FullPageLoader from '../layout/FullPageLoader'
import { useIntercom } from 'react-use-intercom'
import Page from '../Page'
// import { onChangeInterface } from "../form/TextInputField";

import { useOnceCall } from '../../lib/hooks'

import { formatMoney, setDocumentTitle } from '../../lib/utils'
import RoundedSection from '../layout/RoundedSection'
import { FilterMultiSelect, MultiSelectOption } from '../ui/TestListFilterBar'
import { Highlight } from '../ui/Highlight'
import { Pill } from '../ui/BiomarkerPill'
import { TestSearchBar } from '../ui/TestSearchBar'
import { Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { EyeDropperIcon } from '@heroicons/react/20/solid'
import { TestTurnaroundTime } from '../ui/TestTurnaroundTime'
import { Button } from '../form/button/Button'
import { useOrganisations, useUser } from '../../lib/store'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { BiomarkerCount } from '../ui/BiomarkerCount'
import { PageHeading } from '../layout/PageHeading'
import { getPricesForTests } from '../../lib/organisation'
import { TestPrice } from '../../lib/validators'
import { Tooltip } from 'react-tooltip'

const baseTransitionClasses =
  'transition-all duration-500 overflow-hidden transform '

const bgtTestSchema = z.object({
  id: z.string(),
  name: z.string(),
  shortName: z.string(),
  description: z.string().nullable(),
  testType: z.string(),
  provider: z
    .object({
      id: z.string().nullable().optional(),
      name: z.string().nullable().optional(),
    })
    .nullable(),
  updatedAt: z.coerce.date(),
  turnAroundTimes: z.array(
    z.object({
      maximumTime: z.number().nullable(),
      minimumTime: z.number().nullable(),
      providerId: z.string(),
      providerName: z.string(),
    })
  ),
  turnAroundTimeAverage: z.object({
    maximumTime: z.number().nullable(),
    minimumTime: z.number().nullable(),
  }),
  category: z
    .object({
      description: z.string().nullable(),
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  biomarkers: z.array(
    z.object({
      commonName: z.string().nullable(),
      description: z.string().nullable(),
      id: z.string(),
      name: z.string(),
      valueType: z.string(),
    })
  ),
})
type BGTTest = z.infer<typeof bgtTestSchema>

const basePillClasses = 'text-xs border-none shadow-none uppercase font-bold'
const testTypePillClasses = `${basePillClasses} bg-mandarin/20 text-mandarin`
const categoryPillClasses = `${basePillClasses} bg-blue-600/20 text-blue-600`
const providerPillClasses = `${basePillClasses} bg-purple-600/20 text-purple-600`

interface FilterState {
  search: string
  testType: string[]
  category: string[]
  provider: string[]
  showFilters: boolean
}

const convertIdToPrettyText = (name: string): string => {
  const testNameParts = name.split('-')
  const newNames = testNameParts.map(
    (n) => `${n.substring(0, 1).toUpperCase()}${n.substring(1, n.length)}`
  )
  return newNames.join(' ')
}

const doesTestMeetFilter = (
  test: BGTTest,
  testTypes: string[],
  categories: string[],
  providers: string[]
): boolean => {
  const hasTestType =
    testTypes.length === 0 ? true : testTypes.some((tt) => test.testType === tt)
  const hasCategory =
    categories.length === 0
      ? true
      : categories.some((c) => test.category && test.category.id === c)
  const hasProvider =
    providers.length === 0
      ? true
      : providers.some((tt) => test.provider && test.provider.id === tt)

  return hasTestType && hasCategory && hasProvider
}

const doesMatchFiltersAndSearchTerm = (
  test: BGTTest,
  filterOptions: FilterState
): boolean => {
  let isValid = false

  const filteredTestTypeOptions = filterOptions.testType.filter(
    (tt) => tt !== 'all'
  )
  const filteredCategoryOptions = filterOptions.category.filter(
    (tt) => tt !== 'all'
  )
  const filteredProviderOptions = filterOptions.provider.filter(
    (tt) => tt !== 'all'
  )

  isValid = doesTestMeetFilter(
    test,
    filteredTestTypeOptions,
    filteredCategoryOptions,
    filteredProviderOptions
  )

  if (isValid && filterOptions.search) {
    const filterTerm = filterOptions.search.toLowerCase()
    const textToMatch = test.name.toLowerCase()
    const shortNameToMatch = test.shortName.toLowerCase()
    const matchName = textToMatch.indexOf(filterTerm) !== -1
    const matchShortName = shortNameToMatch.indexOf(filterTerm) !== -1

    if (
      matchName ||
      matchShortName ||
      test.biomarkers.filter(
        (b) => b.name.toLowerCase().indexOf(filterTerm) !== -1
      ).length > 0
    ) {
      isValid = true
    } else {
      isValid = false
    }
  }
  return isValid
}

export default function TestListPage() {
  const [loading, setLoading] = useState(true)
  const [isChromeless, setChromeless] = useState(false)
  const [tests, setTests] = useState<BGTTest[]>([])
  const [testPriceList, setTestPriceList] = useState<TestPrice[]>([])
  const intercom = useIntercom()
  const [searchParams, setSearchParams] = useSearchParams()

  const user = useUser()
  const orgs = useOrganisations()

  const isLoggedInUserWithOrg = useMemo(
    () => (user && orgs && orgs.length > 0) ?? false,
    [user, orgs]
  )

  // Derived filter from searchParams
  const filter: FilterState = useMemo(
    () => ({
      search: searchParams.get('search') || '',
      testType: searchParams.get('testType')?.split(',') || ['all'],
      showFilters: searchParams.get('showFilters') ? true : false,
      category: searchParams.get('category')?.split(',') || ['all'],
      provider: searchParams.get('provider')?.split(',') || ['all'],
    }),
    [searchParams]
  )

  // this page is just a placeholder so we have a route for /callback,
  // all of the logic is actually happening in the AppWrapper
  useEffect(() => {
    setDocumentTitle('Complete Test List', 'BRANDED')
  }, [])

  useOnceCall(() => {
    unauthedFetch(
      import.meta.env.VITE_ENV === 'development'
        ? '/public/test-list.json'
        : `/data/test-list.json`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      }
    ).then((result) => {
      setTests(z.array(bgtTestSchema).parse(result.data))
      setLoading(false)
    })
  })

  useEffect(() => {
    if (tests && isLoggedInUserWithOrg) {
      getPricesForTests(tests.map((test) => test.id)).then((testList) => {
        setTestPriceList(testList)
      })
    }
  }, [tests, isLoggedInUserWithOrg])

  const testPriceMap = useMemo(() => {
    return Object.fromEntries(
      new Map(testPriceList.map((item) => [item.id, item])).entries()
    )
  }, [testPriceList])

  useEffect(() => {
    if (isChromeless) {
      intercom.update({
        hideDefaultLauncher: true,
      })
    } else {
      intercom.update({
        horizontalPadding: 55,
      })
    }
  }, [isChromeless, intercom])

  // test for chromeless
  useEffect((): void => {
    const chromelessParam = searchParams.get('chromeless')
    if (chromelessParam !== null) {
      setChromeless(true)
    }
  }, [searchParams])

  const updateSearchParams = (key: string, value?: string | string[]) => {
    setSearchParams(
      (prevParams) => {
        const newParams = new URLSearchParams(prevParams)

        // Remove the key if the value is empty or undefined
        if (!value || (Array.isArray(value) && value.length === 0)) {
          newParams.delete(key)
        } else {
          newParams.set(key, Array.isArray(value) ? value.join(',') : value)
        }

        return newParams
      },
      { replace: true }
    )
  }

  const handleOnSearchChange = (term: string) => {
    updateSearchParams('search', term || undefined)
  }

  const handleOnTestTypeChange = (selectedKeys: string[]) => {
    if (
      selectedKeys.length === 0 ||
      selectedKeys[selectedKeys.length - 1] === 'all'
    ) {
      // 'none' is not a valid key so it should remove all range bars
      updateSearchParams('testType', ['all'])
      return
    }

    updateSearchParams(
      'testType',
      selectedKeys.filter((key) => key !== 'all')
    )
  }
  const deleteTestTypeFromSearchParams = (key: string) => {
    handleOnTestTypeChange(filter.testType.filter((t) => t !== key))
  }
  const handleOnCategoryChange = (selectedKeys: string[]) => {
    if (
      selectedKeys.length === 0 ||
      selectedKeys[selectedKeys.length - 1] === 'all'
    ) {
      // 'none' is not a valid key so it should remove all range bars
      updateSearchParams('category', ['all'])
      return
    }

    updateSearchParams(
      'category',
      selectedKeys.filter((key) => key !== 'all')
    )
  }
  const deleteCategoryFromSearchParams = (key: string) => {
    handleOnCategoryChange(filter.category.filter((t) => t !== key))
  }
  const handleOnProviderChange = (selectedKeys: string[]) => {
    if (
      selectedKeys.length === 0 ||
      selectedKeys[selectedKeys.length - 1] === 'all'
    ) {
      // 'none' is not a valid key so it should remove all range bars
      updateSearchParams('provider', ['all'])
      return
    }

    updateSearchParams(
      'provider',
      selectedKeys.filter((key) => key !== 'all')
    )
  }
  const deleteProviderFromSearchParams = (key: string) => {
    handleOnProviderChange(filter.provider.filter((t) => t !== key))
  }

  const testTypeOptions = useMemo(() => {
    const testTypeMap: Record<string, MultiSelectOption> = Object.fromEntries(
      new Map(
        tests.map((item) => [
          item.testType,
          { key: item.testType, name: convertIdToPrettyText(item.testType) },
        ])
      ).entries()
    )
    testTypeMap['all'] = {
      key: 'all',
      name: 'All',
    }
    const result = Object.values(testTypeMap)
    result.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    return result
  }, [tests])

  const categoryOptions = useMemo(() => {
    const categoryMap: Record<string, MultiSelectOption> = Object.fromEntries(
      new Map(
        tests
          .filter(
            (item) => item.category && item.category.id && item.category.name
          )
          .map((item) => [
            item.category!.id,
            { key: item.category!.id, name: item.category!.name },
          ])
      ).entries()
    )
    categoryMap['all'] = {
      key: 'all',
      name: 'All',
    }
    const result = Object.values(categoryMap)
    result.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    return result
  }, [tests])

  const providerOptions = useMemo(() => {
    const providerMap: Record<string, MultiSelectOption> = Object.fromEntries(
      new Map(
        tests
          .filter(
            (item) => item.provider && item.provider.id && item.provider.name
          )
          .map((item) => [
            item.provider!.id,
            {
              key: item.provider!.id,
              name: item.provider!.name,
            },
          ])
      ).entries()
    )
    providerMap['all'] = {
      key: 'all',
      name: 'All',
    }
    const result = Object.values(providerMap)
    result.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    return result
  }, [tests])

  const filteredTests = useMemo(
    () => tests.filter((t) => doesMatchFiltersAndSearchTerm(t, filter)),
    [filter, tests]
  )

  const filteredTestTypes = useMemo(() => {
    const filteredTests = filter.testType.filter((tt) => tt !== 'all')
    return testTypeOptions.filter(
      (option) => filteredTests.indexOf(option.key) !== -1
    )
  }, [filter.testType, testTypeOptions])
  const filteredCategories = useMemo(() => {
    const filteredCategories = filter.category.filter((cat) => cat !== 'all')
    return categoryOptions.filter(
      (option) => filteredCategories.indexOf(option.key) !== -1
    )
  }, [filter.category, categoryOptions])
  const filteredProviders = useMemo(() => {
    const filteredProviders = filter.provider.filter((cat) => cat !== 'all')
    return providerOptions.filter(
      (option) => filteredProviders.indexOf(option.key) !== -1
    )
  }, [filter.provider, providerOptions])

  return (
    <Page fullViewport={true} removePagePadding={isChromeless ? false : true}>
      {!loading && tests && (
        <div className={`block px-4 sm:px-6 lg:px-4 pt-4 pb-5`}>
          <RoundedSection size="2xl">
            {isChromeless && (
              <div className="p-4">
                <h1 className="text-xl font-bold">Test Catalogue</h1>
                <span className="max-w-[50%] block min-w-[300px]">
                  You can search the Bloody Good Tests catalogue simply and
                  easily. Just search for a test or biomarker. If you'd like to
                  filter by Test Type, Category or Provider, you can simply use
                  the filters below the search box.
                </span>
              </div>
            )}
            {!isChromeless && (
              <PageHeading title={`Test Catalogue`}>
                Browse, search &amp; filter our complete list of tests
              </PageHeading>
            )}
            <RoundedSection size="xl" reducedPadding={true} className="">
              <TestSearchBar
                onSearchChange={handleOnSearchChange}
                searchTerm={filter.search || ''}
              >
                <Fragment>
                  <div
                    className={`items-center gap-4 flex flex-col sm:flex-row mt-2 mb-2 `}
                  >
                    <FilterMultiSelect
                      filterName="Test Type"
                      filterNamePlural="Test Types"
                      options={testTypeOptions}
                      selectedKeys={filter.testType}
                      onChange={handleOnTestTypeChange}
                    />
                    <FilterMultiSelect
                      filterName="Category"
                      filterNamePlural="Categories"
                      options={categoryOptions}
                      selectedKeys={filter.category}
                      onChange={handleOnCategoryChange}
                    />
                    <FilterMultiSelect
                      filterName="Provider"
                      filterNamePlural="Providers"
                      options={providerOptions}
                      selectedKeys={filter.provider}
                      onChange={handleOnProviderChange}
                    />
                  </div>
                  {(filteredTestTypes.length > 0 ||
                    filteredCategories.length > 0 ||
                    filteredProviders.length > 0) && (
                    <div className="mt-2 text-sm rounded-md bg-mandarin/5 p-4">
                      <div className="block">
                        You have filters active which will limit the results
                        shown
                      </div>
                      <div className="sm:block hidden">
                        <span>
                          {filteredTestTypes.map((testType) => (
                            <Pill
                              key={testType.key}
                              className={testTypePillClasses}
                              rounded={true}
                              onDelete={() =>
                                deleteTestTypeFromSearchParams(testType.key)
                              }
                            >
                              Test Type - {testType.name}
                            </Pill>
                          ))}
                        </span>
                        <span>
                          {filteredCategories.map((category) => (
                            <Pill
                              key={category.key}
                              className={categoryPillClasses}
                              rounded={true}
                              onDelete={() =>
                                deleteCategoryFromSearchParams(category.key)
                              }
                            >
                              Category - {category.name}
                            </Pill>
                          ))}
                        </span>
                        <span>
                          {filteredProviders.map((provider) => (
                            <Pill
                              key={provider.key}
                              className={providerPillClasses}
                              rounded={true}
                              onDelete={() =>
                                deleteProviderFromSearchParams(provider.key)
                              }
                            >
                              Provider - {provider.name}
                            </Pill>
                          ))}
                        </span>
                      </div>
                    </div>
                  )}
                </Fragment>
              </TestSearchBar>
            </RoundedSection>

            {(filteredTestTypes.length > 0 ||
              filteredCategories.length > 0 ||
              filteredProviders.length > 0 ||
              filter.search) && (
              <div className="mt-2 px-6 text-sm rounded-md bg-jade/10 p-4">
                Displaying {filteredTests.length} result
                {filteredTests.length !== 1 ? 's' : ''}
              </div>
            )}
            <div className="divide-y divide-gray dark:divide-dark-gray-light">
              {filteredTests.map((t) => (
                <div key={`test-container-${t.id}`} className="px-4 py-4">
                  <TestListItem
                    test={t}
                    filter={filter}
                    validUser={isLoggedInUserWithOrg}
                    price={
                      testPriceMap && testPriceMap[t.id]
                        ? testPriceMap[t.id].price || undefined
                        : undefined
                    }
                    eligibleForDiscount={
                      testPriceMap && testPriceMap[t.id]
                        ? testPriceMap[t.id].eligibleForDiscount || false
                        : false
                    }
                  ></TestListItem>
                </div>
              ))}
              {filteredTests.length === 0 && (
                <div className="min-h-72 flex justify-center text-center items-center">
                  {filter.search ? (
                    <div>
                      Oops, we couldn't find anything that matched '
                      {filter.search}'
                      <div className="mt-2">
                        <Button
                          onClick={() =>
                            intercom.showNewMessage(
                              `I tried to look for '${filter.search}' but it didn't show - is this a test you might be adding in future?`
                            )
                          }
                        >
                          Click here to suggest it
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      Looks like your filters might be a little too strict, try
                      removing a few.
                    </div>
                  )}
                </div>
              )}
            </div>
          </RoundedSection>
        </div>
      )}
      {loading && <FullPageLoader></FullPageLoader>}
    </Page>
  )
}

interface TestListItem {
  test: BGTTest
  filter: FilterState
  validUser?: boolean
  price?: number
  eligibleForDiscount: boolean
}

function TestListItem({
  test,
  filter,
  validUser,
  price,
  eligibleForDiscount,
}: TestListItem) {
  const [expandedOpen, setExpandedOpen] = useState(false)
  const intercom = useIntercom()

  const gridColsClass = validUser ? 'grid-cols-6' : 'grid-cols-5'
  const fullColSpanClass = validUser ? 'col-span-6' : 'col-span-5'

  const pillClasses =
    'rounded-full bg-gray-medium/50 hover:bg-gray-medium/80 dark:bg-gray-medium/10 dark:hover:bg-gray-medium/20 px-2 py-1 text-sm'

  return (
    <div className={`grid ${gridColsClass} gap-2 sm:gap-4 items-center`}>
      <div
        className={`${fullColSpanClass} sm:col-span-2 font-bold text-base cursor-pointer`}
        onClick={() => setExpandedOpen((expandedOpen) => !expandedOpen)}
      >
        <Highlight term={filter.search} text={test.name}></Highlight>
        {test.provider && (
          <span className="block font-normal">{test.provider?.name}</span>
        )}
      </div>
      <div className="col-span-2 sm:col-span-1">
        <Pill
          key={`${test.id}-type`}
          className={testTypePillClasses}
          rounded={true}
        >
          {convertIdToPrettyText(test.testType)}
        </Pill>
      </div>
      {!validUser && (
        <div className="col-span-3 sm:col-span-1 items-center">
          <a
            target="_blank"
            className={pillClasses}
            onClick={() => {
              intercom.showNewMessage(
                `I'd like to know more about the test prices on the Test List Page. Specifically the price for ${test.name}${test.provider ? ` by ${test.provider.name}` : ''}. Can you please send me some more information?`
              )
            }}
          >
            <LockClosedIcon
              width={14}
              className="inline-block mr-2"
            ></LockClosedIcon>
            Enquire about price
          </a>
        </div>
      )}
      {validUser && (
        <div className="col-span-2 sm:col-span-1">
          <div
            data-tooltip-id={`discount-tooltip-${test.id}`}
            className="inline-block cursor-default"
          >
            {price ? (
              <b>
                {formatMoney(price / 100, 'aud')}{' '}
                {eligibleForDiscount ? '*' : ''}
              </b>
            ) : (
              '-'
            )}
          </div>
          {price && eligibleForDiscount && (
            <Tooltip
              id={`discount-tooltip-${test.id}`}
              place="bottom"
              content="This item is eligable for a bulk discount."
              className="rounded-lg z-50"
            />
          )}
        </div>
      )}
      {validUser && (
        <div className="col-span-2 sm:col-span-1">
          <div key={test.id} className="block">
            {test.biomarkers.length > 0 ? (
              <Fragment>
                <BiomarkerCount>{test.biomarkers.length}</BiomarkerCount>
                <span className="hidden sm:inline">
                  {' '}
                  biomarker
                  {test.biomarkers.length !== 1 ? 's' : ''}
                </span>
              </Fragment>
            ) : (
              'N/A'
            )}
          </div>
        </div>
      )}
      <div
        className={`${fullColSpanClass} sm:col-span-1 justify-items-start sm:justify-items-end`}
      >
        <div
          className="cursor-pointer text-sm text-blood hover:text-bgt-primary"
          onClick={() => setExpandedOpen((expandedOpen) => !expandedOpen)}
        >
          <span className={pillClasses}>
            {expandedOpen ? 'Show less' : 'Show more'}

            {expandedOpen ? (
              <ChevronUpIcon
                className="h-5 w-5  inline-block -mt-1"
                aria-hidden="true"
                width={10}
              />
            ) : (
              <ChevronDownIcon
                className="h-5 w-5  inline-block"
                aria-hidden="true"
                width={10}
              />
            )}
          </span>
        </div>
      </div>

      <Transition
        show={expandedOpen}
        as={Fragment}
        appear={true}
        enterFrom={`${baseTransitionClasses}  opacity-0 max-h-0`}
        enterTo={`${baseTransitionClasses}  opacity-100 max-h-screen`}
        leaveFrom={`${baseTransitionClasses} opacity-100 max-h-screen`}
        leaveTo={`${baseTransitionClasses}  opacity-0 max-h-0`}
      >
        <div className={`${fullColSpanClass} mt-2 mb-2`}>
          <div className="grid grid-cols-12 gap-2 sm:gap-4">
            <div className="col-span-12 sm:col-span-12 sm:border-l-4 border-gray-200 sm:pl-5">
              <div className="grid grid-cols-2 gap-6 mb-4 mt-4">
                {test.description && (
                  <div className="col-span-2 text-sm">{test.description}</div>
                )}
                <div>
                  <ExpandedCell title="Company">
                    {test.provider?.name || 'Bloody Good Tests'}
                  </ExpandedCell>
                </div>
                <div>
                  <ExpandedCell title="Sample Type">
                    <EyeDropperIcon
                      width={16}
                      className="inline-block mr-1"
                    ></EyeDropperIcon>
                    <Pill
                      key={`${test.id}-type`}
                      className={testTypePillClasses}
                      rounded={true}
                    >
                      {convertIdToPrettyText(test.testType)}
                    </Pill>
                  </ExpandedCell>
                </div>
                <div>
                  <ExpandedCell title="Processing Time">
                    <TestTurnaroundTime
                      turnaroundTimes={test.turnAroundTimeAverage}
                      hideHeading={true}
                      showMissingText={false}
                    ></TestTurnaroundTime>
                  </ExpandedCell>
                </div>
                <div>
                  <div>
                    <ExpandedCell title="Category">
                      {test.category?.name ? (
                        <Pill
                          key={`${test.id}-category`}
                          className={categoryPillClasses}
                          rounded={true}
                        >
                          {test.category.name}
                        </Pill>
                      ) : (
                        'N/A'
                      )}
                    </ExpandedCell>
                  </div>
                </div>
              </div>
              {test.biomarkers.length > 0 && (
                <div key={`${test.id}-biomarkers`} className="block">
                  <div className="block">
                    <BiomarkerCount>
                      {test.biomarkers.length} Included Biomarker
                      {test.biomarkers.length !== 1 ? 's' : ''}:
                    </BiomarkerCount>
                  </div>
                  {test.biomarkers.map((biomarker) => (
                    <Pill key={biomarker.id}>
                      <Highlight
                        term={filter.search}
                        text={biomarker.name}
                      ></Highlight>
                    </Pill>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}

interface ExpandedCellProps extends PropsWithChildren {
  title: string
}
function ExpandedCell({ title, children }: ExpandedCellProps) {
  return (
    <Fragment>
      <div className="text-sm">{title}</div>
      <div className="font-bold text-base">{children}</div>
    </Fragment>
  )
}
