import { Fragment, useCallback, useEffect, useState } from 'react'

import PageLoader from '../../../layout/PageLoader'
import PageSection from '../../../layout/PageSection'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import { getProductsForOrganisation } from '../../../../lib/organisation'
import {
  Organisation,
  ProductWithTestsAndBiomarkersSelected,
} from '../../../../lib/validators'
import { PageHeading } from '../../../layout/PageHeading'
import { Link } from 'react-router-dom'
import { getBundleUrl, getCreateTestBundleUrl } from '../../../../lib/routes'
import { getPrettyDate, setDocumentTitle } from '../../../../lib/utils'
import OrgPage from '../../../OrgPage'
import { LinkButton } from '../../../form/button/LinkButton'
import { StatusTag } from '../../../layout/StatusTag'
import { StatusTagColors } from '../../../../lib/interfaces'
import 'react-tooltip/dist/react-tooltip.css'

import { BundleActionsDropdown } from './dropdowns/BundleActionsDropdown'
import { TestTurnaroundTime } from '../../../ui/TestTurnaroundTime'
import { archiveProduct, unarchiveProduct } from '../../../../lib/product'
import toast from 'react-hot-toast'
import { RadioGroup } from '../../../form/RadioGroup'

export function OrgTestBundles() {
  const [loading, setLoading] = useState(false)
  const { org, theme } = useOrganisationContext()
  const [packages, setPackages] = useState<
    ProductWithTestsAndBiomarkersSelected[] | null
  >(null)
  const [archivedOnly, setArchivedOnly] = useState(false)

  setDocumentTitle(`Test Bundles  | ${org && org?.name}`, 'BRANDED')

  useEffect(() => {
    if (org) {
      setLoading(true)
      getProductsForOrganisation({
        organisationUuid: org.uuid,
        archivedOnly: archivedOnly,
      }).then((products) => {
        setPackages(products)
        setLoading(false)
      })
    }
  }, [org, archivedOnly])

  const handleArchive = useCallback(
    async (bundleId: string) => {
      const bundle = packages?.find((p) => p.id === bundleId)
      if (!bundle) {
        throw new Error('Bundle not found')
      }
      if (bundle.archivedAt) {
        unarchiveProduct(bundle.id)
          .then(() => {
            toast.success(`${bundle.name} has been unarchived`, {
              duration: 3000,
            })
            setPackages((prev) => {
              return prev?.filter((p) => p.id !== bundleId) ?? null
            })
          })
          .catch((e) => {
            toast.error(`${bundle.name} could not be unarchived`, {
              duration: 3000,
            })
            console.error(e)
          })
      } else {
        archiveProduct(bundle.id)
          .then(() => {
            toast.success(`${bundle.name} has been archived`, {
              duration: 3000,
            })
            setPackages((prev) => {
              return prev?.filter((p) => p.id !== bundleId) ?? null
            })
          })
          .catch((e) => {
            toast.error(`${bundle.name} could not be archived`, {
              duration: 3000,
            })
            console.error(e)
          })
      }
    },
    [packages]
  )

  const getBundleActionDropdown = (
    pack: ProductWithTestsAndBiomarkersSelected,
    org: Organisation
  ) => {
    return (
      <div className="-mt-1.5">
        <BundleActionsDropdown
          editControls={true}
          bundleId={pack.id}
          bundleName={pack.name}
          org={org}
          theme={org.theme || undefined}
          disabled={false}
          buttonLabel="Actions"
          isArchived={pack.archivedAt !== null}
          storeLinks={pack.storeLinks}
          handleArchive={handleArchive}
        ></BundleActionsDropdown>
      </div>
    )
  }

  return (
    <Fragment>
      {org && (
        <OrgPage
          org={org}
          tabs={[
            {
              name: 'Test Bundles',
            },
          ]}
          className="pb-20"
        >
          {loading && <PageLoader theme={theme || undefined}></PageLoader>}
          {!loading && org && (
            <PageSection>
              <PageHeading
                title={`${org?.name} Test Bundles`}
                action={
                  <div className="flex items-center gap-2">
                    <RadioGroup
                      name="archive-toggle"
                      options={[
                        {
                          label: 'Active',
                          value: 'active',
                        },
                        {
                          label: 'Archived',
                          value: 'archived',
                        },
                      ]}
                      selected={archivedOnly ? 'archived' : 'active'}
                      handleChange={(e) => {
                        if (e.strValue === 'active') {
                          setArchivedOnly(false)
                        } else {
                          setArchivedOnly(true)
                        }
                      }}
                      theme={theme || undefined}
                    />
                    <LinkButton
                      url={getCreateTestBundleUrl(org.id)}
                      label="Create Test Bundle"
                      internal={true}
                      theme={theme || undefined}
                    ></LinkButton>
                  </div>
                }
              ></PageHeading>
              {!packages ||
                (packages.length === 0 && <div>No test bundles found.</div>)}
              {packages && packages.length > 0 && (
                <div className="divide-y divide:dark:border-dark-gray-light divide:border-gray">
                  {packages.map((pack) => (
                    <div
                      key={`${pack.id}-pack`}
                      className="grid grid-cols-6 sm:grid-cols-12 "
                    >
                      <div className="col-span-4 sm:col-span-4 font-bold min-h-10  pt-2 pb-2">
                        <Link to={getBundleUrl(org?.id, pack.id)}>
                          {pack.name}
                        </Link>
                      </div>
                      <div className="col-span-2 font-bold min-h-10 mt-4 sm:hidden block ">
                        {getBundleActionDropdown(pack, org)}
                      </div>

                      <div
                        className={`col-span-6 sm:col-span-4 text-gray-semi-dark content-center mt-2 ${!pack.description ? '!mt-0' : 'min-h-10'}`}
                      >
                        <div>
                          {pack.description && pack.description.length > 200
                            ? `${pack.description?.substring(0, 200)}...`
                            : pack.description}
                          <div>
                            {pack.turnAroundTimeAverage && (
                              <TestTurnaroundTime
                                hideHeading={true}
                                turnaroundTimes={pack.turnAroundTimeAverage}
                                showMissingText={false}
                              ></TestTurnaroundTime>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-span-6 sm:col-span-1 text-gray-semi-dark mt-2  pb-4 sm:pb-0  ${pack.internal ? '!mt-0 !pb-0' : 'min-h-10'}`}
                      >
                        {!pack.internal && (
                          <StatusTag
                            color={StatusTagColors.ORANGE}
                            label="Template"
                          ></StatusTag>
                        )}
                      </div>
                      <div className=" hidden sm:block sm:col-span-2 text-center min-h-10 mt-2 ">
                        {getPrettyDate(pack.createdAt)}
                        {/* <p
                        title={pack.tests?.map((t) => t.name).join('\n')}
                        className="truncate text-sm"
                      >
                        {pack.tests?.map((t) => t.shortName).join(', ')}
                      </p> */}
                      </div>
                      <div className="hidden col-span-1 text-left sm:block min-h-10 mt-2 pt-1 ">
                        {getBundleActionDropdown(pack, org)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </PageSection>
          )}
        </OrgPage>
      )}
    </Fragment>
  )
}
