import { Fragment, ReactNode } from 'react'
import { OrganisationWithBranding } from './context/OrganisationContext'
import Page, { PageWithProps } from './Page'
import { OrgTabbar, TabbarItem } from './layout/Tabbar'
import { ThemeInterface } from '../lib/interfaces'

export interface OrgPageWithProps extends PageWithProps {
  org: OrganisationWithBranding
  theme?: ThemeInterface
  tabs?: TabbarItem[]
  aboveTabBar?: ReactNode
}

export default function OrgPage({
  org,
  aboveTabBar,
  backgroundColor,
  children,
  className,
  fullViewport,
  tabs,
  theme,
}: OrgPageWithProps) {
  return (
    <Page
      backgroundColor={backgroundColor}
      fullViewport={fullViewport}
      className={className}
    >
      {aboveTabBar && aboveTabBar}
      <OrgTabbar theme={theme} org={org} tabs={tabs}></OrgTabbar>
      <Fragment>{children}</Fragment>
    </Page>
  )
}
