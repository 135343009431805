import { PropsWithChildren } from 'react'

interface TestCreatorWrapperProps extends PropsWithChildren {}

export function TestCreatorWrapper({ children }: TestCreatorWrapperProps) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-5 4xl:grid-cols-6 5xl:grid-cols-7 6xl:grid-cols-8 gap-4">
      {children}
    </div>
  )
}
